import React, { useEffect, useState } from "react";

const CheckboxList = ({ checkboxes, getLayoutSettings }) => {
  const [checkboxStatus, setCheckboxStatus] = useState([]);

  useEffect(() => {
    const data = checkboxes.reduce((acc, checkbox) => {
      acc[checkbox.label] = checkbox.checked;
      return acc;
    }, {});
    setCheckboxStatus(data);
  }, [checkboxes]);

  const handleCheckboxChange = (label) => {
    const current = checkboxes.find((item) => item.label === label);

    setCheckboxStatus({
      ...checkboxStatus,
      [label]: !checkboxStatus[label],
    });
    if (getLayoutSettings) {
      getLayoutSettings(
        current.setting,
        !checkboxStatus[label],
        window.location.pathname.split("/")[1]
      );
    }
  };

  return (
    <div>
      {checkboxes.map((checkbox, index) => (
        <div className="d-flex align-items-center mt-4" key={index}>
          <span>{checkbox.label}</span>
          <span className="switch ml-auto">
            <input type="checkbox" checked={checkboxStatus[checkbox.label]} />
            <span
              className="slid round"
              onClick={() => handleCheckboxChange(checkbox.label)}
            ></span>
          </span>
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
