import { userInfo, updateSegment } from "../constant/Api";
import * as opsService from "./Ops";
import config from "../constant/Config";

export const getUserDetails = async (data) => {
  let result = await opsService.postdata(userInfo, {}, config);
  return result;
};

export const updateTradingSegment = async (data) => {
  let result = await opsService.postdata(updateSegment, data, config);
  return result;
};
