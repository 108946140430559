import React, { useEffect, useState } from "react";
import Navbar from "../widgets/Navbar";
import Sidebar from "../widgets/Sidebar";
import Footer from "../widgets/Footer";
import { useGetCryptoTransactionHistoryQuery } from "../../redux/tradeApi";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CryptoTransactionHistory = () => {
  const { t } = useTranslation();

  const [selectedSegment, setSelectedSegment] = useState("main_wallet");
  const [selectedMainSegment, setSelectedMainSegment] = useState("crypto");
  const [mainWalletData, setMainWalletData] = useState([]);
  const [spotWalletData, setSpotWalletData] = useState([]);
  const [mainWalletSum, setMainWalletSum] = useState([]);
  const [spotWalletSum, setSpotWalletSum] = useState([]);
  const [coinListData, setCoinListData] = useState([]);
  const [selectedCryptoCoin, setSelectedCryptoCoin] = useState("");
  const [depositData, setDepositData] = useState([]);
  const [allData, setAllData] = useState([]);
  const { data: cryptoTransData } = useGetCryptoTransactionHistoryQuery();

  useEffect(() => {
    if (cryptoTransData) {
      const cryptoData = cryptoTransData[0];
      setAllData(cryptoTransData);
      setMainWalletData(cryptoTransData);
      setCoinListData(cryptoData.coinListQry);
      setMainWalletSum(cryptoData.walletSum);
      setDepositData(cryptoData.depositResult);
    }
  }, [cryptoTransData]);

  const mainWalletHtml = () => {
    const html = [];
    if (mainWalletData.length > 0) {
      const filteredTransactions = mainWalletData.filter(
        (e) => e.name === selectedCryptoCoin
      );
      let WalletBAlnace = 0;
      if (mainWalletSum.length > 0) {
        WalletBAlnace = mainWalletSum[0].walletAmount;
      }
      if (filteredTransactions.length > 0) {
        filteredTransactions[0].wallet.map((item, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>{t(item.tx_type)}</td>
              <td>{item.coin_amount}</td>
              <td>{WalletBAlnace}</td>
              <td>{item.tx_id}</td>
              <td>{item.coinName}</td>
              <td>{moment(item.created).format("lll")}</td>
            </tr>
          );
          WalletBAlnace = parseFloat(
            (WalletBAlnace - parseFloat(item.coin_amount)).toFixed(8)
          );
        });
      } else {
        html.push(
          <tr>
            <td colSpan={7} className="text-center">
              {t("No Data Found")}
            </td>
          </tr>
        );
      }
    } else {
      html.push(
        <tr>
          <td colSpan={7} className="text-center">
            {t("No Data Found")}
          </td>
        </tr>
      );
    }
    return html;
  };

  const spotWalletHtml = () => {
    const html = [];
    if (spotWalletData.length > 0) {
      const filteredTransactions = spotWalletData.filter(
        (e) => e.coinName === selectedCryptoCoin
      );
      let WalletBAlnace = 0;
      if (spotWalletSum.length > 0) {
        WalletBAlnace = spotWalletSum[0].transSum;
      }
      if (filteredTransactions.length > 0) {
        filteredTransactions.map((item, i) => {
          var pair;
          if (item.tx_type === "buy_exchange") {
            var Buy = "Buy";
          }
          if (item.tx_type === "sell_exchange") {
            Buy = "Sell";
          }
          if (item.tx_type === "sell_exchange" && item.remark === "adminFees") {
            Buy = "Sell (fees)";
          }
          if (item.tx_type === "buy_exchange" && item.remark === "adminFees") {
            Buy = "Buy (fees)";
          }
          if (item.tx_type === "sell_exchange") {
            pair = item.coin_pair_sell;
          } else {
            pair = item.coin_pair_buy;
          }
          html.push(
            <tr>
              <td
                style={
                  item.tx_type === "sell_exchange" ||
                  item.tx_type === "Withdrawal" ||
                  item.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {i + 1}
              </td>
              <td
                style={
                  item.tx_type === "sell_exchange" ||
                  item.tx_type === "Withdrawal" ||
                  item.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {item.coin_amount}
              </td>
              <td
                style={
                  item.tx_type === "sell_exchange" ||
                  item.tx_type === "Withdrawal" ||
                  item.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {item.tx_type !== "Wallet to transactions"
                  ? item.tx_type === "buy_exchange" ||
                    item.tx_type === "sell_exchange"
                    ? t(Buy)
                    : t(item.tx_type)
                  : t("Wallet to spot")}
              </td>
              <td
                style={
                  item.tx_type === "sell_exchange" ||
                  item.tx_type === "Withdrawal" ||
                  item.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {WalletBAlnace}
              </td>
              <td
                style={
                  item.tx_type === "sell_exchange" ||
                  item.tx_type === "Withdrawal" ||
                  item.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {pair}
              </td>
              <td
                style={
                  item.tx_type === "sell_exchange" ||
                  item.tx_type === "Withdrawal" ||
                  item.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {moment(item.created).format("lll")}
              </td>
            </tr>
          );
          WalletBAlnace = parseFloat(
            (WalletBAlnace - parseFloat(item.coin_amount)).toFixed(8)
          );
        });
      } else {
        html.push(
          <tr>
            <td colSpan={7} className="text-center">
              {t("No Data Found")}
            </td>
          </tr>
        );
      }
    } else {
      html.push(
        <tr>
          <td colSpan={7} className="text-center">
            {t("No Data Found")}
          </td>
        </tr>
      );
    }
    return html;
  };

  const depositHtml = () => {
    const html = [];
    if (depositData.length > 0) {
      const filteredTransactions = depositData.filter(
        (e) => e.coin_name === selectedCryptoCoin
      );

      if (filteredTransactions.length > 0) {
        filteredTransactions.map((item, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>{item.coin_amount}</td>
              <td>{t(item.tx_type)}</td>
              <td>{item.coin_name}</td>
              <td>{moment(item.created).format("lll")}</td>
            </tr>
          );
        });
      } else {
        html.push(
          <tr>
            <td colSpan={5} className="text-center">
              {t("No Data Found")}
            </td>
          </tr>
        );
      }
    } else {
      html.push(
        <tr>
          <td colSpan={5} className="text-center">
            {t("No Data Found")}
          </td>
        </tr>
      );
    }
    return html;
  };

  const handleSelect = (item) => {
    if (item) {
      const transData = JSON.parse(item);
      setSelectedCryptoCoin(transData.name);
      setSpotWalletData(transData.transactions);
      setSpotWalletSum(transData.transSum ? transData.transSum : 0);
    }
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row ">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="nav-tabs3  bg2 br10 mb-4">
                <button
                  className={selectedMainSegment === "crypto" && "active"}
                  onClick={() => setSelectedMainSegment("crypto")}
                >
                  {t("Transaction History")}
                </button>
                {/* <button
                  className={selectedMainSegment === "forex" && "active"}
                  onClick={() => setSelectedMainSegment("forex")}
                >
                  Forex History
                </button>
                <button
                  className={selectedMainSegment === "equity" && "active"}
                  onClick={() => setSelectedMainSegment("equity")}
                >
                  Equity History
                </button> */}
              </div>
              {selectedMainSegment === "crypto" ? (
                <div className="p-3  bg2 br20 ">
                  <div className="row">
                    <div className="nav-tabs2 col-auto">
                      <button
                        className={
                          selectedSegment === "main_wallet" && "active"
                        }
                        onClick={() => setSelectedSegment("main_wallet")}
                      >
                        {t("Main Wallet")}
                      </button>
                      <button
                        className={selectedSegment === "deposit" && "active"}
                        onClick={() => setSelectedSegment("deposit")}
                      >
                        {t("Deposit")}
                      </button>
                      <button
                        className={selectedSegment === "withdrawal" && "active"}
                        onClick={() => setSelectedSegment("withdrawal")}
                      >
                        {t("Withdrawal")}
                      </button>
                    </div>
                    <div className="col-auto mt-md-0 mt-3 ml-auto">
                      <select
                        className="form-control select_crypto"
                        onChange={(e) => handleSelect(e.target.value)}
                      >
                        <option value={[]}>
                          {t("Select")} {t("Crypto")}
                        </option>
                        {allData.map((item, i) => {
                          return (
                            <option value={JSON.stringify(item)}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {selectedSegment === "main_wallet" ? (
                    <div className="table-responsive trade_scrool_table">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Final Balance")}</th>
                            <th>{t("Transaction ID")}</th>
                            <th>{t("Currency Type")}</th>
                            <th>{t("Created")}</th>
                          </tr>
                        </thead>
                        <tbody>{mainWalletHtml()}</tbody>
                      </table>
                    </div>
                  ) : selectedSegment === "deposit" ? (
                    <div className="table-responsive trade_scrool_table">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Token ID")}</th>
                            <th>{t("Created")}</th>
                          </tr>
                        </thead>
                        <tbody>{depositHtml()}</tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="table-responsive trade_scrool_table">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Withdrawal Fee")}</th>
                            <th>{t("Withdrawal Token ID")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Transaction Hash")}</th>
                            <th>{t("Created")}</th>
                            <th>{t("Status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                            <td>1</td>
                            <td>50</td>
                            <td>0.02</td>
                            <td>BTC</td>
                            <td>Withdrawal</td>
                            <td>TX_6h&kdjK</td>
                            <td>Mar 15, 2024 4:48 PM</td>
                            <td>Pending</td>
                          </tr> */}

<tr>
            <td colSpan={8} className="text-center">
              {t("No Data Found")}
            </td>
          </tr>

                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              ) : selectedMainSegment === "forex" ? (
                <div className="p-3  p-md-4 bg2 br20 mb-4">
                  <div className="nav-tabs2 ">
                    <button
                      className="active"
                      onClick={() => setSelectedSegment("main_wallet")}
                    >
                      {t("Main Wallet")}
                    </button>
                    <button
                      className=""
                      onClick={() => setSelectedSegment("deposit")}
                    >
                      {t("Deposit")}
                    </button>
                    <button
                      className=""
                      onClick={() => setSelectedSegment("withdrawal")}
                    >
                      {t("Withdrawal")}
                    </button>
                  </div>
                  {selectedSegment === "main_wallet" ? (
                    <div className="table-responsive">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Amount ")}</th>
                            <th>{t("Final Balance")}</th>
                            <th>{t("Transaction ID")}</th>
                            <th>{t("Currency Type")}</th>
                            <th>{t("Created")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Wallet to Spot</td>
                            <td>50</td>
                            <td>50 </td>
                            <td>HsXZjd </td>
                            <td>BTC </td>
                            <td>Mar 15, 2024 4:48 PM</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : selectedSegment === "deposit" ? (
                    <div className="table-responsive">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Token ID")}</th>
                            <th>{t("Created")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>50</td>
                            <td>Deposit</td>
                            <td>BTC/USDT</td>
                            <td>Mar 15, 2024 4:48 PM</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Withdrawal Fee")}</th>
                            <th>{t("Withdrawal Token ID")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Transaction Hash")}</th>
                            <th>{t("Created")}</th>
                            <th>{t("Status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>50</td>
                            <td>0.02</td>
                            <td>BTC</td>
                            <td>Withdrawal</td>
                            <td>TX_6h&kdjK</td>
                            <td>Mar 15, 2024 4:48 PM</td>
                            <td>Pending</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              ) : (
                <div className="p-3  p-md-4 bg2 br20 mb-4">
                  <div className="nav-tabs2 ">
                    <button
                      className="active"
                      onClick={() => setSelectedSegment("main_wallet")}
                    >
                      {t("Main Wallet")}
                    </button>
                    <button
                      className=""
                      onClick={() => setSelectedSegment("deposit")}
                    >
                      {t("Deposit")}
                    </button>
                    <button
                      className=""
                      onClick={() => setSelectedSegment("withdrawal")}
                    >
                      {t("Withdrawal")}
                    </button>
                  </div>
                  {selectedSegment === "main_wallet" ? (
                    <div className="table-responsive">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Amount ")}</th>
                            <th>{t("Final Balance")}</th>
                            <th>{t("Transaction ID")}</th>
                            <th>{t("Currency Type")}</th>
                            <th>{t("Created")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Wallet to Spot</td>
                            <td>50</td>
                            <td>50 </td>
                            <td>HsXZjd </td>
                            <td>BTC </td>
                            <td>Mar 15, 2024 4:48 PM</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : selectedSegment === "deposit" ? (
                    <div className="table-responsive">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Token ID")}</th>
                            <th>{t("Created")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>50</td>
                            <td>Deposit</td>
                            <td>BTC/USDT</td>
                            <td>Mar 15, 2024 4:48 PM</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-striped  ">
                        <thead>
                          <tr>
                            <th>{t("Sr. No.")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Withdrawal Fee")}</th>
                            <th>{t("Withdrawal Token ID")}</th>
                            <th>{t("Transaction Type")}</th>
                            <th>{t("Transaction Hash")}</th>
                            <th>{t("Created")}</th>
                            <th>{t("Status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>50</td>
                            <td>0.02</td>
                            <td>BTC</td>
                            <td>Withdrawal</td>
                            <td>TX_6h&kdjK</td>
                            <td>Mar 15, 2024 4:48 PM</td>
                            <td>Pending</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CryptoTransactionHistory;
