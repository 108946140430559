import { getCodeKyx, verifyCodeKyx } from "../constant/Api";
import * as opsService from "./Ops";
import config from "../constant/Config";

export const sendOtpKyc = async (data) => {
  let result = await opsService.postdata(getCodeKyx, data, config);
  return result;
};

export const verifyOtpKyc = async (data) => {
  let result = await opsService.postdata(verifyCodeKyx, data, config);
  return result;
};
