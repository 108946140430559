import React, { useEffect, useState, useMemo } from "react";
import ExchangeNavBar from "../widgets/ExchangeNavBar";
import Footer from "../widgets/Footer";
import {
  useGetAllUserWalletQuery,
  useTransferAccountMutation,
  usePaymentRequestMutation,
  usePaymentCountryQuery,
  usePaymentMethodsMutation,
  useWithdrawalRequestMutation,
} from "../../redux/accountApi";
import { imageUrl } from "../constant/BaseUrl";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useGetCryptoPortfolioQuery } from "../../redux/userApi";
import Sidebar from "../widgets/Sidebar";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../util/decimalFormatter";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "2px solid #ccc",
    borderRadius: "5px",
    // padding: "10px",
    boxShadow: state.isFocused ? null : null,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#007bff" : "white",
    color: state.isSelected ? "white" : "black",
    padding: "10px",
  }),
  menu: (provided, state) => ({
    ...provided,
    width: "300px",
    margin: "0 auto",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  }),
};
function convertToTitleCase(text) {
  let words = text.split(/[_\s]+/);

  let capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  let titleCaseString = capitalizedWords.join(" ");

  return titleCaseString;
}

const CryptoAssets = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem("jwtTokon");
  const { data, refetch } = useGetAllUserWalletQuery();
  const { data: countries, isLoading: countriesLoading } =
    usePaymentCountryQuery();
  const [getPaymentMethods, { isLoading: methodsLoading }] =
    usePaymentMethodsMutation();
  const [isWithdrawal, setIsWithdrawal] = useState(false);
  const [transferAmount] = useTransferAccountMutation();
  const [walletTableData, setWalletTableData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [isTransferModelOpen, setIsTransferModelOpen] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [balanceOfAmount, setBalanceOfAmount] = useState(0);
  const [toWallet, setToWallet] = useState("");
  const [fromWallet, setFromWallet] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [coinAmount, setCoinAmount] = useState("");
  const [balanceOfAmountSpot, setBalanceOfAmountSpot] = useState(0);
  const [transferCoinName, setTransferCoinName] = useState("");
  const [amount, setAmount] = useState("");
  const [disableTransferButton, setDisableTransferButton] = useState(false);
  const [transferCoinId, setTransferCoinId] = useState("");
  const [walletBalanceAmount, setWalletBalanceAmount] = useState("");
  const [spotBalance, setSpotBalance] = useState(0);
  const [cryptoPortfolioValue, setCryptoPortfolioValue] = useState(0.0);
  const { data: cryptoPortfolio } = useGetCryptoPortfolioQuery({
    skip: !token,
  });
  const [paymentRequest, { isLoading }] = usePaymentRequestMutation();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currentMethod, setCurrentMethod] = useState("");
  const [selectedCrypto, setSelectedCrypto] = useState("");
  const [withdrawalRequest, { isLoading: isWithdrawaling }] =
    useWithdrawalRequestMutation();
  const [errorDeposit, setErrorDeposit] = useState({
    amount: "",
    paymentType: "",
    country: "",
    paymentMethod: "",
  });
  const [errorWithdrawal, setErrorWithdrawal] = useState({
    amount: "",
    crypto: "",
    country: "",
    paymentMethod: "",
  });

  console.log(countries, "countries");

  const options = useMemo(() => {
    return (
      countries &&
      countries?.map((item) => ({
        value: item.country,
        label: item.name,
      }))
    );
  }, [countries]);

  useEffect(() => {
    if (cryptoPortfolio) {
      if (cryptoPortfolio.length > 0) {
        setCryptoPortfolioValue(cryptoPortfolio[0].USDT);
      }
    }
  }, [cryptoPortfolio]);

  useEffect(() => {
    if (data) {
      setWalletTableData(data);
    }
  }, [data]);

  const handleCheckChange = () => {
    setIsShow(!isShow);
  };

  const openDepositModel = () => {
    setIsDeposit(true);
  };
  const resetStates = () => {
    setPaymentType("");
    setPaymentMethods([]);
    setCurrentMethod("");
    setSelectedCountry(null);
    setAvailableCurrencies([]);
    setCurrentCurrency(null);
    setDepositAmount(null);
  };

  const openWithdrawalModel = () => {
    setIsWithdrawal(true);
  };

  const closeWithdrawal = () => {
    setIsWithdrawal(false);
    resetStates();
  };

  const handleWithdrawalTypeChange = (e) => {
    const { name, value } = e.target;
    if (name === "paymentType") {
      setPaymentType(value);
      if (!value) {
        return setErrorWithdrawal((prevError) => {
          return { ...prevError, paymentType: "This field is required" };
        });
      } else {
        setErrorWithdrawal((prevError) => {
          return { ...prevError, paymentType: "" };
        });
      }
    }
    if (name === "depositAmount") {
      setDepositAmount(value);
      if (!value) {
        return setErrorWithdrawal((prevError) => {
          return { ...prevError, depositAmount: "This field is required" };
        });
      } else {
        setErrorWithdrawal((prevError) => {
          return { ...prevError, depositAmount: "" };
        });
      }
    }
  };

  const handleChangeCrypto = (event) => {
    setSelectedCrypto(event.target.value);
  };

  const openTransferModel = (
    transferCoinName,
    transferCoinId,
    spotBalance,
    walletBalance
  ) => {
    console.log(
      transferCoinName,
      walletBalance,
      spotBalance,
      "coin, wallet, spot"
    );
    setTransferCoinName(transferCoinName);
    setTransferCoinId(transferCoinId);
    setWalletBalanceAmount(walletBalance);
    setSpotBalance(spotBalance);
    setAmount("");
    setIsTransferModelOpen(true);

    if (toWallet === "wallet") {
      setBalanceOfAmountSpot(walletBalance);
      setBalanceOfAmount(spotBalance);
    }
    if (toWallet === "transactions") {
      setBalanceOfAmountSpot(spotBalance);
      setBalanceOfAmount(walletBalance);
    }
  };

  const closeTransferModal = () => {
    setIsTransferModelOpen(false);
    setAmount("");
  };

  const closeDeposit = () => {
    setIsDeposit(false);
    setPaymentType("");
    setPaymentMethods([]);
    setCurrentMethod("");
    setSelectedCountry(null);
    setAvailableCurrencies([]);
    setCurrentCurrency(null);
    setDepositAmount(null); //
  };

  const handleSelectChange = async (selected) => {
    setSelectedCountry(selected);
    const { currencies } = countries.find(
      (item) => item.country === selected.value
    );
    setAvailableCurrencies(currencies);
    setCurrentCurrency(currencies[0]);
    const obj = { country: selected.value, currency: currencies[0] };
    setPaymentMethods([]);
    setCurrentMethod("");

    const { data } = await getPaymentMethods(obj);
    setPaymentMethods(data.data);
  };

  const handleCurrencyChange = async (e) => {
    const { value } = e.target;
    setCurrentCurrency(value);
    const obj = { country: selectedCountry.value, currency: value };
    setPaymentMethods([]);
    setCurrentMethod("");
    const { data } = await getPaymentMethods(obj);
    setPaymentMethods(data.data);
  };

  const handleMethodChange = async (e) => {
    const { value } = e.target;
    setCurrentMethod(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "fromWallet") {
      if (value === "wallet") {
        setBalanceOfAmount(walletBalanceAmount);
        setBalanceOfAmountSpot(spotBalance);
        setToWallet("transactions");
        setFromWallet("wallet");
      }
      if (value === "transactions") {
        setBalanceOfAmount(spotBalance);
        setBalanceOfAmountSpot(walletBalanceAmount);
        setToWallet("wallet");
        setFromWallet("transactions");
      }
    }
    if (name === "toWallet") {
      if (value === "wallet") {
        setBalanceOfAmountSpot(walletBalanceAmount);
        setBalanceOfAmount(spotBalance);
        setFromWallet("transactions");
        setToWallet("wallet");
      }
      if (value === "transactions") {
        setBalanceOfAmountSpot(spotBalance);
        setBalanceOfAmount(walletBalanceAmount);
        setFromWallet("wallet");
        setToWallet("transactions");
      }
    }
    if (name === "amount") {
      const number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          setAmount(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setAmount(value);
        }
      }
    }
  };

  const exchangeValue = () => {
    setFromWallet(toWallet);
    setToWallet(fromWallet);
    setBalanceOfAmount(balanceOfAmountSpot);
    setBalanceOfAmountSpot(balanceOfAmount);
    setCoinAmount("");
  };

  const autoAmount = () => {
    if (balanceOfAmount) {
      setAmount(balanceOfAmount);
    } else {
      setAmount("");
    }
  };

  const handleTransfer = async (event) => {
    event.preventDefault();
    if (!fromWallet || !toWallet) {
      toast.error("Please select trasnfer wallet type");
      return false;
    }
    if (!amount) {
      toast.error("Amount field is required");
      return false;
    }

    const LoginData = {
      fromWallet: fromWallet,
      toWallet: toWallet,
      amount: amount,
      amountType: transferCoinId, //coin id
    };
    setDisableTransferButton(true);
    setTimeout(() => setDisableTransferButton(false), 1000);
    const res = await transferAmount(LoginData);
    console.log("Res", res);
    var resp = res.data;
    if (resp.status === true) {
      refetch();
      toast.success(t(resp.message));
      setIsTransferModelOpen(false);
      setFromWallet("");
      setToWallet("");
      setAmount("");
      setTransferCoinId("");
      setBalanceOfAmountSpot("");
      setBalanceOfAmount("");
    } else {
      toast.dismiss();
      toast.error(t(resp.message));
    }
  };

  const handlePaymentTypeChange = (e) => {
    const { name, value } = e.target;
    if (name === "paymentType") {
      setPaymentType(value);
      if (!value) {
        return setErrorDeposit((prevError) => {
          return { ...prevError, paymentType: "This field is required" };
        });
      } else {
        setErrorDeposit((prevError) => {
          return { ...prevError, paymentType: "" };
        });
      }
    }
    if (name === "depositAmount") {
      setDepositAmount(value);
      if (!value) {
        return setErrorDeposit((prevError) => {
          return { ...prevError, depositAmount: "This field is required" };
        });
      } else {
        setErrorDeposit((prevError) => {
          return { ...prevError, depositAmount: "" };
        });
      }
    }
  };

  const submitPaymentRequest = async (e) => {
    e.preventDefault();

    if (!paymentType) {
      return setErrorDeposit((prevError) => {
        return { ...prevError, paymentType: "This field is required" };
      });
    }

    if (!depositAmount) {
      return setErrorDeposit((prevError) => {
        return { ...prevError, depositAmount: "This field is required" };
      });
    }

    const data =
      paymentType === "crypto"
        ? {
            amount: depositAmount,
            type: paymentType,
          }
        : {
            amount: depositAmount,
            type: paymentType,
            country: selectedCountry.value,
            currency: currentCurrency,
            payment_method: currentMethod,
          };

    console.log(data, "data submiiss");

    const res = await paymentRequest(data);
    console.log("Res", res);
    var resp = res.data;
    if (resp.status === true) {
      toast.success(t(resp.message));
      closeDeposit();
      window.open(resp.data.href, "_blank");
    } else {
      toast.dismiss();
      toast.error(t(resp.message));
    }
  };

  const submitWithdrawalRequest = async (e) => {
    e.preventDefault();

    if (!paymentType) {
      return setErrorWithdrawal((prevError) => {
        return { ...prevError, paymentType: "This field is required" };
      });
    }

    if (!depositAmount) {
      return setErrorWithdrawal((prevError) => {
        return { ...prevError, depositAmount: "This field is required" };
      });
    }

    const data =
      paymentType === "crypto"
        ? {
            amount: depositAmount,
            type: paymentType,
            code: selectedCrypto,
          }
        : {
            amount: depositAmount,
            type: paymentType,
            country: selectedCountry.value,
            currency: currentCurrency,
            payment_method: currentMethod,
          };

    console.log(data, "withdrawal data");

    const res = await withdrawalRequest(data);
    console.log("Res", res);
    var resp = res.data;
    if (resp.status === true) {
      toast.success(t(resp.message));
      closeDeposit();
      window.open(resp.data.href, "_blank");
    } else {
      toast.dismiss();
      toast.error(t(resp.message));
    }
  };

  const tableHtml = () => {
    return walletTableData
      .filter((value) => {
        // Determine if the coin should be shown based on `isShow` and coin amounts
        const hasWalletAmount =
          value.getValue.main[0].coin_amount !== null &&
          value.getValue.main[0].coin_amount != 0;
        const hasSpotAmount =
          value.getValue.spot[0].coin_amount !== null &&
          value.getValue.spot[0].coin_amount != 0;

        // If `isShow` is true, only return items with non-zero amounts
        return !isShow || hasWalletAmount || hasSpotAmount;
      })
      .map((value) => {
        // Simplify the amount assignment using logical OR (||)
        const walletAmount = value.getValue.main[0].coin_amount || 0;
        const spotAmount = value.getValue.spot[0].coin_amount || 0;
        const coinName = value.name;

        return (
          <tr key={value.name}>
            <td className="tl">
              <img
                src={`${imageUrl}/static/currencyImage/${value.coin_icon}`}
                alt="coin_icon"
                className="currency_icon"
              />
              {coinName}
              <small className="ml-1" />
            </td>
            <td>{walletAmount}</td>
            <td>{spotAmount}</td>
            <td>
              <div className="four_btn">
                {/* <button
                  className="btn btn_man fs14 mr-3"
                  onClick={() => openDepositModel()}
                >
                  Deposit
                </button> */}
                <span>
                  {/* <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() => openWithdrawalModel()}
                  >
                    Withdrawal
                  </button> */}
                </span>
                <button
                  className="btn btn_man fs14 mr-3"
                  onClick={() =>
                    openTransferModel(
                      value.name,
                      value.coin_id,
                      value.getValue.spot[0].coin_amount,
                      value.getValue.main[0].coin_amount
                    )
                  }
                >
                  {t("Transfer")}
                </button>
              </div>
            </td>
          </tr>
        );
      });
  };

  const tableHtmlCurrency = () => {
    return walletTableData
      .filter((value) => {
        // Determine if the coin should be shown based on `isShow` and coin amounts
        const hasWalletAmount =
          value.getValue.main[0].coin_amount !== null &&
          value.getValue.main[0].coin_amount != 0;
        const hasSpotAmount =
          value.getValue.spot[0].coin_amount !== null &&
          value.getValue.spot[0].coin_amount != 0;

        // If `isShow` is true, only return items with non-zero amounts
        return !isShow || hasWalletAmount || hasSpotAmount;
      })
      .map((value) => {
        // Simplify the amount assignment using logical OR (||)
        const walletAmount = value.getValue.main[0].coin_amount || 0;
        const coinName = value.name;

        return (
          <tr key={value.name}>
            <td className="tl">
              <img
                src={`${imageUrl}/static/currencyImage/${value.coin_icon}`}
                alt="coin_icon"
                className="currency_icon"
              />
              {coinName}
              <small className="ml-1" />
            </td>
            <td>{walletAmount}</td>
            <td>
              <div className="four_btn">
                <button
                  className="btn btn_man fs14 mr-3"
                  onClick={() => openDepositModel()}
                >
                  {t("Deposit")}
                </button>
                <span>
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() => openWithdrawalModel()}
                  >
                    {t("Withdrawal")}
                  </button>
                </span>
              </div>
            </td>
          </tr>
        );
      });
  };

  return (
    <div>
      <ExchangeNavBar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container">
          <div className="row ">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="p-3  bg2 br20">
                <h5>{t("Wallet")}</h5>
                <div className="row align-items-center mb-3">
                  <div className="col-md-4 col-12">
                    <div className=" form-check pl-0">
                      <label className="form-check-label">
                        <input
                          className="mr-2"
                          name="isGoing"
                          type="checkbox"
                          onChange={handleCheckChange}
                        />
                        {t("Hide Zero Balances Wallet")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-auto col-12 ml-auto">
                    <div className="portfolio-amount d-flex justify-content-end align-items-center">
                      <button
                        className="btn btn_man fs14 mr-3"
                        onClick={() => openDepositModel()}
                      >
                        {t("Deposit")}
                      </button>
                      <button
                        className="btn btn_man fs14 mr-3"
                        onClick={() => openWithdrawalModel()}
                      >
                        {t("Withdrawal")}
                      </button>
                      <p className="m-0">{t("Portfolio")} :</p>
                      <div className="amount-inner d-flex">
                        <div className="amount-right ml-2">
                          <p className="m-0">
                            <b> {formatDecimal(cryptoPortfolioValue, 2)}</b>{" "}
                            USDT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="amount-tabel-main-outer">
                  <div className="in_table tc border_man">
                    <div className="table-responsive crypto_assets_table">
                      <table className="table mb-0 table_man table-borderbb">
                        <thead>
                          <tr>
                            <th className="tl">
                              <b>{t("Coin")}</b>
                            </th>
                            <th>
                              <b>
                                {t("Main")} - {t("Wallet")}
                              </b>
                            </th>
                            <th>
                              <b>
                                {t("Spot")} - {t("Wallet")}
                              </b>
                            </th>
                            <th />
                          </tr>
                        </thead>
                        <tbody className="main">{tableHtml()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <h5>Currency Wallets</h5>
          <div className="p-3  p-md-4 bg2 br20 mb-4">
            <div className="row align-items-center mb-3">
              <div className="col-md-4 col-12">
                <div className=" form-check pl-0">
                  <label className="form-check-label">
                    <input
                      name="isGoing"
                      type="checkbox"
                      onChange={handleCheckChange}
                    />{" "}
                    Hide zero balances wallet
                  </label>
                </div>
              </div>
              <div className="col-md-auto col-12 ml-auto">
                <div className="portfolio-amount d-flex justify-content-end align-items-center">
                  <p className="m-0">Portfolio :</p>
                  <div className="amount-inner d-flex">
                    <div className="amount-right ml-2">
                      <p className="m-0">
                        <b> 0</b> USD
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="amount-tabel-main-outer">
              <div className="in_table tc border_man">
                <div className="table-responsive crypto_assets_table">
                  <table className="table mb-0 table_man table-borderbb">
                    <thead>
                      <tr>
                        <th className="tl">
                          <b>Currency</b>
                        </th>
                        <th>
                          <b>Wallet</b>
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody className="main">{tableHtmlCurrency()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Modal
          show={isDeposit}
          onHide={closeDeposit}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="mb-0"> {t("Deposit")}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeDeposit}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center mb-3">
              <div className="form-group col mb-0">
                <label className=" ">{t("Select Payment Type")}</label>
                <select
                  className="form-control"
                  name="paymentType"
                  value={paymentType}
                  onChange={handlePaymentTypeChange}
                >
                  <option value="">{t("Select")}</option>
                  <option value="crypto">{t("Crypto")}</option>
                  <option value="fiat">{t("Fiat")}</option>
                </select>
                <span style={{ color: "red" }}>
                  {t(errorDeposit?.paymentType)}
                </span>
              </div>
            </div>
            {paymentType === "fiat" && (
              <>
                <div className="form-group">
                  <label className=" ">{t("Country")}</label>
                  <div className="row">
                    <div className="position-relative col-md-8">
                      {countriesLoading ? (
                        <select className="form-control" name="currentCurrency">
                          <option value="">Loading countries...</option>
                        </select>
                      ) : (
                        <Select
                          options={options}
                          onChange={handleSelectChange}
                          value={selectedCountry}
                          isDisabled={countriesLoading}
                          isSearchable
                          styles={customStyles}
                        />
                      )}
                    </div>
                    <div className="position-relative col-md-4">
                      <select
                        className="form-control"
                        name="currentCurrency"
                        value={currentCurrency}
                        onChange={handleCurrencyChange}
                      >
                        {availableCurrencies.length > 0 ? (
                          availableCurrencies.map((curr) => (
                            <option value={curr}>{curr}</option>
                          ))
                        ) : (
                          <option value="">{t("No Options")}</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className=" ">{t("Payment Method")}</label>
                  <div className="position-relative">
                    {methodsLoading ? (
                      <select
                        className="form-control"
                        disabled={methodsLoading}
                        name="currentCurrency"
                      >
                        <option value="">{t("Loading")}...</option>
                      </select>
                    ) : (
                      <select
                        className="form-control"
                        name="currentMethod"
                        value={currentMethod}
                        onChange={handleMethodChange}
                        disabled={methodsLoading}
                      >
                        <option value="">{t("Select")}</option>
                        {paymentMethods.map((curr) => (
                          <option value={curr.payment_method}>
                            {convertToTitleCase(curr.payment_method)}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="form-group">
              <label className=" ">{t("Amount")}</label>
              <div className="position-relative">
                <input
                  // value="0"
                  step="any"
                  className="form-control pr-5"
                  name="depositAmount"
                  value={depositAmount}
                  onChange={handlePaymentTypeChange}
                  placeholder={t("Enter Amount")}
                  type="number"
                />
              </div>
              <span style={{ color: "red" }}>
                {t(errorDeposit?.depositAmount)}
              </span>
            </div>
            <div className="tc">
              <button
                className="btn btn_man fs14 w100"
                onClick={submitPaymentRequest}
                disabled={isLoading}
              >
                {isLoading ? "Generating Payment Url..." : t("Deposit")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={isTransferModelOpen}
          onHide={closeTransferModal}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="mb-0"> {t("Transfer")}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeTransferModal}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center mb-3">
              <div className="form-group col mb-0">
                <label className=" ">
                  {t("From")}
                  <span>
                    {" "}
                    {t("balance")} : {balanceOfAmount}
                  </span>{" "}
                </label>

                {toWallet === "wallet" ? (
                  <select
                    className="form-control"
                    name="fromWallet"
                    value={fromWallet}
                    onChange={handleChange}
                  >
                    <option value="">{t("Select")}</option>
                    <option value="transactions">{t("Spot")}</option>
                  </select>
                ) : toWallet === "transactions" ? (
                  <select
                    className="form-control"
                    name="fromWallet"
                    value={fromWallet}
                    onChange={handleChange}
                  >
                    <option value="">{t("Select")}</option>
                    <option value="wallet">{t("Wallet")}</option>
                  </select>
                ) : (
                  <select
                    className="form-control"
                    name="fromWallet"
                    value={fromWallet}
                    onChange={handleChange}
                  >
                    <option value="">{t("Select")}</option>
                    <option value="transactions">{t("Spot")}</option>
                    <option value="wallet">{t("Wallet")}</option>
                  </select>
                )}

                <small>{coinAmount}</small>
              </div>
              <div className="form-group col directions_35_OP pr-0 pl-0 mb-0">
                <span onClick={exchangeValue}>
                  <i className="bi bi-arrow-left-right"></i>
                </span>
              </div>
              <div className="form-group col mb-0">
                <label className=" ">
                  {t("To")}
                  <span>
                    {" "}
                    {t("balance")} : {balanceOfAmountSpot}
                  </span>
                </label>
                {fromWallet == "wallet" ? (
                  <select
                    className="form-control"
                    name="toWallet"
                    value={toWallet}
                    onChange={handleChange}
                  >
                    <option value="">{t("Select")}</option>
                    <option value="transactions">{t("Spot")}</option>
                  </select>
                ) : fromWallet == "transactions" ? (
                  <select
                    className="form-control"
                    name="toWallet"
                    value={toWallet}
                    onChange={handleChange}
                  >
                    <option value="">{t("Select")}</option>
                    <option value="wallet">{t("Wallet")}</option>
                  </select>
                ) : (
                  <select
                    className="form-control"
                    name="toWallet"
                    value={toWallet}
                    onChange={handleChange}
                  >
                    <option value="">{t("Select")}</option>
                    <option value="transactions">{t("Spot")}</option>
                    <option value="wallet">{t("Wallet")}</option>
                  </select>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className=" ">{t("Coin")}</label>
              <div className="form-control">{transferCoinName}</div>{" "}
            </div>
            <div className="form-group">
              <label className=" ">{t("Amount")}</label>
              <div className="position-relative">
                <input
                  // value="0"
                  step="any"
                  className="form-control pr-5"
                  name="amount"
                  value={amount}
                  onChange={handleChange}
                  placeholder={t("Enter Amount")}
                  type="number"
                />
                <button
                  className="absolute_btc btn btn_man fs14"
                  onClick={autoAmount}
                >
                  {" "}
                  {t("Max")}
                </button>
              </div>
            </div>
            <div className="tc">
              <button
                className="btn btn_man fs14 w100"
                onClick={handleTransfer}
                disabled={disableTransferButton}
              >
                {t("Transfer")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={isWithdrawal}
          onHide={closeWithdrawal}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="mb-0">{t("Withdrawal")}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeWithdrawal}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center mb-3">
              <div className="form-group col mb-0">
                <label className=" ">{t("Select Payment Type")}</label>
                <select
                  className="form-control"
                  name="paymentType"
                  value={paymentType}
                  onChange={handleWithdrawalTypeChange}
                >
                  <option value="">{t("Select")}</option>
                  <option value="crypto">{t("Crypto")}</option>
                  <option value="fiat">{t("Fiat")}</option>
                </select>
                <span style={{ color: "red" }}>
                  {t(errorWithdrawal?.paymentType)}
                </span>
              </div>
            </div>
            {paymentType === "fiat" && (
              <>
                <div className="form-group">
                  <label className=" ">Country</label>
                  <div className="row">
                    <div className="position-relative col-md-8">
                      {countriesLoading ? (
                        <select className="form-control" name="currentCurrency">
                          <option value="">Loading countries...</option>
                        </select>
                      ) : (
                        <Select
                          options={options}
                          onChange={handleSelectChange}
                          value={selectedCountry}
                          isDisabled={countriesLoading}
                          isSearchable
                          styles={customStyles}
                        />
                      )}
                    </div>
                    <div className="position-relative col-md-4">
                      <select
                        className="form-control"
                        name="currentCurrency"
                        value={currentCurrency}
                        onChange={handleCurrencyChange}
                      >
                        {availableCurrencies.length > 0 ? (
                          availableCurrencies.map((curr) => (
                            <option value={curr}>{curr}</option>
                          ))
                        ) : (
                          <option value="">No Options</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </>
            )}

            {paymentType === "crypto" && (
              <div className="form-group ">
                <label className=" ">{t("Select Withdrawal Currency")}</label>
                <select
                  className="form-control"
                  name="withdrawalCurrency"
                  value={selectedCrypto}
                  onChange={handleChangeCrypto}
                >
                  <option value="">{t("Select")}</option>
                  {/* <option value="eth">ETH</option>
                  <option value="trx">TRX</option> */}
                  <option value="bscusd">USDT (BEP-20)</option>
                  {/* <option value="btc">BTC</option>
                  <option value="bnb">BNB</option> */}
                </select>
                <span style={{ color: "red" }}>
                  {t(errorWithdrawal?.paymentType)}
                </span>
              </div>
            )}
            <div className="form-group">
              <label className=" ">{t("Amount")}</label>
              <div className="position-relative">
                <input
                  // value="0"
                  step="any"
                  className="form-control pr-5"
                  name="depositAmount"
                  value={depositAmount}
                  onChange={handleWithdrawalTypeChange}
                  placeholder={t("Enter Amount")}
                  type="number"
                />
              </div>
              <span style={{ color: "red" }}>
                {t(errorWithdrawal?.depositAmount)}
              </span>
            </div>
            <div className="tc">
              <button
                className="btn btn_man fs14 w100"
                onClick={submitWithdrawalRequest}
                disabled={isWithdrawaling}
              >
                {isWithdrawaling ? "Submitting..." : t("Withdrawal")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <Footer />
    </div>
  );
};

export default CryptoAssets;
