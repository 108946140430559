
export const KyxValid = (name, value) => {
  let error = "";
  if (name === "contactValue") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    return error;
  }
  if (name === "verificationCode") {
    if (value === "") {
      error = "This field is required";
      return error;
    }

    if (value.length > 6) {
      error = "Please enter valid verification code";
      return error;
    }

    return error;
  }
};
