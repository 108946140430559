import React from "react";
import BigNumber from "bignumber.js";
const MarketTrade = (props) => {
  var trades = props.trades;
  var currentPair = props.currentPair;
  const tradesArr = Object.values(trades);
  const reversedTrades = tradesArr.reverse();
  let currentp = new BigNumber(0);
  let rows = [];
  let numRows = Object.keys(reversedTrades).length;
  let number;
  if (numRows < 17) {
    number = numRows;
  } else {
    number = 17;
  }
  for (var i = 0; i < number; i++) {
    let newp = new BigNumber(reversedTrades[i].cost);
    const spreadAdjustmentFactor =
      props.spreadType === "up"
        ? 1 + props.spreadPercentage / 100
        : 1 - props.spreadPercentage / 100;

    // Compute the adjusted trade price using BigNumber directly, ensuring accuracy.
    const adjustedPrice = newp.multipliedBy(spreadAdjustmentFactor);

    // Now convert the BigNumber result to a float (or keep it as a BigNumber if further precision is needed).
    const tradePrice = parseFloat(adjustedPrice.toString());

    // Log the results.

    // Determine the number of decimal places based on the magnitude of tradePrice.
    const decimalPlaces = tradePrice < 1 ? 8 : 3;

    // Now format the number for display.
    const formattedPrice = tradePrice.toLocaleString("en-US", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });

    // Output the formatted price for verification.
    rows.push(
      <tr className="ng-scope">
        <td className="crypt-up col-4">
          <span className={newp.gte(currentp) ? "text-success" : "text-danger"}>
            {formattedPrice}
          </span>
        </td>
        <td className="col-4">
          {new BigNumber(reversedTrades[i].amount).toFormat(null, 1)}
        </td>
        <td className="col-4">{currentPair}</td>
        <td>{new Date(reversedTrades[i].timestamp).toLocaleTimeString()}</td>
      </tr>
    );
    currentp = new BigNumber(reversedTrades[i].cost);
  }

  return <>{rows}</>;
};

export default MarketTrade;
