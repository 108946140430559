import { Emailpattern, Passwordpattern } from "../pattern/Pattern";

export const LoginValid = (name, value) => {
  let error = "";
  if (name === "username") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    if (value.length > 6) {
      error = "Please enter valid username";
      return error;
    }
    // if (!Emailpattern.test(value)) {
    //   error = "Please enter  valid email address";
    //   return error;
    // }
    return error;
  }
  if (name === "password") {
    if (value === "") {
      error = "This field is required";
      return error;
    }

    // if (!Passwordpattern.test(value)) {
    //   error =
    //     "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character";
    //   return error;
    // }

    return error;
  }
};
