import { addChatsApi, submitTickit, tikitChatByIDSingle } from "../constant/Api";
import * as opsService from "./Ops";
import config from "../constant/Config";

export const addTickit = async (data) => {
  let result = await opsService.postdata(submitTickit, data, config);
  return result;
};

export const getSingleTIckitInfo = async (data) => {
  let result = await opsService.postdata(tikitChatByIDSingle, data, config);
  return result;
};

export const addChats = async (data) => {
  let result = await opsService.postdata(addChatsApi, data, config);
  return result;
};
