import {
  changePasswordApi,
  checkOtp,
  forgotOtp,
  forgotUsernameOtp,
  getUsername,
  loginApi,
  registerApi,
  sendOtp,
} from "../constant/Api";
import * as opsService from "./Ops";
import config from "../constant/Config";

export const logIn = async (data) => {
  let result = await opsService.postdata(loginApi, data);
  return result;
};
export const register = async (data) => {
  let result = await opsService.postdata(registerApi, data);
  return result;
};
export const sendCode = async (data) => {
  let result = await opsService.postdata(sendOtp, data);
  return result;
};
export const sendCodeForgot = async (data) => {
  let result = await opsService.postdata(forgotOtp, data);
  return result;
};
export const sendCodeForgotUsername = async (data) => {
  let result = await opsService.postdata(forgotUsernameOtp, data);
  return result;
};
export const getUserName = async (data) => {
  let result = await opsService.postdata(getUsername, data);
  return result;
};
export const validateCodeResult = async (data) => {
  let result = await opsService.postdata(checkOtp, data);
  return result;
};
export const changePassword = async (data) => {
  let result = await opsService.postdata(changePasswordApi, data, config);
  return result;
};
