import React, { useEffect, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import { useNavigate } from "react-router-dom";
import { updateTradingSegment } from "../services/Users";
import { toast } from "react-hot-toast";
import { useGetallUserQuery } from "../../redux/userApi";
import { useTranslation } from "react-i18next";


const OnBoarding = () => {
  const { t } = useTranslation();
  const { data } = useGetallUserQuery();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [cryptoEnabled, setCryptoEnabled] = useState(false);
  const [equityEnabled, setEquityEnabled] = useState(false);
  const [fxEnabled, setFxEnabled] = useState(false);

  useEffect(() => {
    const isUserOnBoard = localStorage.getItem("isUserOnBoard");
    if (isUserOnBoard === "true") {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data) {
          if(data?.is_onboared === "Y"){
            navigate("/dashboard");
          }
          if (data?.user_name) {
            setUsername(data?.user_name);
          }
        }
      } catch (error) {
        console.error("Error fetching tickit chat:", error);
      }
    };

    fetchData();
  }, [data]);

  const handleCryptoChange = () => {
    setCryptoEnabled(!cryptoEnabled);
  };

  const handleEquityChange = () => {
    setEquityEnabled(!equityEnabled);
  };

  const handleFxChange = () => {
    setFxEnabled(!fxEnabled);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = {
      cryptoString: cryptoEnabled ? "yes" : "no",
      equityString: equityEnabled ? "yes" : "no",
      fxString: fxEnabled ? "yes" : "no",
    };
    const result = await updateTradingSegment(submitData);
    if (result.status) {
      toast.success(t(result.message));
      localStorage.setItem("isUserOnBoard", true);
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } else {
      toast.error(t(result.message));
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login_form pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5 m-auto">
              <div className="bg1 p-3 p-md-4 br20">
                <p className="mb-3">
                  Note: Please save your username some where safe place and do
                  not share your password to anyone.
                </p>
                <form className="">
                  <div className="form-group">
                    <label>{t("Username")}:</label>
                    <label className="ml-3">
                      {username}{" "}
                      <i
                        className="bi bi-copy ml-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(username);
                          toast.success("Copied");
                        }}
                      ></i>
                    </label>
                  </div>
                  <div className="form-group">
                    <label>{t("Password")}:</label>
                    <label className="ml-3">**********</label>
                  </div>

                  <div className="row row8">
                    <div className="form-group col-4">
                      <div className="checkbox">
                        <label className="checkbox-wrapper">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={cryptoEnabled}
                            onChange={handleCryptoChange}
                          />
                          <span className="checkbox-tile">
                            <span className="checkbox-icon">
                              <img src="/img/crypto.png" alt="" />
                            </span>
                            <span className="checkbox-label mt-2">{t("Crypto")}</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-4">
                      <div className="checkbox">
                        <label className="checkbox-wrapper">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={equityEnabled}
                            onChange={handleEquityChange}
                          />
                          <span className="checkbox-tile">
                            <span className="checkbox-icon">
                              <img src="/img/equity.png" alt="" />
                            </span>
                            <span className="checkbox-label mt-2">{t("Equity")}</span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-4">
                      <div className="checkbox">
                        <label className="checkbox-wrapper">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={fxEnabled}
                            onChange={handleFxChange}
                          />
                          <span className="checkbox-tile">
                            <span className="checkbox-icon">
                              <img src="/img/fx.png" alt="" />
                            </span>
                            <span className="checkbox-label mt-2">{t("FX")}</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <a href="!" className="btn" onClick={handleSubmit}>
                      {t("Submit")}{" "}
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OnBoarding;
