import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { LoginValid } from "../validations/LoginValid";
import { logIn } from "../services/Login";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import { toast } from "react-hot-toast";
import { useVerifyLoginCodeMutation } from "../../redux/userApi";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const { authenticated, login } = useAuth();
  const [verifyLoginCode] = useVerifyLoginCodeMutation();
  const navigate = useNavigate();
  const [loginField, setLoginField] = useState({ username: "", password: "" });
  const [loginFieldErr, setLoginFieldErr] = useState({
    username: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState({
    eye: "bi-eye-slash",
    type: "password",
  });
  const [is2FaEnable, setIs2FaEnable] = useState(false);
  const [vcode, setVcode] = useState("");
  const [vcodeError, setVcodeError] = useState("");

  useEffect(() => {
    if (!authenticated) {
      navigate("/login", { replace: true });
    } else {
      navigate("/dashboard", { replace: true });
    }
  }, [authenticated, navigate]);

  const showcurrentPassword = () => {
    if (passwordShow.type === "password") {
      setPasswordShow({ eye: "bi-eye", type: "text" });
    } else {
      setPasswordShow({ eye: "bi-eye-slash", type: "password" });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "vcode") {
      setVcode(value);
    }
    setLoginField({ ...loginField, [name]: value });
    let checkLogin = LoginValid(name, value);
    setLoginFieldErr({ ...loginFieldErr, [name]: checkLogin });
  };

  const handleObBlur = (e) => {
    const { name } = e.target;

    if (name === "username" && loginField.username === "") {
      setLoginFieldErr((prevErrors) => ({
        ...prevErrors,
        username: "This field is required",
      }));
    } else if (name === "password" && loginField.password === "") {
      setLoginFieldErr((prevErrors) => ({
        ...prevErrors,
        password: "This field is required",
      }));
    }
  };

  const onLogin = async (event) => {
    event.preventDefault();
    for (let key in loginField) {
      const checkLogin = LoginValid(key, loginField[key]);
      setLoginFieldErr({ ...loginFieldErr, [key]: checkLogin });
      if (checkLogin !== "") {
        return false;
      }
    }
    const LoginData = {
      username: loginField.username,
      password: loginField.password,
    };
    const result = await logIn(LoginData);
    if (result.status) {
      const token = result.token;
      if (!token) {
        setIs2FaEnable(true);
        toast.success(t(result.message));
      } else {
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userType", result.data.role);
        login();
        toast.success(t(result.message));
        setTimeout(function () {
          navigate("/dashboard", { replace: true });
        }, 2000);
        return false;
      }
    } else {
      toast.dismiss();
      toast.error(t(result.message));
      return;
    }
  };

  const onVerify = async (event) => {
    event.preventDefault();
    if (!vcode) {
      setVcodeError("Code is required");
      return false;
    }
    const data = {
      username: loginField.username,
      code: vcode,
    };
    const result = await verifyLoginCode(data);
    if (result) {
      const resp = result.data;
      const token = resp.token;
      if (resp.status) {
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userType", resp.data.role);
        login();
        toast.success(t(resp.message));
        setTimeout(function () {
          navigate("/dashboard", { replace: true });
        }, 2000);
        return false;
      } else {
        toast.error(t(resp.message));
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login_form pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5 m-auto">
              <div className="bg3 p-3 p-md-4 br20">
                {is2FaEnable === false ? (
                  <>
                    <h3 className="mb-3">{t("Login")}</h3>
                    <form className="">
                      <div className="form-group">
                        <label>{t("Username")}</label>

                        <input
                          className="form-control"
                          autocomplete="off"
                          type="text"
                          name="username"
                          id="username"
                          placeholder={t("Enter Username")}
                          value={loginField.username}
                          onChange={handleChange}
                          onBlur={handleObBlur}
                        />
                        <span className="text-danger">
                          {t(loginFieldErr.username)}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>{t("Password")}</label>
                        <div className="eye_pass position-relative">
                          <input
                            className="form-control"
                            autocomplete="off"
                            type={passwordShow.type}
                            name="password"
                            id="password"
                            placeholder={t("Enter Password")}
                            value={loginField.password}
                            onChange={handleChange}
                            onBlur={handleObBlur}
                          />
                          <span
                            className="password__show position-absolute eye1"
                            onClick={showcurrentPassword}
                          >
                            <i className={`bi ${passwordShow.eye}`}></i>
                          </span>
                        </div>
                        <span className="text-danger">
                          {t(loginFieldErr.password)}
                        </span>
                      </div>
                      <div className="row">
                        <div className="form-group col-6">
                          <Link to={"/forgot-password"} className="login-links">
                            {t("Forgot Password")} ?
                          </Link>
                        </div>
                        <div className="form-group col-6 text-right">
                          <Link to={"/forgot-username"} className="login-links">
                            {t("Forgot")} {t("Username")} ?
                          </Link>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group log-btn mx-auto">
                          <button
                            className="btn w100 btn_man"
                            type="submit"
                            onClick={onLogin}
                          >
                            {t("Login")}
                          </button>
                        </div>
                      </div>

                      <div className="form-group text-center pt-2">
                        {t("Don't have an account?")}
                        <Link className="color1" to={"/register"}>
                          {t("Let's Sign Up")}
                        </Link>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <h3 className="mb-3">{t("Two Factor Authentication")}</h3>
                    <form className="">
                      <div className="form-group">
                        <label>{t("Enter Auth Code")}</label>

                        <input
                          className="form-control"
                          autocomplete="off"
                          type="text"
                          name="vcode"
                          id="vcode"
                          placeholder={t("Enter Code")}
                          value={vcode}
                          onChange={handleChange}
                          onBlur={handleObBlur}
                        />
                        <span className="text-danger">{t(vcodeError)}</span>
                      </div>

                      <div className="form-group">
                        <div className="form-group log-btn mx-auto">
                          <button
                            className="btn w100 btn_man"
                            type="submit"
                            onClick={onVerify}
                          >
                            {t("Verify Code")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
