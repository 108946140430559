import { myApi } from "./api";
import { tickitLists, tikitChatByID } from "../components/constant/Api";

export const SupportApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getTickit: builder.query({
      query: () => ({
        url: tickitLists,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["support"],
    }),
    getTickitChatById: builder.mutation({
      query: (post) => ({
        url: tikitChatByID,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const { useGetTickitQuery, useGetTickitChatByIdMutation } = SupportApi;
