import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, NavLink, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import toast from "react-hot-toast";
import CheckboxList from "./CheckBoxes";
import { useDropdown } from "./useDropdown";
import { useGetUserForexPortfolioMutation } from "../../redux/forexApi";
import { useTranslation } from "react-i18next";
import ChangeLang from "./ChangeLang";
import { formatDecimal } from "../../util/decimalFormatter";
import { useSelector } from "react-redux";

const ExchangeNavBar = ({
  resetLayout,
  changeChartTheme,
  getLayoutSettings,
  layoutChanged,
  refresh,
}) => {
  const { t } = useTranslation();
  const userSegment = useSelector((state) => state.user);
  const token = localStorage.getItem("jwtToken");
  const pathname = window.location.pathname;
  const firstPath = pathname.split("/")[1];
  const { symbol } = useParams();
  const [userPortfolioForex] = useGetUserForexPortfolioMutation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const themeDropdown = useDropdown();
  const menuDropdown = useDropdown();
  const layoutDropdown = useDropdown();
  const [selectedTheam, setSelectedTheam] = useState("");
  const [checkBoxesReal, setCheckBoxes] = useState([]);
  const [floatingProfits, setFloatingProfits] = useState(0.0);
  const [equityPortfolio, setEquityPortfolio] = useState(0.0);
  const [freeBalance, setFreeBalance] = useState(0.0);
  const [usedBalance, setUsedBalance] = useState(0.0);
  const [totalBalance, setTotalBalance] = useState(0.0);

  const checkboxes = [
    { label: t("Chart"), checked: true, setting: "chart" },
    { label: t("Market Depth"), checked: true, setting: "marketdepth" },
    { label: t("Orders"), checked: true, setting: "orderdetails" },
  ];

  const checkCrypto = [
    { label: t("Chart"), checked: true, setting: "chart" },
    { label: t("Market Depth"), checked: true, setting: "marketdepth" },
    { label: t("Pairs List"), checked: true, setting: "pairslist" },
    { label: t("Trades"), checked: true, setting: "trades" },
    { label: t("Wallet"), checked: true, setting: "positions" },
    { label: t("Orders"), checked: true, setting: "orders" },
    // { label: "Sell Orders", checked: true, setting: "sellOrders" },
  ];

  const getPathSegment = () => window.location.pathname.split("/")[1];

  const getDisabledCheckBoxesKey = (pathSegment) => {
    if (pathSegment === "forex" || pathSegment === "forex-equity") return "frl";
    if (pathSegment === "crypto") return "rl";
    return null;
  };

  const getInitialCheckboxes = (pathSegment) => {
    if (pathSegment === "forex" || pathSegment === "forex-equity")
      return checkboxes;
    if (pathSegment === "crypto") return checkCrypto;
    return [];
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      // getForexPortfolio();
      getEquityPortfolio();
    }
  }, [refresh]);

  const getForexPortfolio = async () => {
    const data = {
      symbolType: "forex",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp.status === true) {
      setFloatingProfits(resp.data);
    }
  };

  const getEquityPortfolio = async () => {
    const data = {
      symbolType: "equity",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp.status === true) {
      setFreeBalance(resp.data.freeBalance ? resp.data.freeBalance : "0.0");
      setUsedBalance(resp.data.usedMargin ? resp.data.usedMargin : "0.0");
      setTotalBalance(resp.data.balance ? resp.data.balance : "0.0");
    }
  };

  const updateCheckboxes = useCallback((checkboxes, settingsToUncheck) => {
    return checkboxes.map((checkbox) => {
      if (settingsToUncheck.includes(checkbox.setting)) {
        return { ...checkbox, checked: false };
      }
      return checkbox;
    });
  }, []);

  useEffect(() => {
    const updateCheckboxesState = () => {
      const pathSegment = getPathSegment();
      const disabledCheckBoxesKey = getDisabledCheckBoxesKey(pathSegment);

      if (!disabledCheckBoxesKey) return;

      const disabledCheckBoxes = localStorage.getItem(disabledCheckBoxesKey);
      if (!disabledCheckBoxes || disabledCheckBoxes.length === 0) {
        setCheckBoxes(getInitialCheckboxes(pathSegment));
      } else {
        const updatedCheckboxes = updateCheckboxes(
          pathSegment === "forex" || pathSegment === "forex-equity"
            ? checkboxes
            : checkCrypto,
          disabledCheckBoxes
        );
        setCheckBoxes(updatedCheckboxes);
      }
    };

    const timer = setTimeout(updateCheckboxesState, 1000);
    return () => clearTimeout(timer);
  }, [layoutChanged, updateCheckboxes, setCheckBoxes]);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
      setSelectedTheam(theme);
      if (changeChartTheme) {
        changeChartTheme(theme);
      }
    }
  }, []);

  const setTheme = (e, themeName) => {
    e.preventDefault();
    if (themeName === "defaultTheme") {
      document.body.className = "";
    } else {
      document.body.className = themeName;
    }
    setSelectedTheam(themeName);
    localStorage.setItem("theme", themeName);
    if (changeChartTheme) {
      changeChartTheme(themeName);
    }
  };

  const logoutPage = () => {
    toast.success(t("Logout successfully"));
    localStorage.clear();
    logout();
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  return (
    <div className="header_main bg2">
      <div className="container-fluid">
        <div className="manu d-flex align-items-center">
          <Link to="/">
            <img
              className="head_logo logo_lite"
              src="/img/logo.png"
              alt="Head Logo"
            />
            <img
              src="/img/logo.png"
              alt="Head Logo"
              className="head_logo logo_dark"
            />
          </Link>
          {token ? (
            <div className="exchange_route ml-4 d-flex mr-auto">
              {userSegment.isCryptoEnabled === "yes" && (
                <NavLink
                  exact={true}
                  to="/crypto/BTC/USDT"
                  activeClassName="active"
                >
                  {t("Crypto")}
                </NavLink>
              )}
              {userSegment.isFxEnabled === "yes" && (
                <NavLink
                  exact={true}
                  to={`/forex/${
                    firstPath === "forex"
                      ? symbol
                        ? symbol
                        : "EURUSD"
                      : "EURUSD"
                  }`}
                  activeClassName="active"
                >
                  {t("FX")}
                </NavLink>
              )}
              {userSegment.isEquityEnabled === "yes" && (
                <NavLink
                  exact={true}
                  to={`/forex-equity/${
                    firstPath === "forex-equity"
                      ? symbol
                        ? symbol
                        : "GOOGL"
                      : "GOOGL"
                  }`}
                  activeClassName="active"
                >
                  {t("Equity")}
                </NavLink>
              )}
            </div>
          ) : (
            <div className="exchange_route ml-4 d-flex mr-auto">
              <NavLink
                exact={true}
                to="/crypto/BTC/USDT"
                activeClassName="active"
              >
                {t("Crypto")}
              </NavLink>

              <NavLink
                exact={true}
                to={`/forex/${
                  firstPath === "forex"
                    ? symbol
                      ? symbol
                      : "EURUSD"
                    : "EURUSD"
                }`}
                activeClassName="active"
              >
                {t("FX")}
              </NavLink>

              <NavLink
                exact={true}
                to={`/forex-equity/${
                  firstPath === "forex-equity"
                    ? symbol
                      ? symbol
                      : "GOOGL"
                    : "GOOGL"
                }`}
                activeClassName="active"
              >
                {t("Equity")}
              </NavLink>
            </div>
          )}

          {!token && (
            <>
              <ChangeLang />
              <Link className="btn" to={"/login"}>
                Login
              </Link>
            </>
          )}
          {/* {token && (
            <>
              <div className="row current_unds_item">
                <div className="mr-3">
                  <p className=" mb-0">
                    {t("Total")}: $
                    {isNaN(totalBalance)
                      ? "0.00"
                      : formatDecimal(totalBalance, 2)}
                  </p>
                </div>
                <div className="mr-3">
                  <p className="mb-0">
                    {t("Used")}: $
                    {isNaN(usedBalance)
                      ? "0.00"
                      : formatDecimal(usedBalance, 2)}
                  </p>
                </div>
                <div className="mr-5">
                  <p className="mb-0">
                    {t("Free")}: $
                    {isNaN(freeBalance)
                      ? "0.00"
                      : formatDecimal(freeBalance, 2)}
                  </p>
                </div>
              </div>
            </>
          )} */}
          <ChangeLang />

          {/* <GoogleTranslator /> */}
          {token && (
            <div className="dropdown user_dropdown  mr-3">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => layoutDropdown.setIsOpen(!layoutDropdown.isOpen)}
              >
                <i className="bi bi-gear"></i>
              </span>
              <div
                className={
                  layoutDropdown.isOpen
                    ? "setting_box1 active "
                    : "setting_box1"
                }
              >
                <div className="setting_box2 bg2" ref={layoutDropdown.ref}>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-left ">{t("Layout Settings")}</h6>
                    <div className="text-right">
                      <i
                        class="bi bi-x-lg clode_btn  cursor-pointer"
                        onClick={() =>
                          layoutDropdown.setIsOpen(!layoutDropdown.isOpen)
                        }
                      ></i>
                    </div>
                  </div>
                  {checkBoxesReal.length > 0 && (
                    <CheckboxList
                      checkboxes={checkBoxesReal}
                      getLayoutSettings={getLayoutSettings}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {token && (
            <div className="dropdown user_dropdown  mr-3">
              <span
                type="button"
                className="dropdown-toggle color-toggle"
                data-toggle="dropdown"
                onClick={() => themeDropdown.setIsOpen(!themeDropdown.isOpen)}
              >
                {selectedTheam === "lightTheme" ? (
                  <i className="bi bi-brightness-high"></i>
                ) : selectedTheam === "defaultTheme" ? (
                  <i className="bi bi-moon-stars"></i>
                ) : (
                  <i className="bi bi-moon"></i>
                )}{" "}
              </span>
              <div
                ref={themeDropdown.ref}
                className={
                  themeDropdown.isOpen
                    ? "dropdown-menu2 active"
                    : "dropdown-menu2"
                }
              >
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "lightTheme")}
                >
                  <i className="bi bi-brightness-high mr-2"></i> {t("Light")}
                </a>
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "defaultTheme")}
                >
                  <i className="bi bi-moon-stars mr-1"></i> {t("Dim")}
                </a>
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "darkTheme")}
                >
                  <i className="bi bi-moon mr-1"></i> {t("Dark")}
                </a>
                {resetLayout && (
                  <>
                    <hr />
                    <a
                      className="a_flex"
                      href="#!"
                      onClick={(e) => {
                        resetLayout(window.location.pathname.split("/")[1]);
                      }}
                    >
                      <i className="bi bi-x-diamond mr-1"></i>{" "}
                      {t("Reset Layout")}
                    </a>
                  </>
                )}
              </div>
            </div>
          )}
          {token && (
            <div className="dropdown user_dropdown ">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => menuDropdown.setIsOpen(!menuDropdown.isOpen)}
              >
                <i className="bi bi-person"></i>{" "}
              </span>
              <div
                ref={menuDropdown.ref}
                className={
                  menuDropdown.isOpen
                    ? "dropdown-menu2 active"
                    : "dropdown-menu2"
                }
              >
                <Link className="a_flex" to="/dashboard">
                  <i className="bi bi-grid mr-2"></i>
                  {t("Dashboard")}
                </Link>
                <Link className="a_flex" to={""} onClick={logoutPage}>
                  <i className="bi bi-box-arrow-right mr-2"></i>
                  {t("Logout")}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExchangeNavBar;
