import React,{useState,useEffect}  from "react";
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import i18n from '../../i18n';
const ChangeLang = () => {
    const { t } = useTranslation();

    const [state, setState] = useState({lang:"en"})
//   state = {
//     lang: "en"
//   };

useEffect(()=>{
   // const lang_code = state.lang;
    // i18n.changeLanguage(lang_code);
  // localStorage.setItem("lang", lang_code);
  // const lang = localStorage.getItem("lang");
   
},[state])
  const LANGUAGES = [   
    { label: "English", code: "en" },
    { label: "Chinese", code: "cn" },
    { label: "Hindi", code: "hn" },
    // { label: "Russian", code: "rus" },
    // { label: "Persian", code: "per" },
    // { label: "Arabic", code: "ar" }
    
  ];

 const onChangeLang = (e) => {
    setState({ [e.target.name]: e.target.value })
    localStorage.setItem("lang", e.target.value);
    i18n.changeLanguage(e.target.value);
    if(e.target.value == "ar" || e.target.value == "per"){
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    }else {
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    }

  };

   // const { t } = this.props;
   // const { lang } = this.state;
    return (
      <>
       <Form.Select aria-label="Default select example" defaultValue={i18n.language} onChange={onChangeLang} name="lang" className="lang-control form-control">
        {LANGUAGES.map(({ code, label }) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </Form.Select>   
      </>
    );
  
}
export default ChangeLang;
