import { Passwordpattern } from "../pattern/Pattern";

export const RegisterValid = (name, value) => {
  let error = "";
  if (name === "contactValue") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    // if (!Emailpattern.test(value) && !Mobilepattern.test(value)) {
    //   error = "This field is required";
    //   return error;
    // }
    return error;
  }
  if (name === "password") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    if (!Passwordpattern.test(value)) {
      error =
        "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character";
      return error;
    }

    return error;
  }
  if (name === "newPassword") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    if (!Passwordpattern.test(value)) {
      error =
        "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character";
      return error;
    }

    return error;
  }
  if (name === "confirmPassword") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    if (!Passwordpattern.test(value)) {
      error =
        "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character";
      return error;
    }

    return error;
  }
  if (name === "verificationCode") {
    if (value === "") {
      error = "This field is required";
      return error;
    }

    if (value.length > 6) {
      error = "Please enter valid verification code";
      return error;
    }

    return error;
  }
  if (name === "refrelId") {
    // if (value === "") {
    //   error = "This field is required";
    //   return error;
    // }

    return error;
  }

  if (name === "email") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    const regex = /^[A-Z0-9][A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      value
    );
    if (!regex) {
      error = "Please enter a valid email address";
      return error;
    }
    return error;
  }
};
