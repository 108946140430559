import React, { useEffect, useState } from "react";
import Navbar from "../widgets/Navbar";
import Sidebar from "../widgets/Sidebar";
import {
  useChangeStatus2FaMutation,
  useGenerateGoogleCodeQuery,
  useGetallUserQuery,
} from "../../redux/userApi";
import toast from "react-hot-toast";
import Footer from "../widgets/Footer";
import { useTranslation } from "react-i18next";

const GoogleAuthentication = () => {
  const { t } = useTranslation();
  const { data } = useGenerateGoogleCodeQuery();
  const [changeStatusGoogle] = useChangeStatus2FaMutation();
  const { data: userData, refetch } = useGetallUserQuery();
  const [qrcodeurl, setQrcodeurl] = useState("/img/qr.jpg");
  const [SKey, setSKey] = useState("");
  const [statusText, setStatusText] = useState("Disable");
  const [checkTwoFa, setCheckTwoFa] = useState("");
  const [vcode, setVcode] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    console.log("userDSa", userData);
    if (userData) {
      setCheckTwoFa(userData.enable_2fa);
      if (userData.enable_2fa === "N") {
        setStatusText("Enable");
      } else {
        setStatusText("Disable");
      }
    }
    if (data) {
      setQrcodeurl(data.qrCodeUrl);
      setSKey(data.secretKey);
    }
  }, [data, userData]);

  const handleChange = (e) => {
    const { value } = e.target;
    setVcode(value);
    if (value.replace(/\s+/g, "").length == 0) {
      setMessage("Please enter a valid code");
      return false;
    }
    setMessage("");
  };

  const verifyCode = async () => {
    if (!vcode) {
      setMessage("This field is required");
      return false;
    }
    const data = {
      code: vcode,
      statusText: statusText,
    };
    const res = await changeStatusGoogle(data);
    if (res) {
      const resp = res.data;
      if (resp.status) {
        toast.success(t(resp.message));
        refetch();
        setVcode("");
      } else {
        toast.error(t(resp.message));
      }
    }
    console.log("resp", res);
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row ">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="p-3  p-md-4 bg2 br20 mb-4">
                <h5 className="mb-3">{t("Google Authentication")}</h5>
                <div className=" row ">
                  <div className="col-md-4 col-lg-3">
                    <div className="bg1 br10 p-4 text-center">
                      <img src={qrcodeurl} alt="qr" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8 mt-3 mt-md-0">
                    <p className="wbba">
                      {" "}
                      {t("Key")}: {SKey}
                    </p>
                    <h5 className="mb-3">
                      {t("Status")} :{" "}
                      {checkTwoFa === "Y" ? t("Enabled") : t("Disabled")}
                    </h5>
                    <div className="row align-items-end">
                      <div className="form-group col-8">
                        <label>{t("Authentication Code")}</label>
                        <input
                          placeholder={t("Enter Code")}
                          type="text"
                          className="form-control"
                          value={vcode}
                          onChange={handleChange}
                          name="vcode"
                        />
                        {message && (
                          <span className="text-danger">{t(message)}</span>
                        )}
                      </div>
                      <div className="form-group col-4">
                        <button
                          className="btn w100"
                          type="submit"
                          onClick={verifyCode}
                        >
                          {" "}
                          {statusText === "Enable" ? t("Enable") : t("Disable")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default GoogleAuthentication;
