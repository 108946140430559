import React, { useEffect, useRef, useState, useCallback } from "react";
import update from "immutability-helper";
import socketIOClient from "socket.io-client";
import ExchangeNavBar from "../widgets/ExchangeNavBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { imageUrl, socketUrl } from "../constant/BaseUrl";
import {
  useCancleOrderCryptoMutation,
  useCancleOrderMutation,
  useCloseOrderCryptoMutation,
  useCreateOrderClickCryptoMutation,
  useGetAllActiveTabsQuery,
  useGetSinglePairInfoMutation,
  useModifyOrderClickCryptoMutation,
  usePlaceOrderMutation,
} from "../../redux/exchangeApi";
import MarketTrade from "../partials/MarketTrade";
import Moment from "moment";
import DocumentMeta from "react-document-meta";
import BigNumber from "bignumber.js";
import { ProductName } from "../constant/ProductName";
import toast from "react-hot-toast";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import TradingViewCrypto from "../partials/TradingViewCrypto";
import { Responsive, WidthProvider } from "react-grid-layout";
import moment from "moment";
import { useGetSelectedLeverageQuery } from "../../redux/forexApi";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { formatDecimal, formatTakeProfit } from "../../util/decimalFormatter";
import { useGetUserWidgetsQuery } from "../../redux/userApi";
import UserTicker from "../partials/UserTicker";
import ResizeObserver from "resize-observer-polyfill"; // Import resize observer polyfill

const ResponsiveGridLayout = WidthProvider(Responsive);

const layouts = {
  lg: [
    {
      i: "topbar",
      x: 0,
      y: 0,
      w: 12,
      h: 1.5,
      static: true,
      // setIndex: 1,
    },
    {
      i: "chart",
      x: 3,
      y: 1,
      w: 5.999,
      h: 12,
      minW: 1,
      minH: 6,
      // setIndex: 3,
    },
    {
      i: "pairslist",
      x: 9,
      y: 0,
      w: 3,
      h: 12,
      setIndex: 2,
      minW: 1,
      minH: 1,
    },

    {
      i: "marketdepth",
      x: 0,
      y: 0,
      w: 3,
      h: 12,
      minW: 2,
      minH: 4,
      setIndex: 4,
    },
    {
      i: "buy",
      x: 9,
      y: 1,
      w: 3,
      h: 20,
      minW: 1,
      minH: 1,
      // setIndex: 6,
    },
    { i: "trades", x: 0, y: 1, w: 3, h: 20, minW: 1, minH: 4, setIndex: 5 },
    { i: "sell", x: 6, y: 10, w: 3, h: 9, minW: 3, minH: 8, setIndex: 7 },
    {
      i: "positions",
      x: 0,
      y: 3,
      w: 4.5,
      h: 13,
      minW: 1,
      minH: 4,
      setIndex: 8,
    },
    {
      i: "orderdetails",
      x: 3,
      y: 1,
      w: 5.999,
      h: 20,
      minW: 3,
      minH: 4,
      setIndex: 9,
    },
    {
      i: "sellOrders",
      x: 6,
      y: 3,
      w: 6,
      h: 8.8,
      minW: 3,
      minH: 4,
      setIndex: 10,
    },
  ],
  md: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1.3, static: true },
    {
      i: "pairslist",
      x: 7,
      y: 0,
      w: 3,
      h: 12,
    },
    { i: "chart", x: 0, y: 0, w: 7, h: 12 },
    { i: "marketdepth", x: 7, y: 3, w: 3, h: 12 },
    { i: "trades", x: 4, y: 3, w: 3, h: 12 },
    { i: "buy", x: 7, y: 1, w: 3, h: 10.5 },
    { i: "sell", x: 7, y: 1, w: 3, h: 10.5 },
    { i: "positions", x: 0, y: 3, w: 4, h: 12 },
    { i: "orderdetails", x: 0, y: 1, w: 7, h: 10.5 },
    { i: "sellOrders", x: 0, y: 2, w: 6, h: 8.8 },
  ],
  sm: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1.8, static: true },
    {
      i: "pairslist",
      x: 3,
      y: 1,
      w: 3,
      h: 12,
    },
    { i: "chart", x: 0, y: 0, w: 3, h: 12 },
    { i: "marketdepth", x: 9, y: 3, w: 3, h: 12 },
    { i: "trades", x: 0, y: 3, w: 3, h: 12 },
    { i: "buy", x: 3, y: 1, w: 3, h: 10.5 },
    { i: "sell", x: 3, y: 1, w: 3, h: 10.5 },
    { i: "positions", x: 0, y: 1, w: 3, h: 10.5 },
    { i: "orderdetails", x: 0, y: 2, w: 6, h: 12 },
    { i: "sellOrders", x: 0, y: 2, w: 6, h: 8.8 },
  ],
  xs: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 5.3, static: true },
    {
      i: "pairslist",
      x: 0,
      y: 1,
      w: 12,
      h: 12,
    },
    { i: "chart", x: 0, y: 2, w: 12, h: 10 },
    { i: "marketdepth", x: 9, y: 3, w: 12, h: 12 },
    { i: "trades", x: 0, y: 3, w: 12, h: 7 },
    { i: "buy", x: 0, y: 1, w: 12, h: 10.5 },
    { i: "sell", x: 0, y: 1, w: 12, h: 10.5 },
    { i: "positions", x: 0, y: 1, w: 12, h: 7 },
    { i: "orderdetails", x: 0, y: 2, w: 12, h: 8.8 },
    { i: "sellOrders", x: 0, y: 2, w: 12, h: 8.8 },
  ],
};

const getFromLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    return saved ? JSON.parse(saved) : null;
  }
  return null;
};

const saveToLocalStorage = (key, value) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const defaultError = {
  price: "",
  stopPrice: "",
  takeProfit: "",
};

const CryptoExchange = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const token = localStorage.getItem("jwtToken");
  const localSocket = useRef(null);
  const { data: userWidgets, isLoading: loadingWidgets } =
    useGetUserWidgetsQuery({ type: "crypto" });
  const [visibleRows, setVisibleRows] = useState(10); // Default row count
  const { data: selectedLeverages } = useGetSelectedLeverageQuery();
  const [createOrderOneClickCrypto] = useCreateOrderClickCryptoMutation();
  const [modifyOrderClick] = useModifyOrderClickCryptoMutation();
  const [closeOrderCrypto, { isLoading: positionClosing }] =
    useCloseOrderCryptoMutation();
  const [cancleOrderCrypto] = useCancleOrderCryptoMutation();
  const binanceWebsocket = useRef(null);
  const krakenWebsocket = useRef(null);
  const [getSinglePairInfo, { data: pairData }] =
    useGetSinglePairInfoMutation();
  const [createOrder, { isLoading }] = usePlaceOrderMutation();
  const [selectedAsk, setSelectedAsk] = useState("");
  const [selectedBid, setSelectedBid] = useState("");
  const [deleteOrderApi] = useCancleOrderMutation();
  const { data: tabData } = useGetAllActiveTabsQuery();
  const { firstCoin, secondCoin } = useParams();
  const [setActiveTab, setSetActiveTab] = useState(secondCoin);
  const [buy_commission, setBuy_commission] = useState("");
  const [sell_commission, setSell_commission] = useState("");
  const [buyPrice, setBuyPrice] = useState("");
  const [allOrdersData, setAllOrdersData] = useState([]);
  const [sellPrice, setSellPrice] = useState("");
  const [stopPrice, setStopPrice] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [currentOrderClosing, setCurrentClosingOrder] = useState(null);
  const [stopPriceSell, setStopPriceSell] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [buy_order_total_write, setBuy_order_total_write] = useState("");
  const [buy_order_total, setBuy_order_total] = useState("0");
  const [sell_order_total, setSell_order_total] = useState("");
  const [feeAmyt, setFeeAmyt] = useState("");
  const [sellFeeAmyt, setSellFeeAmyt] = useState("");
  const [firstCoinId, setFirstCoinId] = useState("");
  const [secondCoinId, setSecondCoinId] = useState("");
  const [coinPairData, setCoinPairData] = useState([]);
  const [exchange, setExchange] = useState("");
  const [activeBuyOrder, setActiveBuyOrder] = useState(false);
  const [activeBuyOrderData, setActiveBuyOrderData] = useState([]);
  const [activeSellOrder, setActiveSellOrder] = useState(false);
  const [activeSellOrderData, setActiveSellOrderData] = useState([]);
  const [topBarStatus, setTopBarStatus] = useState(false);
  const [livPrice, setLivPrice] = useState(0);
  const [topBarApiData, setTopBarApiData] = useState([]);
  const [marketTrade, setMarketTrade] = useState(false);
  const [marketTradeData, setMarketTradeData] = useState([]);
  const [disabledBuuButtn, setDisabledBuuButtn] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [exchange_tab, setExchange_tab] = useState([]);
  const [setUrl, setSetUrl] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [binanceTicker, setBinanceTicker] = useState([]);
  const [binanceTrades, setBinanceTrades] = useState([]);
  const [binanceDepth, setBinanceDepth] = useState([]);
  const [buyTabs, setBuyTabs] = useState("pending");
  const [sellTabs, setSellTabs] = useState("pending");
  const [currentPrice, setCurrentPrice] = useState("");
  const [firstCoinBalance, setFirstCoinBalance] = useState("");
  const [secondCoinBalance, setSecondCoinBalance] = useState("");
  const [limit, setLimit] = useState(true);
  const [market, setMarket] = useState(false);
  const [stopLimit, setStopLimit] = useState(false);
  const [InExtype, setInExtype] = useState("exclude");
  const [orderMethod, setOrderMethod] = useState("market");
  const [myActiveBuyOrder, setMyActiveBuyOrder] = useState(false);
  const [myActiveBuyOrderData, setMyActiveBuyOrderData] = useState([]);
  const [myActiveSellOrder, setMyActiveSellOrder] = useState(false);
  const [myActiveSellOrderData, setMyActiveSellOrderData] = useState([]);
  const [myCompletedBuyOrder, setMyCompletedBuyOrder] = useState(false);
  const [myCompletedBuyOrderData, setMyCompletedBuyOrderData] = useState([]);
  const [myCancleOrder, setMyCancleOrder] = useState(false);
  const [myCancleOrderData, setMyCancleOrderData] = useState([]);
  const [myCompletedSellOrder, setMyCompletedSellOrder] = useState(false);
  const [myCompletedSellOrderData, setMyCompletedSellOrderData] = useState([]);
  const [updateAndDeleteButton, setUpdateAndDeleteButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [buyPercentage, setBuyPercentage] = useState("");
  const [bPBtn, setBPBtn] = useState(0);
  const [sPBtn, setSPBtn] = useState(0);
  const [feesType, setFeesType] = useState("");
  const [spreadPercentage, setSpreadPercentage] = useState(0);
  const [spreadType, setSpreadType] = useState("");
  const [spreadRateType, setSpreadRateType] = useState("");
  const [openPositions, setOpenPositions] = useState([]);
  const [favList, setFavList] = useState([]);
  const [activeSegment, setActiveSegment] = useState("margin");
  const [layout, setLayout] = useState(
    getFromLocalStorage("cryptoLayouts") || layouts
  );
  const [accountPnL, setAccountPnL] = useState(new BigNumber(0));
  const [removedItems, setRemovedItems] = useState([]);
  const [theme, setTheme] = useState("dark");
  const [orderPlace, setOrderPlace] = useState("buy");
  const [marketSegment, setMarketSegment] = useState("margin");
  const [openOrders, setOpenOrders] = useState([]);
  const [floatingProfits, setFloatingProfits] = useState(new BigNumber(0));
  const [floatingProfitsClose, setFloatingProfitsClose] = useState(
    new BigNumber(0)
  );
  const [stopErrors, setStopError] = useState({
    buyError: "",
    sellError: "",
    amountError: "",
    amountSellError: "",
    limitError: "",
    limitSellError: "",
  });
  const [showMenu, setShowMenu] = useState(false);
  const [currentRightClickData, setCurrentRightClickData] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [orderSide, setOrderSide] = useState("BUY");
  const [activeSide, setActiveSide] = useState("positions");
  const [pendingOrders, setPendingOrders] = useState([]);
  const [cancleOrders, setCancleOrders] = useState([]);
  const [closedOrders, setClosedOrders] = useState([]);
  const [userLeverage, setUserLeverage] = useState("");
  const [userBalance, setUserBalance] = useState("0.00");
  const [show, setShow] = useState(false);
  const [margin, setMargin] = useState("0.00");
  const [freeMargin, setFreeMargin] = useState("0.00");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [takeProfit, setTakeProfit] = useState("");
  const [sideAcvive, setSideAcvive] = useState("buy");
  const [errors, setErrors] = useState(defaultError);
  const [notAllowedWidgets, setNotAllowedWidgets] = useState([]);
  const [isTopActive, setIsTopActive] = useState(false);
  const [isStopLossActive, setIsStopLossActive] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    if (userWidgets) {
      const disabled = Object.keys(userWidgets).filter(
        (key) => userWidgets[key] === "0"
      );
      if (disabled.length) {
        const removeItemsLocal = getFromLocalStorage("rl") || [];
        setNotAllowedWidgets(disabled);
        let widgetToBeRemoved = [
          ...new Set(
            removeItemsLocal.filter((item) => disabled.includes(item))
          ),
        ];
        let widgetToBeAdded = [
          ...new Set(
            removeItemsLocal.filter((item) => !disabled.includes(item))
          ),
        ];
        widgetToBeAdded.map((item) => addItem(item));
        saveToLocalStorage("rl", widgetToBeRemoved);
      }
      if (!disabled.length) {
        const removeItemsLocal = getFromLocalStorage("rl") || [];
        removeItemsLocal.map((item) => addItem(item));
        saveToLocalStorage("rl", []);
      }
    }
  }, [userWidgets]);

  useEffect(() => {
    if (selectedLeverages) {
      if (selectedLeverages) {
        setUserLeverage(selectedLeverages.amount);
      } else {
        setUserLeverage(1);
      }
    }
  }, [selectedLeverages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const ContextMenu = ({ x, y, onAction }) => (
    <div ref={menuRef} className="context-menu bg2" style={{ top: y, left: x }}>
      <button onClick={() => onAction("cancel")}>
        <span>Cancel</span> <i class="bi bi-x-circle"></i>
      </button>
    </div>
  );

  // useEffect(() => {
  //   const savedLayouts = getFromLocalStorage("cryptoLayouts");
  //   if (savedLayouts) {
  //     setLayout(savedLayouts);
  //   }
  // }, []);

  useEffect(() => {
    if (removedItems.length > 0) {
      saveToLocalStorage("rl", removedItems);
    }
  }, [removedItems]);

  useEffect(() => {
    const removeItemsLocal = getFromLocalStorage("rl");
    if (removeItemsLocal) {
      setRemovedItems(removeItemsLocal);
    }
  }, []);

  const _connectSocketStreams = (streams) => {
    let newArr = [];
    // Close the previous WebSocket connection
    if (binanceWebsocket.current) {
      binanceWebsocket.current.close();
    }
    setLivPrice("0:00");
    const spreadAdjustmentFlat =
      spreadRateType === "flat"
        ? spreadType === "up"
          ? spreadPercentage
          : -spreadPercentage
        : 0; // No flat adjustment if it's percentage based
    try {
      streams = streams.join("/");
      // Create a new WebSocket connection
      binanceWebsocket.current = new WebSocket(
        `wss://stream.binance.com:9443/stream?streams=${streams}`
      );
      binanceWebsocket.current.onmessage = async (evt) => {
        const eventData = JSON.parse(evt.data);
        if (eventData.stream.endsWith("@ticker")) {
          const obj = eventData.data;
          setLivPrice(Number(obj.c) + spreadAdjustmentFlat);
          setBinanceTicker(obj);
          setTopBarStatus(true);
        }

        if (eventData.stream.endsWith("@trade")) {
          const mainValue = eventData.data;
          const data = await secondConstructor(mainValue);
          newArr.push(data);
          if (newArr.length > 17) {
            newArr.shift();
          }
          const binanceTrades = { binanceTrades: newArr };
          const trades = binanceTrades.binanceTrades;
          setBinanceTrades(trades);
        }

        if (eventData.stream.endsWith("@depth20")) {
          setBinanceDepth(eventData.data);
        }
      };

      binanceWebsocket.current.onerror = (evt) => {
        console.error(evt);
      };
    } catch (error) {
      console.log("err2", error);
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    const calculateVisibleRows = () => {
      const rowHeight = 67; // Assume each row takes 40px in height
      const containerHeight = container.offsetHeight;
      const rowsToShow = Math.floor(containerHeight / rowHeight);
      setVisibleRows(rowsToShow);
    };

    // Set up resize observer
    const observer = new ResizeObserver(() => {
      calculateVisibleRows();
    });

    if (container) {
      observer.observe(container);
      calculateVisibleRows(); // Initial calculation
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, []);

  const formatKrakenToBinance = (krakenData) => {
    return {
      e: "24hrTicker", // Hardcoded, as Kraken does not provide this
      E: Date.now(), // Current timestamp, Kraken does not provide exact event time
      s: "XBTUSDT", // Assumed based on your example, adjust as needed
      p: (parseFloat(krakenData.c[0]) - parseFloat(krakenData.o[1])).toFixed(8), // Price change (last price - opening price)
      P: (
        ((parseFloat(krakenData.c[0]) - parseFloat(krakenData.o[1])) /
          parseFloat(krakenData.o[1])) *
        100
      ).toFixed(3), // Price change percentage
      w: krakenData.p[1], // Weighted average price (last 24 hours)
      x: krakenData.o[1], // Opening price (today)
      c: krakenData.c[0], // Last price
      Q: krakenData.c[1], // Last quantity
      b: krakenData.b[0], // Best bid price
      B: krakenData.b[2], // Best bid quantity
      a: krakenData.a[0], // Best ask price
      A: krakenData.a[2], // Best ask quantity
      o: krakenData.o[0], // Opening price
      h: krakenData.h[1], // High price (last 24 hours)
      l: krakenData.l[1], // Low price (last 24 hours)
      v: krakenData.v[1], // Total traded volume (last 24 hours)
      q: krakenData.v[1], // Total traded quote volume (last 24 hours), not available directly from Kraken data
      O: Date.now() - 86400000, // Approximate Open time (24 hours ago), as exact time not provided by Kraken
      C: Date.now(), // Current time as Close time, as exact time not provided by Kraken
      F: "N/A", // First trade ID, not available from Kraken data
      L: "N/A", // Last trade ID, not available from Kraken data
      n: krakenData.t[1], // Number of trades (last 24 hours)
    };
  };

  let orderBook = {
    bids: {},
    asks: {},
  };

  // useEffect(() => {
  //   let totalFloatingProfit = new BigNumber(0);

  //   openOrders.forEach((item) => {
  //     const currentPricea = new BigNumber(item.currentPrice);
  //     const orderPrice = new BigNumber(item.price);
  //     let priceChange;
  //     if (item.order_side === "BUY") {
  //       priceChange = currentPricea.minus(orderPrice);
  //     } else {
  //       priceChange = orderPrice.minus(currentPricea);
  //     }

  //     const quantity = parseFloat(item.quantity);
  //     const floatingProfit = priceChange.multipliedBy(quantity);
  //     const commission = new BigNumber(item.commission || 0); // Assuming commission is part of the item object
  //     const profitAfterCommission = floatingProfit.minus(commission);

  //     totalFloatingProfit = totalFloatingProfit.plus(profitAfterCommission);
  //   });
  //   setFloatingProfits(totalFloatingProfit);
  // }, [openOrders]);

  useEffect(() => {
    let totalFloatingProfit = new BigNumber(0);

    closedOrders.forEach((item) => {
      const currentPricea = new BigNumber(item.closed_price);
      const orderPrice = new BigNumber(item.price);
      let priceChange;
      if (item.order_side === "BUY") {
        priceChange = currentPricea.minus(orderPrice);
      } else if (item.order_side === "SELL") {
        priceChange = orderPrice.minus(currentPricea);
      }

      const quantity = parseFloat(item.quantity);
      const floatingProfit = priceChange.multipliedBy(quantity);
      const commission = new BigNumber(item.commission || 0); // Assuming commission is part of the item object
      const profitAfterCommission = floatingProfit.minus(commission);

      totalFloatingProfit = totalFloatingProfit.plus(profitAfterCommission);
    });

    setFloatingProfitsClose(totalFloatingProfit);
  }, [closedOrders]);

  function updateOrderBook(krakenData) {
    // Check if the message contains 'b' (bids) or 'a' (asks) and update accordingly
    if (krakenData[1].b) {
      krakenData[1].b.forEach((bid) => {
        const price = bid[0];
        const volume = bid[1];
        if (volume === "0.00000000") {
          // Remove bid from the order book if volume is 0
          delete orderBook.bids[price];
        } else {
          // Update or add the bid to the order book
          orderBook.bids[price] = volume;
        }
      });
    } else if (krakenData[1].a) {
      krakenData[1].a.forEach((ask) => {
        const price = ask[0];
        const volume = ask[1];
        if (volume === "0.00000000") {
          // Remove ask from the order book if volume is 0
          delete orderBook.asks[price];
        } else {
          // Update or add the ask to the order book
          orderBook.asks[price] = volume;
        }
      });
    }
  }

  function getFormattedOrderBook() {
    const transformedData = {
      lastUpdateId: Date.now(), // Placeholder, as Kraken doesn't provide a direct equivalent
      bids: Object.entries(orderBook.bids).map(([price, volume]) => [
        price,
        volume,
      ]),
      asks: Object.entries(orderBook.asks).map(([price, volume]) => [
        price,
        volume,
      ]),
    };

    // Sort bids descending and asks ascending
    transformedData.bids.sort((a, b) => parseFloat(b[0]) - parseFloat(a[0]));
    transformedData.asks.sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));

    return transformedData;
  }

  const _connectKrakenSocketStreams = (streams) => {
    let newArr = [];
    // Close the previous WebSocket connection
    if (krakenWebsocket.current) {
      krakenWebsocket.current.close();
    }
    setLivPrice("0:00");
    try {
      krakenWebsocket.current = new WebSocket("wss://ws.kraken.com");

      krakenWebsocket.current.onopen = () => {
        const subscribeMessage = {
          event: "subscribe",
          pair: [streams],
          subscription: { name: "trade" }, // Adjust as needed (e.g., "trade", "book", etc.)
        };
        krakenWebsocket.current.send(JSON.stringify(subscribeMessage));
        const subscribeMessageTicker = {
          event: "subscribe",
          pair: [streams],
          subscription: { name: "ticker" }, // Adjust as needed (e.g., "trade", "book", etc.)
        };
        krakenWebsocket.current.send(JSON.stringify(subscribeMessageTicker));
        const subscribeMessageBook = {
          event: "subscribe",
          pair: [streams],
          subscription: { name: "book", depth: 25 }, // Adjust as needed (e.g., "trade", "book", etc.)
        };
        krakenWebsocket.current.send(JSON.stringify(subscribeMessageBook));
      };

      krakenWebsocket.current.onmessage = async (evt) => {
        const eventData = JSON.parse(evt.data);
        if (Array.isArray(eventData) && eventData[2] === "trade") {
          const adaptedData = adaptKrakenTradeData(eventData);
          newArr.push(adaptedData);
          if (newArr.length > 17) {
            newArr.shift();
          }
          const binanceTrades = { binanceTrades: newArr };
          const trades = binanceTrades.binanceTrades;
          setBinanceTrades(trades);
        }
        if (Array.isArray(eventData) && eventData[2] === "ticker") {
          const obj = formatKrakenToBinance(eventData[1]);

          const spreadAdjustmentFlat =
            spreadRateType === "flat"
              ? spreadType === "up"
                ? spreadPercentage
                : -spreadPercentage
              : 0; // No flat adjustment if it's percentage based

          setLivPrice(Number(obj.c) + spreadAdjustmentFlat);
          setBinanceTicker(obj);
          setTopBarStatus(true);
        }
        if (Array.isArray(eventData) && eventData[2] === "book-25") {
          updateOrderBook(eventData);
          // Optionally, after updating you can format the order book for use/display
          const formattedOrderBook = getFormattedOrderBook();
          setBinanceDepth(formattedOrderBook);
        }
      };

      krakenWebsocket.current.onerror = (evt) => {
        console.error("WebSocket error:", evt);
      };
    } catch (error) {
      console.log("WebSocket connection error:", error);
    }
  };

  const secondConstructor = async (obj) => {
    return {
      cost: obj.p,
      amount: obj.q,
      timestamp: obj.T,
    };
  };

  const adaptKrakenTradeData = (krakenData) => {
    // Assuming krakenData is the array from the trade message
    // And that the first item in the nested array is the trade data
    const tradeDetails = krakenData[1][0];

    return {
      cost: tradeDetails[0], // Price
      amount: tradeDetails[1], // Quantity
      timestamp: tradeDetails[2] * 1000, // Assuming this is the timestamp
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      const pairObj = {
        firstCoin: firstCoin,
        secondCoin: secondCoin,
      };

      try {
        await getSinglePairInfo(pairObj);
      } catch (error) {
        console.error("Error fetching tickit chat:", error);
      }
    };

    fetchData();
  }, [getSinglePairInfo, firstCoin, secondCoin]);

  useEffect(() => {
    if (pairData) {
      const spreadAdjustmentFactor =
        spreadRateType === "percentage"
          ? spreadType === "up"
            ? 1 + spreadPercentage / 100
            : 1 - spreadPercentage / 100
          : 1; // No factor needed for flat spread

      const spreadAdjustmentFlat =
        spreadRateType === "flat"
          ? spreadType === "up"
            ? spreadPercentage
            : -spreadPercentage
          : 0; // No flat adjustment if it's percentage based

      // Apply the appropriate spread calculation based on the rate type
      const buyPr =
        spreadRateType === "percentage"
          ? parseFloat(pairData.currentPairPrice * spreadAdjustmentFactor)
          : parseFloat(pairData.currentPairPrice + spreadAdjustmentFlat);

      setBuyPrice(buyPr);
      setSellPrice(buyPr);
      setCurrentPrice(buyPr);
      setFirstCoinId(pairData.firstCoinId);
      setSecondCoinId(pairData.secondCoinId);
      setBuy_commission(pairData.buy_commission);
      setSell_commission(pairData.sell_commission);
      setFeesType(pairData.type);
    }
  }, [pairData]);

  useEffect(() => {
    const pair = firstCoin + secondCoin;
    const pairObj = {
      firstCoin: firstCoin,
      secondCoin: secondCoin,
    };
    const activeTab = localStorage.getItem("tab");
    setSetUrl(activeTab);
    if (!localSocket.current) {
      const mainState = {
        symbolType: "crypto",
      };
      localSocket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });

      localSocket.current.on("connect", () => {
        console.log("Socket is connected");
        localSocket.current.emit("ping");
        localSocket.current.emit("coinPairList");
        localSocket.current.emit("checkPairBinance", pairObj); // Emit an event to request top bar data for the pair
        localSocket.current.emit("userBalanceByPair", pairObj);
        localSocket.current.emit("userPendingOrders", pairObj);
        localSocket.current.emit("userCompleteOrders", pairObj);
        localSocket.current.emit("userCancledOrders", pairObj);
        localSocket.current.emit("getSpreadPercentage", pairObj);
        localSocket.current.emit("userOpenOrderFX", { symbolType: "crypto" });
        localSocket.current.emit("subscribe", pair);
        localSocket.current.emit("userBalanceById", mainState);
        localSocket.current.emit("userPendingOrderCrypto");
        localSocket.current.emit("userCloseOrderCrypto");
        localSocket.current.emit("userCancledOrderCrypto");
        localSocket.current.emit("userAllOrderCrypto");
        localSocket.current.emit("notificationLogs");
        localSocket.current.emit("getOpenPosition");
        handleSocketMessages(localSocket, firstCoin, secondCoin);
      });
    }
    return () => {
      if (
        localSocket.current &&
        !window.location.pathname.includes("/crypto")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        localSocket.current.disconnect();
      }
    };
  }, [exchange]);

  useEffect(() => {
    if (tabData) {
      setExchange_tab(tabData);
    }
  }, [tabData]);

  const handleSocketMessages = (localSocket, firstCoin, secondCoin) => {
    let newArr = [];
    localSocket.current.on("pong", () => {
      console.log("Received PONG from server");
    });
    localSocket.current.off("coinPairListApi");
    localSocket.current.on("coinPairListApi", (data) => {
      if (data.status === true) {
        // setCoinPairData(data.data);

        const savedOrder = JSON.parse(localStorage.getItem("savedOrder")) || [];

        const mergedData = mergeDataWithOrder(data.data, savedOrder);

        const selectedSymbolData = mergedData.filter(
          (sym) =>
            sym.first_coin_name === firstCoin &&
            sym.second_coin_name === secondCoin
        );

        if (selectedSymbolData.length > 0) {
          setSelectedBid(selectedSymbolData[0].best_bid);
          setSelectedAsk(selectedSymbolData[0].best_ask);
          setCurrentPrice(selectedSymbolData[0].current_price);
          setLivPrice(selectedSymbolData[0].current_price);
        }

        setCoinPairData(mergedData);
      }
    });

    localSocket.current.off(`resultPairBinance_${firstCoin}_${secondCoin}`);
    localSocket.current.on(
      `resultPairBinance_${firstCoin}_${secondCoin}`,
      (topBarData) => {
        const { data, status } = topBarData;
        if (status === true) {
          setExchange(data.exchange);
        } else {
          setExchange("");
        }
      }
    );
    localSocket.current.off("userBalanceByPairApi");
    localSocket.current.on("userBalanceByPairApi", (userBalanceByPairApi) => {
      const { firstCoinBalance, secondCoinBalance } = userBalanceByPairApi;
      setFirstCoinBalance(firstCoinBalance);
      setSecondCoinBalance(secondCoinBalance);
    });
    localSocket.current.off("userPendingOrdersApi");
    localSocket.current.on("userPendingOrdersApi", (pendingOrders) => {
      const { data, status } = pendingOrders;
      if (status) {
        setMyActiveBuyOrder(status);
        setMyActiveBuyOrderData(data);
      } else {
        setMyActiveBuyOrder(status);
        setMyActiveBuyOrderData([]);
      }
    });
    localSocket.current.off("userCompleteOrdersApi");
    localSocket.current.on("userCompleteOrdersApi", (completedOrders) => {
      const { data, status } = completedOrders;
      if (status) {
        setMyCompletedBuyOrder(status);
        setMyCompletedBuyOrderData(data);
      } else {
        setMyCompletedBuyOrder(status);
        setMyCompletedBuyOrderData([]);
      }
    });
    localSocket.current.off("userCancledOrdersApi");
    localSocket.current.on("userCancledOrdersApi", (cancleOrders) => {
      const { data, status } = cancleOrders;
      if (status) {
        setMyCancleOrder(status);
        setMyCancleOrderData(data);
      } else {
        setMyCancleOrder(status);
        setMyCancleOrderData([]);
      }
    });
    localSocket.current.off("resultSpreadPercentage");
    localSocket.current.on("resultSpreadPercentage", (spreadData) => {
      if (spreadData.status === true) {
        setSpreadPercentage(spreadData.data[0].spreadPercentage);
        setSpreadType(spreadData.data[0].type);
        setSpreadRateType(spreadData.data[0].rate_type);
      }
    });
    localSocket.current.off("resultOpenPosition");
    localSocket.current.on("resultOpenPosition", (positionData) => {
      if (positionData.status === true) {
        setOpenPositions(positionData.data);
      }
    });

    localSocket.current.off("userPendingOrderCryptoResult");
    localSocket.current.on("userPendingOrderCryptoResult", (data) => {
      setPendingOrders(data);
    });
    localSocket.current.off("userCloseOrderCryptoResult");
    localSocket.current.on("userCloseOrderCryptoResult", (data) => {
      setClosedOrders(data);
    });
    localSocket.current.off("userOpenOrderFXResult");
    localSocket.current.on("userOpenOrderFXResult", (data) => {
      setOpenOrders(data.orders);
      setFloatingProfits(data.totalFloatingProfit);
      setAccountPnL(data.accountProfitLoss);
    });
    localSocket.current.off("userCancledOrderCryptoCryptoResult");
    localSocket.current.on("userCancledOrderCryptoCryptoResult", (data) => {
      setCancleOrders(data);
    });
    localSocket.current.off("resultUserBalanceById");
    localSocket.current.on("resultUserBalanceById", (data) => {
      if (data.status) {
        const balanceMetrics = data.data;
        setUserBalance(balanceMetrics.balance);
        setMargin(balanceMetrics.cryptoMargin);
        setFreeMargin(balanceMetrics.freeMargin);
        setTopBarStatus(true);
      } else {
        setUserBalance("0.00");
      }
    });
    localSocket.current.off("userAllOrderCryptoResult");
    localSocket.current.on("userAllOrderCryptoResult", (data) => {
      setAllOrdersData(data);
    });
    localSocket.current.off("notificationLogsResult");
    localSocket.current.on("notificationLogsResult", (data) => {
      setNotificationData(data);
    });
    localSocket.current.off(`marketDepth:${firstCoin}${secondCoin}`);
    localSocket.current.on(`marketDepth:${firstCoin}${secondCoin}`, (data) => {
      const binanceData = JSON.parse(data);
      setBinanceDepth(binanceData);
    });
    localSocket.current.off(`orderBook:${firstCoin}${secondCoin}`);
    localSocket.current.on(
      `orderBook:${firstCoin}${secondCoin}`,
      async (data) => {
        const binanceData = JSON.parse(data);
        const changedData = await secondConstructor(binanceData);
        newArr.push(changedData);
        if (newArr.length > 17) {
          newArr.shift();
        }
        const binanceTrades = { binanceTrades: newArr };
        const trades = binanceTrades.binanceTrades;
        setBinanceTrades(trades);
      }
    );
    localSocket.current.off("refresh");
    localSocket.current.on("refresh", () => {
      const pairData = {
        firstCoin: firstCoin,
        secondCoin: secondCoin,
      };
      const mainState = {
        symbolType: "crypto",
      };

      localSocket.current.emit("ping");
      localSocket.current.emit("checkPairBinance", pairData); // Emit an event to request top bar data for the pair
      localSocket.current.emit("userBalanceByPair", pairData);
      localSocket.current.emit("userPendingOrders", pairData);
      localSocket.current.emit("userCompleteOrders", pairData);
      localSocket.current.emit("userCancledOrders", pairData);
      localSocket.current.emit("getSpreadPercentage", pairData);
      localSocket.current.emit("userBalanceById", mainState);
      localSocket.current.emit("userCloseOrderCrypto");
      localSocket.current.emit("userCancledOrderCrypto");
      localSocket.current.emit("getOpenPosition");
      handleSocketMessages(localSocket, firstCoin, secondCoin);
    });
  };

  useEffect(() => {
    if (orderMethod === "market" && buyAmount) {
      if (orderSide === "BUY") {
        const buyOrderTotal = (
          parseFloat(buyAmount) * parseFloat(selectedBid)
        ).toFixed(8);
        setBuy_order_total(buyOrderTotal);
      } else {
        const sellOrderTotal = (
          parseFloat(buyAmount) * parseFloat(selectedAsk)
        ).toFixed(8);
        setBuy_order_total(sellOrderTotal);
      }
    }
  }, [selectedBid, selectedAsk, orderSide]);

  const handleDoubleClick = (rowData) => {
    setSideAcvive(rowData.order_side.toLowerCase());

    setStopPrice(rowData.stop_price);
    setTakeProfit(rowData.take_profit);
    setSelectedRowData(rowData);
    setShow(true);
    handleSocketMessages(localSocket, rowData.symbol);
  };

  const openOrderTable = () => {
    if (openOrders.length > 0) {
      return openOrders.map((item, index) => {
        const currentPricea = new BigNumber(item.currentPrice);
        const orderPrice = new BigNumber(item.price);
        let priceChange;
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else {
          priceChange = orderPrice.minus(currentPricea);
        }

        const quantity = parseFloat(item.quantity);
        const floatingProfit = priceChange.multipliedBy(quantity);
        const grossProfitLoss = floatingProfit;

        // Calculate P&L with commission deducted
        const commission = item.commission
          ? new BigNumber(item.commission)
          : new BigNumber(0);
        const netProfitLoss = grossProfitLoss.minus(commission);

        return (
          <tr
            key={item.id}
            style={{ cursor: "pointer" }}
            onDoubleClick={() => handleDoubleClick(item)}
            onContextMenu={(e) =>
              handleRightClick(e, {
                item,
                floatingProfit: formatNumberIso(floatingProfit),
                currentPricea: formatNumberIso(currentPricea),
              })
            }
          >
            <td>{item.firstCoin + "/" + item.secondCoin}</td>
            <td>
              <span
                className="ng-binding ng-scope"
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>{formatDecimal(item.price, 5)}</td>
            <td>
              {item.order_side === "BUY"
                ? formatDecimal(item.best_ask, 5)
                : formatDecimal(item.best_bid, 5)}
            </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>{item.commission ? formatDecimal(item.commission, 5) : "-"}</td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              $
              {isNaN(floatingProfit) ? "0.0" : formatDecimal(floatingProfit, 5)}
            </td>
            <td
              style={
                netProfitLoss < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              ${isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
            </td>
            <td>
              <span
                className="mr-3"
                style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                onClick={() => handleDoubleClick(item)}
              >
                <i className="bi bi-pencil-square"></i>
              </span>
              {positionClosing && currentOrderClosing.id === item.id ? (
                <span
                  className="loading-spin"
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                >
                  <div
                    className="loader"
                    style={{ display: "inline-block" }}
                  ></div>
                </span>
              ) : (
                <span
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                  disabled={positionClosing}
                  onClick={() =>
                    handleCloseOrder(
                      item,
                      formatNumberIso(floatingProfit),
                      formatNumberIso(currentPricea)
                    )
                  }
                >
                  <i className="bi bi-x-circle"></i>
                </span>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={11}>
            {t("You have no open positions")}
          </td>
        </tr>
      );
    }
  };

  const pendingOrderTable = () => {
    if (pendingOrders.length > 0) {
      return pendingOrders.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.firstCoin + "/" + item.secondCoin}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>
              {" "}
              {item.currentPrice} <i className="bi bi-arrow-right"></i>{" "}
              {item.price}
            </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>
              <span
                style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                onClick={() => handleCancleOrder(item)}
              >
                <i className="bi bi-x-circle"></i>
              </span>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={6}>
            {t("You have no pending positions")}
          </td>
        </tr>
      );
    }
  };

  const cancleOrderTable = () => {
    if (cancleOrders.length > 0) {
      return cancleOrders.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.firstCoin + "/" + item.secondCoin}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td> {item.price}</td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={6}>
            {t("You have no cancelled positions")}
          </td>
        </tr>
      );
    }
  };

  const closeOrderTable = () => {
    if (closedOrders.length > 0) {
      return closedOrders.map((item, index) => {
        const currentPricea = new BigNumber(item.closed_price);
        const orderPrice = new BigNumber(item.price);
        let priceChange;
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else if (item.order_side === "SELL") {
          priceChange = orderPrice.minus(currentPricea);
        }

        const quantity = parseFloat(item.quantity);
        const floatingProfit = priceChange.multipliedBy(quantity);

        const grossProfitLoss = floatingProfit;

        // Calculate P&L with commission deducted
        const commission = item.commission
          ? new BigNumber(item.commission)
          : new BigNumber(0);
        const netProfitLoss = grossProfitLoss.minus(commission);

        return (
          <tr key={index}>
            <td>{item.firstCoin + "/" + item.secondCoin}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>{moment(item.updated_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>
              {item.price} <i className="bi bi-arrow-right"></i>{" "}
              {item.closed_price}
            </td>
            <td>{item.commission ? formatDecimal(item.commission, 5) : "-"}</td>
            <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              $
              {isNaN(floatingProfit) ? "0.0" : formatDecimal(floatingProfit, 2)}
            </td>
            <td
              style={
                netProfitLoss < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              ${isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={7}>
            {t("You have no close positions")}
          </td>
        </tr>
      );
    }
  };
  const handleCloseOrder = async (orderInfo, flotingProfit, closePrice) => {
    setCurrentClosingOrder(orderInfo);
    if (positionClosing) return;
    const orderObj = {
      orderId: orderInfo.id,
      firstCoin: orderInfo.firstCoin,
      secondCoin: orderInfo.secondCoin,
      quantity: orderInfo.quantity,
      flotingProfit: flotingProfit ? flotingProfit : "0.0",
      closePrice: closePrice,
      symbolType: "crypto",
    };
    const res = await closeOrderCrypto(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
      setCurrentClosingOrder(null);
    } else {
      toast.error(t(res.data.message));
      setCurrentClosingOrder(null);
    }
  };

  const handleCancleOrder = async (order) => {
    const orderObj = {
      orderId: order.id,
    };
    const resp = await cancleOrderCrypto(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const onSearch = (event) => {
    setSearchedValue(event.target.value);
  };

  const EmptySearch = () => {
    setSearchedValue("");
  };

  const handleBuyChange = (event) => {
    const { name, value } = event.target;
    setInExtype("exclude");
    var buyOrderTotal = "";
    var commission;
    if (name === "buy_order_total" && buyPrice > 0) {
      if (buy_commission !== null) {
        if (feesType === "Percentage") {
          commission = (value * buy_commission) / 100;
        } else {
          commission = buy_commission;
        }
        var buy_value = parseFloat(value) + parseFloat(commission);
      } else {
        buy_value = value;
      }
    } else {
      if (buy_commission !== null) {
        if (feesType === "Percentage") {
          commission = (value * buy_commission) / 100;
        } else {
          commission = buy_commission;
        }
        buy_value = parseFloat(value) + parseFloat(commission);
      } else {
        buy_value = value;
      }
    }
    if (buyAmount == 0) {
      buyOrderTotal = (
        parseFloat(buy_value) * parseFloat(buy_order_total_write)
      ).toFixed(8);
      setBuy_order_total(buyOrderTotal);
    }
    if (name === "buyPrice") {
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 9) {
          setBuyPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setBuyPrice(value);
        }
      }
      if (!value) {
        setBuy_order_total("");
        setFeeAmyt("");
        setStopError((prev) => {
          return {
            ...prev,
            limitError: "This field is required",
          };
        });
        return;
      } else if (Number(value) <= 0) {
        setStopError((prev) => {
          return {
            ...prev,
            limitError: "Price should be greater than zero",
          };
        });
        return;
      } else {
        setStopError((prev) => {
          return {
            ...prev,
            limitError: "",
          };
        });
      }
    }
    if (name === "stopPriceBuy") {
      const stopPrice = Number(value);
      if (stopPrice === Math.floor(stopPrice)) {
        if (value.length <= 9) {
          setStopPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setStopPrice(value);
        }
      }

      if (!value) {
        setStopError((prev) => {
          return {
            ...prev,
            buyError: "This field is required",
          };
        });
        return;
      } else if (Number(stopPrice) <= 0) {
        setStopError((prev) => {
          return {
            ...prev,
            buyError: "Stop price should be greater than zero",
          };
        });
        return;
      }
      if (marketSegment === "margin") {
        if (orderPlace === "buy" && stopPrice >= livPrice) {
          // console.log(stopPrice, currentPrice, "stopPrice < currentPrice");
          setStopError((prev) => {
            return {
              ...prev,
              buyError: "Trigger price should be lower than Last price",
            };
          });
          return;
        } else if (orderPlace === "sell" && stopPrice <= livPrice) {
          // console.log(stopPrice, currentPrice, "stopPrice < currentPrice");
          setStopError((prev) => {
            return {
              ...prev,
              buyError: "Trigger price should be higher than Last price",
            };
          });
          return;
        } else {
          setStopError((prev) => {
            return {
              ...prev,
              buyError: "",
            };
          });
        }
      } else {
        if (stopPrice >= livPrice) {
          // console.log(stopPrice, currentPrice, "stopPrice < currentPrice");
          setStopError((prev) => {
            return {
              ...prev,
              buyError: "Trigger price should be lower than Last price",
            };
          });
          return;
        } else {
          setStopError((prev) => {
            return {
              ...prev,
              buyError: "",
            };
          });
        }
      }
    }

    if (name === "takeProfit") {
      setTakeProfit(value);
      // if (sideAcvive === "buy") {
      //   if (value !== "" && Number(value) && value <= livPrice) {
      //     errorMessages.takeProfit =
      //       "Set take profit higher than the current level";
      //   } else {
      //     errorMessages.takeProfit = "";
      //   }
      // } else if (sideAcvive === "sell") {
      //   if (Number(value) >= Number(livPrice)) {
      //     errorMessages.takeProfit =
      //       "Set take profit lower than the current level";
      //   } else {
      //     errorMessages.takeProfit = "";
      //   }
      // }
    }

    if (orderMethod === "stop-loss-limit") {
      if (name === "stopPriceBuy") {
        if (Number(value) >= Number(buyPrice)) {
          setStopError((prev) => {
            return {
              ...prev,
              limitError: "Limit price should be higher than Trigger price",
            };
          });
          return;
        } else {
          setStopError((prev) => {
            return {
              ...prev,
              limitError: "",
            };
          });
          return;
        }
      } else {
        if (name === "buyPrice" && !value) {
          setStopError((prev) => {
            return {
              ...prev,
              limitError: "This field is required",
            };
          });
        } else if (name === "buyPrice" && Number(value) <= 0) {
          setStopError((prev) => {
            return {
              ...prev,
              limitError: "Limit price should be greater than zero",
            };
          });
        } else if (name === "buyPrice" && Number(value) <= Number(stopPrice)) {
          setStopError((prev) => {
            return {
              ...prev,
              limitError: "Limit price should be higher than Trigger price",
            };
          });
          return;
        } else if (name === "buyPrice" && Number(value) > Number(stopPrice)) {
          setStopError((prev) => {
            return {
              ...prev,
              limitError: "",
            };
          });
        }
      }
    }

    if (name === "buyPrice" && buyAmount > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(buyAmount);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          setBuyPrice(value);
          buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
            8
          );
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setBuyPrice(formattedValue);
            buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
              8
            );
            setBuy_order_total(buyOrderTotal);
            setFeeAmyt(buyOrderTotalWithFee);
          }
        }
      } else {
        if (value.includes(".") && value.toString().split(".")[1].length <= 8) {
          setBuyPrice(value);
          buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
            8
          );
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
        }
      }
    } else if (name === "buyAmount") {
      if (!value) {
        setStopError((prev) => {
          return {
            ...prev,
            amountError: "This field is required",
          };
        });
      } else if (Number(value) <= 0) {
        setStopError((prev) => {
          return {
            ...prev,
            amountError: "Amount should be greater than zero",
          };
        });
      } else {
        setStopError((prev) => {
          return {
            ...prev,
            amountError: "",
          };
        });
      }
      let buyOrderTotalWithFee;
      if (feesType === "Percentage") {
        buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(buyPrice);
      } else {
        buyOrderTotalWithFee =
          parseFloat(buy_value) + parseFloat(value) * parseFloat(buyPrice);
      }
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(8);
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
          setBuyAmount(value);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setBuyAmount(formattedValue);
            buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(
              8
            );
            setBuy_order_total(buyOrderTotal);
            setFeeAmyt(buyOrderTotalWithFee);
          }
        }
      } else {
        if (value.includes(".") && value.toString().split(".")[1].length <= 8) {
          buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(8);
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
          setBuyAmount(value);
        }
      }
    } else if (name === "buy_order_total" && buyPrice > 0) {
      let buyOrderTotal = (parseFloat(value) / parseFloat(buyPrice)).toFixed(8);
      let buyOrderTotalWithFee = parseFloat(buy_value);
      setBuy_order_total_write(buyOrderTotal);
      setBuyAmount(buyOrderTotal);
      setFeeAmyt(buyOrderTotalWithFee);
    }
  };
  const handleSellChange = (event) => {
    const { name, value } = event.target;

    setInExtype("exclude");
    var sellOrderTotal = "";
    var commission;
    if (name === "sell_order_total" && sellPrice > 0) {
      if (sell_commission !== null) {
        if (feesType === "Percentage") {
          commission = value * (100 / (100 + sell_commission));
        } else {
          commission = sell_commission;
        }
        var sell_value = parseFloat(commission);
      } else {
        sell_value = value;
      }
    } else {
      if (sell_commission !== null) {
        if (feesType === "Percentage") {
          commission = value * (100 / (100 + sell_commission));
        } else {
          commission = sell_commission;
        }
        sell_value = commission;
      } else {
        sell_value = value;
      }
    }
    if (name === "sellPrice") {
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          setSellPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setSellPrice(value);
        }
      }

      if (!value) {
        setSell_order_total("");
        setStopError((prev) => {
          return {
            ...prev,
            limitSellError: "This field is required",
          };
        });
        return;
      } else if (Number(value) <= 0) {
        setStopError((prev) => {
          return {
            ...prev,
            limitSellError: "Price should be greater than zero",
          };
        });
        return;
      } else {
        setStopError((prev) => {
          return {
            ...prev,
            limitSellError: "",
          };
        });
      }
    }
    if (name === "stopPriceSell") {
      var sellStop = Number(value);
      if (sellStop === Math.floor(sellStop)) {
        if (value.length < 9) {
          setStopPriceSell(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setStopPriceSell(value);
        }
      }
      if (!Number(value)) {
        setStopError((prev) => {
          return {
            ...prev,
            sellError: "This field is required",
          };
        });
        return;
      } else if (Number(sellStop) <= 0) {
        setStopError((prev) => {
          return {
            ...prev,
            sellError: "Trigger price should be greater than zero",
          };
        });
        return;
      } else if (
        orderMethod === "stop-loss-limit" &&
        Number(sellStop) < Number(sellPrice)
      ) {
        setStopError((prev) => {
          return {
            ...prev,
            limitSellError: "Limit price should be lower than Trigger price",
            sellError: "",
          };
        });
        return;
      } else {
        setStopError((prev) => {
          return {
            ...prev,
            limitSellError: "",
            // sellError: "",
          };
        });
      }
      if (sellStop <= Number(livPrice)) {
        setStopError((prev) => {
          return {
            ...prev,
            sellError: "Trigger price should be higher than Last price",
          };
        });
        return;
      } else {
        setStopError((prev) => {
          return {
            ...prev,
            sellError: "",
          };
        });
        // return;
      }
    }

    if (orderMethod === "stop-loss-limit") {
      if (name === "stopPriceSell") {
      } else {
        if (name === "sellPrice" && !value) {
          setStopError((prev) => {
            return {
              ...prev,
              limitSellError: "This field is required",
            };
          });
        } else if (
          name === "sellPrice" &&
          Number(value) >= Number(stopPriceSell)
        ) {
          setStopError((prev) => {
            return {
              ...prev,
              limitSellError: "Limit price should be lower than Trigger price",
            };
          });
          return;
        } else if (
          name === "sellPrice" &&
          Number(value) < Number(stopPriceSell)
        ) {
          setStopError((prev) => {
            return {
              ...prev,
              limitSellError: "",
            };
          });
        }
      }
    }

    if (name === "sellPrice" && sellAmount > 0) {
      let sellOrderTotalWithFee =
        parseFloat(sell_value) * parseFloat(sellAmount);
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellAmount)).toFixed(
            8
          );
          setSellPrice(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setSellPrice(formattedValue);
            sellOrderTotal = (
              parseFloat(value) * parseFloat(sellAmount)
            ).toFixed(8);
            setSell_order_total(sellOrderTotal);
            setSellFeeAmyt(sellOrderTotalWithFee);
          }
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellAmount)).toFixed(
            8
          );
          setSellPrice(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      }
    } else if (name === "sellAmount") {
      if (!value) {
        setStopError((prev) => {
          return {
            ...prev,
            amountSellError: "This field is required",
          };
        });
      } else if (Number(value) <= 0) {
        setStopError((prev) => {
          return {
            ...prev,
            amountSellError: "Amount should be greater than zero",
          };
        });
      } else {
        setStopError((prev) => {
          return {
            ...prev,
            amountSellError: "",
          };
        });
      }

      let sellOrderTotalWithFee =
        parseFloat(sell_value) * parseFloat(sellPrice);
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellPrice)).toFixed(
            8
          );
          setSellAmount(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setSellAmount(formattedValue);
            sellOrderTotal = (
              parseFloat(value) * parseFloat(sellPrice)
            ).toFixed(8);
            setSell_order_total(sellOrderTotal);
            setSellFeeAmyt(sellOrderTotalWithFee);
          }
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellPrice)).toFixed(
            8
          );
          setSellAmount(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      }
    } else if (name === "sell_order_total" && sellPrice > 0) {
      let sellOrderTotalWithFee = parseFloat(sell_value);
      let sellOrderTotal = (parseFloat(value) / parseFloat(sellPrice)).toFixed(
        8
      );
      setSellAmount(sellOrderTotal);
      setSellFeeAmyt(sellOrderTotalWithFee);
    }
  };

  const removeTrailingZeros = (decimalNumber) => {
    // Convert the decimal number to a string
    let numberStr = decimalNumber.toString();
    // Use a regular expression to remove trailing zeros
    numberStr = numberStr.replace(/\.?0*$/, "");
    // Convert the modified string back to a number
    const result = parseFloat(numberStr).toFixed(8);

    return result;
  };

  const handleBuySubmit = async (e) => {
    e.preventDefault();
    if (!buyPrice && buyPrice <= 0) {
      toast.error(t("Please enter valid amount"));
      return false;
    }
    if (!buyAmount || buyAmount < 0) {
      toast.error(t("Please enter valid amount"));
      return false;
    }
    var price = "";

    if (limit === true || stopLimit === true) {
      price = buyPrice;
    }
    if (market === true || orderMethod === "stop-loss") {
      price = livPrice;
    }

    if (orderMethod === "stop-loss" || orderMethod === "stop-loss-limit") {
      if (Number(stopPrice) <= livPrice) {
        toast.error(t("Trigger price should be higher than last price"));
        return false;
      }

      if (
        orderMethod === "stop-loss-limit" &&
        Number(stopPrice) >= Number(price)
      ) {
        toast.error(t("Limit price should be higher than Trigger Price"));
        return false;
      }
    }

    if (secondCoinBalance <= 0) {
      toast.error(t("Insufficient balance to execute this order"));
      return false;
    }

    if (secondCoinBalance < Number(feeAmyt).toFixed(8)) {
      toast.error(t("Insufficient balance to execute this order"));
      return false;
    }

    let submitData;
    if (InExtype === "include") {
      submitData = {
        orderType: "buy",
        per_price: price,
        volume: buyAmount,
        firstCoinId: firstCoinId,
        secondCoinId: secondCoinId,
        feeAmyt: feeAmyt,
        orderMethod: orderMethod,
        type: InExtype,
        buy_commission: buy_commission,
        percentAmt: buyPercentage,
      };
    } else {
      submitData = {
        orderType: "buy",
        per_price: price,
        volume: buyAmount,
        firstCoinId: firstCoinId,
        secondCoinId: secondCoinId,
        feeAmyt: feeAmyt,
        orderMethod: orderMethod,
        type: InExtype,
        buy_commission: buy_commission,
      };
    }
    if (stopLimit === true) {
      submitData.stopLimitPrice = stopPrice;
    }

    setDisabledBuuButtn(true);
    setTimeout(() => setDisabledBuuButtn(false), 500);
    const res = await createOrder(submitData);
    if (res) {
      const resp = res.data;
      if (!resp.success) {
        toast.error(t(resp.message));
      } else {
        setBuyAmount("");
        setBuy_order_total("");
        setFeeAmyt(0);
        toast.success(t(resp.message));
      }
    }
  };

  const handleSellSubmit = async (e) => {
    e.preventDefault();
    if (sellAmount < 0) {
      toast.error(t("Please enter valid amount"));
      return false;
    }
    if (sellPrice <= 0) {
      toast.error(t("Please enter valid price"));
      return false;
    }
    var price = "";
    if (limit === true || stopLimit === true) {
      price = sellPrice;
    }
    if (market === true || orderMethod === "stop-loss") {
      price = livPrice;
    }

    if (orderMethod === "stop-loss" || orderMethod === "stop-loss-limit") {
      if (Number(stopPriceSell) >= Number(livPrice)) {
        toast.error(t("Trigger price should be lower than last price"));
        return false;
      }
      if (
        orderMethod === "stop-loss-limit" &&
        Number(price) > Number(stopPriceSell)
      ) {
        toast.error(t("Limit price should be lower than Trigger Price"));
        return false;
      }
    }

    if (firstCoinBalance <= 0) {
      toast.error(t("Insufficient balance to execute this order"));
      return false;
    }

    const submitData = {
      orderType: "sell",
      per_price: price,
      volume: sellAmount,
      firstCoinId: firstCoinId,
      secondCoinId: secondCoinId,
      sellFeeAmyt: sellFeeAmyt,
      orderMethod: orderMethod,
      buy_commission: sell_commission,
    };

    if (stopLimit === true) {
      submitData.stopLimitPrice = stopPriceSell;
    }

    const res = await createOrder(submitData);
    if (res) {
      const resp = res.data;
      if (!resp.success) {
        toast.error(t(resp.message));
      } else {
        setSellAmount("");
        setSell_order_total("");
        setSellFeeAmyt("");
        toast.success(t(resp.message));
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleOrderTypeCHange = (orderTy) => {
    const value = orderTy;
    setOrderMethod(value);
    setStopLimit(true);
    setDisabledButton(false);
    setMarket(false);
    setLimit(false);
    setIsStopLossActive(false);
    setStopPrice("");
    if (value === "stop-loss") {
      setIsStopLossActive(true);
      const currPrice = currentPrice;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;
      if (orderPlace === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(currentPrice, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    }
    setStopError({
      buyError: "",
      sellError: "",
      amountError: "",
    });
  };

  const removeTrailingZerosT = (decimalNumber) => {
    // Convert the decimal number to a string
    let numberStr = decimalNumber.toString();

    // Use a regular expression to remove trailing zeros
    numberStr = numberStr.replace(/\.?0*$/, "");

    // Convert the modified string back to a number
    const result = parseFloat(numberStr);

    return result;
  };

  const handleBuyPercent = (percentAmt) => {
    setBuyPercentage(percentAmt);
    var userBalance = secondCoinBalance;

    if (!buyPrice) {
      setBuy_order_total("");
      setStopError((prev) => {
        return {
          ...prev,
          limitError: "This field is required",
        };
      });
      return;
    }

    setStopError((prev) => {
      return {
        ...prev,
        amountError: "",
      };
    });

    if (buy_commission) {
      var percentAmts =
        (parseFloat(percentAmt).toFixed(8) *
          parseFloat(userBalance).toFixed(8)) /
        100;
      let Amount = percentAmts * (100 / (100 + buy_commission));
      let subTotal = Number(Amount).toFixed(8) / Number(buyPrice);
      setFeeAmyt(parseFloat(percentAmts).toFixed(8));
      setBuy_order_total(Amount.toFixed(8));
      setBuyAmount(subTotal.toFixed(8));
      setBPBtn(percentAmt);
      setInExtype("include");
    } else {
      const secondCoinBalanceBN = new BigNumber(secondCoinBalance);
      const percentAmtBN = new BigNumber(percentAmt);
      const buyPriceBN = new BigNumber(buyPrice);

      const percentBalanceBN = secondCoinBalanceBN.times(percentAmtBN).div(100);
      const AmountBN = percentBalanceBN.div(buyPriceBN);
      const subTotalBN = AmountBN.times(buyPriceBN);

      const feeAmyt = subTotalBN.toFixed(8);
      const buy_order_total = subTotalBN.toFixed(8);
      const buyAmount = AmountBN.toFixed(8);

      setFeeAmyt(feeAmyt);
      setBuy_order_total(buy_order_total);
      setBuyAmount(buyAmount);
      setBPBtn(percentAmt);
      setInExtype("include");
    }
  };

  const handleBuyPercentMargin = (percentAmt) => {
    setBuyPercentage(percentAmt);
    var userBalanceMain = userBalance;

    if (!buyPrice) {
      setBuy_order_total("");
      setStopError((prev) => {
        return {
          ...prev,
          limitError: "This field is required",
        };
      });
      return;
    }

    setStopError((prev) => {
      return {
        ...prev,
        amountError: "",
      };
    });

    if (buy_commission) {
      var percentAmts =
        (parseFloat(percentAmt).toFixed(8) *
          parseFloat(userBalanceMain).toFixed(8)) /
        100;
      let Amount = percentAmts * (100 / (100 + buy_commission));
      let subTotal = Number(Amount).toFixed(8) / Number(buyPrice);
      setFeeAmyt(parseFloat(percentAmts).toFixed(8));
      setBuy_order_total(Amount.toFixed(8));
      setBuyAmount(subTotal.toFixed(8));
      setBPBtn(percentAmt);
      setInExtype("include");
    } else {
      const secondCoinBalanceBN = new BigNumber(secondCoinBalance);
      const percentAmtBN = new BigNumber(percentAmt);
      const buyPriceBN = new BigNumber(buyPrice);

      const percentBalanceBN = secondCoinBalanceBN.times(percentAmtBN).div(100);
      const AmountBN = percentBalanceBN.div(buyPriceBN);
      const subTotalBN = AmountBN.times(buyPriceBN);

      const feeAmyt = subTotalBN.toFixed(8);
      const buy_order_total = subTotalBN.toFixed(8);
      const buyAmount = AmountBN.toFixed(8);

      setFeeAmyt(feeAmyt);
      setBuy_order_total(buy_order_total);
      setBuyAmount(buyAmount);
      setBPBtn(percentAmt);
      setInExtype("include");
    }
  };

  const handleSellPercent = (percentAmt) => {
    var userBalance = firstCoinBalance;
    if (!userBalance) {
      return;
    }
    setStopError((prev) => {
      return {
        ...prev,
        amountSellError: "",
      };
    });

    var percentAmts = (percentAmt * userBalance) / 100;
    percentAmts = percentAmts.toFixed(8);
    let finalAmt = parseFloat(percentAmts) * parseFloat(sellPrice);
    let Amount = percentAmts * (100 / (100 + sell_commission));
    let subTotal = Number(Amount) * Number(sellPrice);
    setSellFeeAmyt(subTotal.toFixed(8));
    setSell_order_total(finalAmt.toFixed(8));
    setSellAmount(parseFloat(percentAmts).toFixed(8));
    setSPBtn(percentAmt);
  };

  const handlePlaceOrder = (type) => {
    setOrderPlace(type);
    if (type === "buy") {
      setOrderSide("BUY");
    } else {
      setOrderSide("SELL");
    }

    setStopError((prev) => {
      return {
        sellError: "",
        buyError: "",
      };
    });

    if (orderMethod === "stop-loss") {
      const currPrice = currentPrice;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (type === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(currPrice, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    }
  };

  const handleMarketSegment = (e, segment) => {
    e.preventDefault();
    setMarketSegment(segment);
  };

  const buyBoxHtml = () => {
    const spread = parseFloat(selectedAsk - selectedBid).toFixed(5);
    return (
      <div className="h_100">
        <div className="ne_box h_100 p-3 br10 bg2">
          <div className="d-flex mb-3">
            <h6 className="h6_head drag-handle mb-0">Order & Trading </h6>
            <div className="sm_tab  d-flex   text-center ml-auto">
              <a
                className="active"
                href="#!"
                onClick={(e) => handleMarketSegment(e, "spot")}
              >
                {t("Spot")}
              </a>
              <a href="#!" onClick={(e) => handleMarketSegment(e, "margin")}>
                {t("Margin")} <small>{userLeverage}x</small>
              </a>
            </div>
          </div>
          <ul className="exchange_tab2  drag-handle br10  d-flex mb-3 position-relative">
            <span className="bs_center">{formatDecimal(spread, 5)}</span>
            <li
              onClick={() => handlePlaceOrder("sell")}
              className={`drag-cancel ${
                orderPlace === "sell" ? "my_color_sell " : ""
              }`}
            >
              {t("Sell")}
              <br />
              {!selectedAsk ? "0" : formatDecimal(selectedAsk, 5)}
            </li>
            <li
              onClick={() => handlePlaceOrder("buy")}
              className={`drag-cancel ${
                orderPlace === "buy" ? "my_color_buy " : ""
              }`}
            >
              {t("Buy")}
              <br />
              {!selectedBid ? "0" : formatDecimal(selectedBid, 5)}
            </li>
          </ul>
          <div className="position_tab position_tab_center d-md-flex mb-3">
            <a
              href="#!"
              className={orderMethod === "market" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("market")}
            >
              {" "}
              Market
            </a>
            <a
              href="#!"
              className={orderMethod === "limit" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("limit")}
            >
              Limit
            </a>
            <a
              href="#!"
              className={orderMethod === "stop-loss" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("stop-loss")}
            >
              Stop
            </a>
          </div>
          <div className="buy-btc-outer buy-sell-outer border1">
            <form
              name="buyForm"
              autoComplete="off"
              onSubmit={handleBuySubmit}
              className="ng-pristine ng-invalid ng-invalid-required"
            >
              <div className="orderforms-inputs">
                {/* {orderMethod === "stop-loss-limit" ||
                orderMethod === "stop-loss" ? (
                  <div className="field f-cb d-flex">
                    <label className="ng-binding">{t("Trigger")}: </label>
                    <div className="iptwrap leftBig">
                      <input
                        type="number"
                        step="any"
                        value={stopPrice}
                        onChange={handleBuyChange}
                        name="stopPriceBuy"
                        className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                        disabled={disabledButton}
                        onKeyPress={handleKeyPress}
                      />
                      <input type="hidden" id="order_id" name="order_id" />

                      <span className="unit ng-binding">{secondCoin}</span>
                      <span className="text-danger mb-2">
                        {t(stopErrors.buyError)}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="field f-cb mb-2">
                  {orderMethod !== "market" && (
                    <label className="ng-binding d-flex">
                      {t("Price")}:{" "}
                      <span className="f-fr ng-binding ml-auto">
                        {secondCoin} :{" "}
                        {secondCoinBalance
                          ? Number.isInteger(secondCoinBalance)
                            ? secondCoinBalance
                            : removeTrailingZeros(
                                parseFloat(secondCoinBalance).toFixed(8)
                              )
                          : 0}
                      </span>
                    </label>
                  )}
                  <div className="iptwrap leftBig">
                    {/* {orderMethod === "market" || orderMethod === "stop-loss" ? (
                      <>
                        <input
                          type="text"
                          step="any"
                          id="pric"
                          value={
                            orderMethod === "market" ? "Market" : "At Market"
                          }
                          onChange={handleBuyChange}
                          name="buyPrice"
                          className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                          disabled
                          onKeyPress={handleKeyPress}
                        />
                        <input type="hidden" id="order_id" name="order_id" />

                        <span className="unit ng-binding">{secondCoin}</span>
                        <span className="text-danger mb-2">
                          {t(stopErrors.limitError)}
                        </span>
                      </>
                    ) : ( */}
                    {orderMethod !== "market" && (
                      <>
                        <input
                          type={"number"}
                          step="any"
                          id="price_buy"
                          value={
                            disabledButton === true ? currentPrice : buyPrice
                          }
                          onChange={handleBuyChange}
                          name="buyPrice"
                          className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                          disabled={disabledButton}
                        />
                        <input type="hidden" id="order_id" name="order_id" />

                        <span className="unit ng-binding">{secondCoin}</span>
                        <span className="text-danger mb-2">
                          {t(stopErrors.limitError)}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="field f-cb mb-2">
                  <label className="ng-binding d-flex">
                    {" "}
                    {t("Quantity")}:{" "}
                    {orderMethod === "market" && (
                      <span className="f-fr ng-binding ml-auto">
                        {secondCoin} :{" "}
                        {secondCoinBalance
                          ? Number.isInteger(secondCoinBalance)
                            ? secondCoinBalance
                            : removeTrailingZeros(
                                parseFloat(secondCoinBalance).toFixed(8)
                              )
                          : 0}
                      </span>
                    )}
                  </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      name="buyAmount"
                      id="buyAmount"
                      aria-invalid="true"
                      onWheel={(e) => e.target.blur()}
                      value={buyAmount}
                      onChange={handleBuyChange}
                    />
                    {/* <span className="unit ng-binding">{firstCoin}</span> */}
                    <span className="text-danger mb-2">
                      {t(stopErrors.amountError)}
                    </span>
                  </div>
                </div>

                <div className="row  pt-3  mb-3">
                  <div className="col-6">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input check_box"
                        type="checkbox"
                        checked={isTopActive}
                      />
                      <span className="checkmark"></span>
                      <label
                        className="form-check-label pl-2"
                        for="flexCheckChecked"
                        onClick={() => setIsTopActive(!isTopActive)}
                      >
                        Take Profit
                      </label>
                    </div>
                    <input
                      type="text"
                      value={takeProfit}
                      placeholder="0"
                      name="takeProfit"
                      disabled={!isTopActive}
                      onChange={handleBuyChange}
                    />
                  </div>
                  <div className="col-6">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input check_box"
                        type="checkbox"
                        checked={isStopLossActive}
                      />
                      <span className="checkmark"></span>
                      <label
                        className="form-check-label pl-2"
                        for="flexCheckChecked"
                        onClick={() => setIsStopLossActive(!isStopLossActive)}
                      >
                        Stop Loss
                      </label>
                    </div>
                    <input
                      className=""
                      type="text"
                      placeholder="0"
                      value={stopPrice}
                      onChange={handleBuyChange}
                      name="stopPriceBuy"
                      disabled={!isStopLossActive}
                    />
                  </div>
                </div>

                <div className="field percent f-cb">
                  <div className="d-flex field4 mb-2">
                    <span
                      className={`col ${bPBtn === 25 ? "my_color_buy" : ""}`}
                      onClick={() => handleBuyPercent(25)}
                    >
                      25%
                    </span>
                    <span
                      className={`col ${bPBtn === 50 ? "my_color_buy" : ""}`}
                      onClick={() => handleBuyPercent(50)}
                    >
                      50%
                    </span>
                    <span
                      className={`col ${bPBtn === 75 ? "my_color_buy" : ""}`}
                      onClick={() => handleBuyPercent(75)}
                    >
                      75%
                    </span>
                    <span
                      className={`col ${bPBtn === 100 ? "my_color_buy" : ""}`}
                      onClick={() => handleBuyPercent(100)}
                    >
                      100%
                    </span>
                  </div>
                </div>
                <div className="field f-cb mb-2">
                  <label className="ng-binding">{t("Total")}: </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      id="total_buy"
                      name="buy_order_total"
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                      value={formatDecimal(buy_order_total)}
                    />
                    {/* <span className="unit ng-binding">{secondCoin}</span> */}
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: 10 }} className="ng-binding">
                    {t("Trade Fee")} :{" "}
                    {buy_commission == null
                      ? 0
                      : Number.isInteger(buy_commission)
                      ? buy_commission
                      : removeTrailingZerosT(
                          parseFloat(buy_commission).toFixed(4)
                        )}{" "}
                    {buy_commission != null && feesType === "Percentage" && (
                      <span>%</span>
                    )}
                  </p>
                  <p style={{ fontSize: 10 }} className="ng-binding">
                    {t("Net Amount")} :{" "}
                    {feeAmyt ? formatDecimal(feeAmyt, 5) : 0}
                  </p>
                </div>
                <div className="orderforms-inputs">{AuthAction()}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const sellBoxHtml = () => {
    const spread = parseFloat(selectedAsk - selectedBid).toFixed(5);
    return (
      <div className="h_100">
        <div className="ne_box h_100 p-3 br10  bg2">
          <div className="d-flex mb-3">
            <h6 className="h6_head drag-handle mb-0">Trading </h6>
            <div className="sm_tab  d-flex   text-center ml-auto">
              <a
                className="active"
                href="#!"
                onClick={(e) => handleMarketSegment(e, "spot")}
              >
                {t("Spot")}
              </a>
              <a href="#!" onClick={(e) => handleMarketSegment(e, "margin")}>
                {t("Margin")} <small>{userLeverage}x</small>
              </a>
            </div>
          </div>
          <ul className="exchange_tab2  drag-handle br10  d-flex mb-3 position-relative">
            <span className="bs_center">{formatDecimal(spread, 5)}</span>
            <li
              onClick={() => handlePlaceOrder("sell")}
              className={`drag-cancel ${
                orderPlace === "sell" ? "my_color_sell " : ""
              }`}
            >
              {t("Sell")}
              <br />
              {!selectedAsk ? "0" : formatDecimal(selectedAsk, 5)}
            </li>
            <li
              onClick={() => handlePlaceOrder("buy")}
              className={`drag-cancel ${
                orderPlace === "buy" ? "my_color_buy " : ""
              }`}
            >
              {t("Buy")}
              <br />
              {!selectedBid ? "0" : formatDecimal(selectedBid, 5)}
            </li>
          </ul>
          <div className="position_tab position_tab_center d-md-flex mb-3">
            <a
              href="#!"
              className={orderMethod === "market" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("market")}
            >
              {" "}
              Market
            </a>
            <a
              href="#!"
              className={orderMethod === "limit" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("limit")}
            >
              Limit
            </a>
            <a
              href="#!"
              className={orderMethod === "stop-loss" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("stop-loss")}
            >
              Stop
            </a>
          </div>
          <div className="sell-btc-outer buy-sell-outer border1">
            <form
              name="buyForm"
              autoComplete="off"
              onSubmit={handleSellSubmit}
              className="ng-pristine ng-invalid ng-invalid-required"
            >
              <div className="orderforms-inputs  ">
                {/* {orderMethod === "stop-loss-limit" ||
                orderMethod === "stop-loss" ? (
                  <div className="field f-cb d-flex">
                    <label className="ng-binding">{t("Trigger")}: </label>
                    <div className="iptwrap leftBig">
                      <input
                        type="number"
                        step="any"
                        value={stopPriceSell}
                        onChange={handleSellChange}
                        name="stopPriceSell"
                        className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                        disabled={disabledButton}
                        onKeyPress={handleKeyPress}
                      />
                      <input type="hidden" id="order_id" name="order_id" />

                      <span className="unit ng-binding">{secondCoin}</span>
                      <span className="text-danger mb-2">
                        {t(stopErrors.sellError)}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="field f-cb mb-2">
                  {orderMethod !== "market" && (
                    <label className="ng-binding d-flex">
                      {t("Price")}:{" "}
                      <span className="f-fr ng-binding ml-auto">
                        {firstCoin} :{" "}
                        {firstCoinBalance
                          ? Number.isInteger(firstCoinBalance)
                            ? firstCoinBalance
                            : removeTrailingZeros(
                                parseFloat(firstCoinBalance).toFixed(8)
                              )
                          : 0}
                      </span>
                    </label>
                  )}
                  <div className="iptwrap leftBig">
                    {/* {orderMethod === "market" || orderMethod === "stop-loss" ? (
                      <>
                        <input
                          type="text"
                          step="any"
                          id="price_sell"
                          name="sellPrice"
                          value={
                            orderMethod === "market" ? "Market" : "At Market"
                          }
                          disabled
                        />
                        <span className="text-danger mb-2">
                          {t(stopErrors.limitSellError)}
                        </span>
                      </>
                    ) : ( */}
                    {orderMethod !== "market" && (
                      <>
                        <input
                          type="number"
                          step="any"
                          id="price_sell"
                          name="sellPrice"
                          value={sellPrice}
                          onChange={handleSellChange}
                        />
                        <span className="text-danger mb-2">
                          {t(stopErrors.limitSellError)}
                        </span>
                      </>
                    )}
                    <input
                      type="hidden"
                      id="sell_order_id"
                      name="sell_order_id"
                      value=""
                    />
                    {/* <span className="unit ng-binding">{secondCoin}</span> */}
                  </div>
                </div>

                <div className="field f-cb mb-2">
                  <label className="ng-binding d-flex">
                    {t("Amount")}:
                    {orderMethod === "market" && (
                      <span className="f-fr ng-binding ml-auto">
                        {firstCoin} :{" "}
                        {firstCoinBalance
                          ? Number.isInteger(firstCoinBalance)
                            ? firstCoinBalance
                            : removeTrailingZeros(
                                parseFloat(firstCoinBalance).toFixed(8)
                              )
                          : 0}
                      </span>
                    )}
                  </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      name="sellAmount"
                      id="amount_sell"
                      value={sellAmount}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleSellChange}
                    />
                    {/* <span className="unit ng-binding">{firstCoin}</span> */}
                    <span className="text-danger mb-2">
                      {t(stopErrors.amountSellError)}
                    </span>
                  </div>
                </div>
                <div className="row  pt-3  mb-3">
                  <div className="col-6">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input check_box"
                        type="checkbox"
                        checked={isTopActive}
                      />
                      <span className="checkmark"></span>
                      <label
                        className="form-check-label pl-2"
                        for="flexCheckChecked"
                        onClick={() => setIsTopActive(!isTopActive)}
                      >
                        Take Profit
                      </label>
                    </div>
                    <input
                      type="text"
                      value={takeProfit}
                      placeholder="0"
                      name="takeProfit"
                      disabled={!isTopActive}
                      onChange={handleBuyChange}
                    />
                  </div>
                  <div className="col-6">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input check_box"
                        type="checkbox"
                        checked={isStopLossActive}
                      />
                      <span className="checkmark"></span>
                      <label
                        className="form-check-label pl-2"
                        for="flexCheckChecked"
                        onClick={() => setIsStopLossActive(!isStopLossActive)}
                      >
                        Stop Loss
                      </label>
                    </div>
                    <input
                      className=""
                      type="text"
                      placeholder="0"
                      value={stopPrice}
                      onChange={handleBuyChange}
                      name="stopPriceBuy"
                      disabled={!isStopLossActive}
                    />
                  </div>
                </div>
                <div className="field percent f-cb">
                  <div className="d-flex field4 mb-2">
                    <span
                      className={`col ${sPBtn === 25 ? "my_color_sell" : ""}`}
                      onClick={() => handleSellPercent(25)}
                    >
                      25%
                    </span>
                    <span
                      className={`col ${sPBtn === 50 ? "my_color_sell" : ""}`}
                      onClick={() => handleSellPercent(50)}
                    >
                      50%
                    </span>
                    <span
                      className={`col ${sPBtn === 75 ? "my_color_sell" : ""}`}
                      onClick={() => handleSellPercent(75)}
                    >
                      75%
                    </span>
                    <span
                      className={`col ${sPBtn === 100 ? "my_color_sell" : ""}`}
                      onClick={() => handleSellPercent(100)}
                    >
                      100%
                    </span>
                  </div>
                </div>
                <div className="field f-cb mb-2">
                  <label className="ng-binding">{t("Total")}: </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      id="total_sell"
                      name="sell_order_total"
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                      value={formatDecimal(sell_order_total)}
                      onChange={handleSellChange}
                    />
                    {/* <span className="unit ng-binding">{secondCoin}</span> */}
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: 10 }} className="ng-binding">
                    {t("Trade Fee")} :{" "}
                    {sell_commission == null
                      ? 0
                      : Number.isInteger(sell_commission)
                      ? sell_commission
                      : removeTrailingZerosT(
                          parseFloat(sell_commission).toFixed(4)
                        )}{" "}
                    {sell_commission != null && feesType === "Percentage" && (
                      <span>%</span>
                    )}
                  </p>
                  <p style={{ fontSize: 10 }} className="ng-binding">
                    {t("Net Amount")} :{" "}
                    {sellFeeAmyt ? formatDecimal(sellFeeAmyt, 5) : 0}
                  </p>
                </div>
                <div className="orderforms-inputs">{AuthActionSell()}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const handleBuySubmitMargin = async (e) => {
    e.preventDefault();
    let orderPrice;
    if (orderMethod === "market" || orderMethod === "stop-loss") {
      orderPrice = orderSide === "BUY" ? selectedAsk : selectedBid;
    } else {
      orderPrice = buyPrice;
    }
    const orderObj = {
      orderSide: orderSide,
      orderPrice: orderPrice,
      orderQuantity: buyAmount,
      firstCoin: firstCoin,
      secondCoin: secondCoin,
      orderType: orderMethod,
      status: orderMethod === "limit" ? "pending" : "open",
      symbolSide: "crypto",
      stopPrice: orderMethod === "limit" ? null : stopPrice ? stopPrice : null,
      takeProfit:
        orderMethod === "limit" ? null : takeProfit ? takeProfit : null,
    };
    const res = await createOrderOneClickCrypto(orderObj);
    if (res.data.success) {
      setBuyAmount("");
      setRefresh((prev) => !prev);
      setBuy_order_total("");
      setFeeAmyt(0);
      setTakeProfit("");
      setIsTopActive(false);

      toast.success(t(res.data.message));
    } else {
      toast.error(t(res.data.message));
    }
  };

  const marginBoxHtml = () => {
    const spread = parseFloat(selectedAsk - selectedBid).toFixed(5);
    return (
      <div className="h_100">
        <div className="ne_box h_100 p-3 br10 bg2">
          <div className="d-flex mb-3">
            <h6 class="h6_head drag-handle mb-0">{t("Order & Trading")} </h6>
            {/* <div className="sm_tab d-flex text-center ml-auto">
              <a
                className=""
                href="#!"
                onClick={(e) => handleMarketSegment(e, "spot")}
              >
                {t("Spot")}
              </a>
              <a
                href="#!"
                onClick={(e) => handleMarketSegment(e, "margin")}
                className="active"
              >
                {t("Margin")} <small>{userLeverage}x</small>
              </a>
            </div> */}
          </div>
          <ul class="exchange_tab2  drag-handle br10  d-flex mb-3 position-relative">
            <span className="bs_center">{formatDecimal(spread, 5)}</span>
            <li
              onClick={() => handlePlaceOrder("sell")}
              className={`drag-cancel ${
                orderPlace === "sell" ? "my_color_sell " : ""
              }`}
            >
              {t("Sell")}
              <br />
              {!selectedAsk ? "0" : formatDecimal(selectedAsk, 5)}
            </li>
            <li
              onClick={() => handlePlaceOrder("buy")}
              className={`drag-cancel ${
                orderPlace === "buy" ? "my_color_buy " : ""
              }`}
            >
              {t("Buy")}
              <br />
              {!selectedBid ? "0" : formatDecimal(selectedBid, 5)}
            </li>
          </ul>
          <div class="position_tab position_tab_center d-md-flex mb-3">
            <a
              href="#!"
              class={orderMethod === "market" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("market")}
            >
              {" "}
              Market
            </a>
            <a
              href="#!"
              class={orderMethod === "limit" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("limit")}
            >
              Limit
            </a>
            <a
              href="#!"
              class={orderMethod === "stop-loss" ? "active" : ""}
              onClick={() => handleOrderTypeCHange("stop-loss")}
            >
              Stop
            </a>
          </div>

          <div className="buy-btc-outer buy-sell-outer border1">
            <form
              name="buyForm"
              autoComplete="off"
              onSubmit={handleBuySubmitMargin}
              className="ng-pristine ng-invalid ng-invalid-required"
            >
              <div className="orderforms-inputs">
                <div className="field f-cb mb-2">
                  {orderMethod !== "market" && (
                    <label className="ng-binding d-flex">{t("Price")} </label>
                  )}
                  <div className="iptwrap leftBig">
                    {orderMethod !== "market" && (
                      <>
                        <input
                          type={"number"}
                          step="any"
                          id="price_buy"
                          value={
                            disabledButton === true ? currentPrice : buyPrice
                          }
                          onChange={handleBuyChange}
                          name="buyPrice"
                          className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                          disabled={disabledButton}
                        />
                        <input type="hidden" id="order_id" name="order_id" />

                        <span className="text-danger mb-2">
                          {t(stopErrors.limitError)}
                        </span>
                      </>
                    )}
                    {/* )} */}
                  </div>
                </div>
                <div className="field f-cb mb-2">
                  <label className="ng-binding d-flex">{t("Amount")}:</label>

                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      name="buyAmount"
                      id="buyAmount"
                      aria-invalid="true"
                      onWheel={(e) => e.target.blur()}
                      value={buyAmount}
                      onChange={handleBuyChange}
                    />
                    {/* <span className="unit ng-binding">{firstCoin}</span> */}
                    <span className="text-danger mb-2">
                      {t(stopErrors.amountError)}
                    </span>
                  </div>
                </div>

                <div className="row  pt-3  mb-3 align-items-end">
                  <div className="col-5">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input check_box"
                        type="checkbox"
                        checked={isTopActive}
                        id="flexCheckChecked"
                      />
                      <span
                        class="checkmark"
                        onClick={() => setIsTopActive(!isTopActive)}
                      ></span>
                      <label
                        className="form-check-label pl-2"
                        htmlFor="flexCheckChecked"
                        onClick={() => setIsTopActive(!isTopActive)}
                      >
                        Take Profit
                      </label>
                    </div>
                    <input
                      type="text"
                      value={takeProfit}
                      placeholder="0"
                      name="takeProfit"
                      disabled={!isTopActive}
                      onChange={handleBuyChange}
                    />
                  </div>
                  <div
                    className="col-2 d-flex"
                    style={{ justifyContent: "center" }}
                  >
                    <label>Price</label>
                  </div>
                  <div className="col-5">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input check_box"
                        type="checkbox"
                        checked={isStopLossActive}
                      />
                      <span
                        class="checkmark"
                        onClick={() => setIsStopLossActive(!isStopLossActive)}
                      ></span>
                      <label
                        className="form-check-label pl-2"
                        for="flexCheckChecked"
                        onClick={() => setIsStopLossActive(!isStopLossActive)}
                      >
                        Stop Loss
                      </label>
                    </div>
                    <input
                      className=""
                      type="text"
                      placeholder="0"
                      value={stopPrice}
                      onChange={handleBuyChange}
                      name="stopPriceBuy"
                      disabled={!isStopLossActive}
                    />
                  </div>
                </div>

                <div className="field percent f-cb">
                  <div className="d-flex field4 mb-2">
                    <span
                      className={`col ${bPBtn === 25 ? "my_color_buy" : ""}`}
                      onClick={() => handleBuyPercentMargin(25)}
                    >
                      25%
                    </span>
                    <span
                      className={`col ${bPBtn === 50 ? "my_color_buy" : ""}`}
                      onClick={() => handleBuyPercentMargin(50)}
                    >
                      50%
                    </span>
                    <span
                      className={`col ${bPBtn === 75 ? "my_color_buy" : ""}`}
                      onClick={() => handleBuyPercentMargin(75)}
                    >
                      75%
                    </span>
                    <span
                      className={`col ${bPBtn === 100 ? "my_color_buy" : ""}`}
                      onClick={() => handleBuyPercentMargin(100)}
                    >
                      100%
                    </span>
                  </div>
                </div>
                <div className="field f-cb mb-2">
                  <label className="ng-binding">{t("Total")}: </label>
                  <div className="iptwrap">
                    <input
                      type="text"
                      step="any"
                      id="total_buy"
                      name="buy_order_total"
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                      value={formatDecimal(buy_order_total)}
                    />
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: 10 }} className="ng-binding">
                    {t("Trade Fee")} :{" "}
                    {buy_commission == null
                      ? 0
                      : Number.isInteger(buy_commission)
                      ? buy_commission
                      : removeTrailingZerosT(
                          parseFloat(buy_commission).toFixed(4)
                        )}
                    {buy_commission != null && feesType === "Percentage" && (
                      <span>%</span>
                    )}
                  </p>
                  <p style={{ fontSize: 10 }} className="ng-binding">
                    {t("Net Amount")} :{" "}
                    {feeAmyt ? formatDecimal(feeAmyt, 5) : 0}
                  </p>
                </div>
                <div className="orderforms-inputs">{AuthActionMargin()}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const AuthActionMargin = () => {
    if (token) {
      let marketStatus;
      if (orderMethod === "market") {
        marketStatus = "MKT";
      } else if (orderMethod === "limit") {
        marketStatus = `@ ${buyPrice} LMT`;
      } else {
        marketStatus = `@ ${buyPrice} STP`;
      }
      return (
        <button
          className={
            orderPlace === "buy"
              ? "btn w100  my_color_buy"
              : "btn w100  my_color_sell"
          }
          type="submit"
          disabled={
            isLoading ||
            !buyPrice ||
            !buyAmount ||
            stopErrors.buyError ||
            stopErrors.amountError ||
            stopErrors.limitError ||
            disabledBuuButtn
          }
        >
          {orderPlace === "buy" ? (
            <div>
              <span>{t("Buy")}</span>
              <br />
              <small>
                {(orderMethod === "market" || orderMethod === "stop-loss") &&
                  buyAmount}{" "}
                {firstCoin + secondCoin} {marketStatus}
              </small>
            </div>
          ) : (
            <div>
              <span>{t("Sell")}</span>
              <br />
              <small>
                {(orderMethod === "market" || orderMethod === "stop-loss") &&
                  sellAmount}{" "}
                {firstCoin + secondCoin} {marketStatus}
              </small>
            </div>
          )}
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border2 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login">{t("Login")}</Link>
          </div>
        </div>
      );
    }
  };

  const formatNumber = (number) => {
    return number
      .toLocaleString("fullwide", {
        useGrouping: false,
        maximumFractionDigits: 20,
      })
      .replace(/\.?0+$/, "");
  };

  const dataOnClick = (paramFirstCoin, paramSecondCoin, isBinance) => {
    navigate(`/crypto/${paramFirstCoin}/${paramSecondCoin}`);
    const disconnectPair = firstCoin + secondCoin;
    const connectPair = paramFirstCoin + paramSecondCoin;
    setOrderMethod("market");
    if (isBinance) {
      setExchange(isBinance);
    } else {
      setExchange("");
      // if (krakenWebsocket.current) {
      //   krakenWebsocket.current.close();
      // }
    }
    if (firstCoin !== paramFirstCoin) {
      const pairData = {
        firstCoin: paramFirstCoin,
        secondCoin: paramSecondCoin,
      };
      localSocket.current.emit("ping");
      localSocket.current.emit("topBarData", pairData); // Emit an event to request top bar data for the pair
      localSocket.current.emit("checkPairBinance", pairData); // Emit an event to request top bar data for the pair
      localSocket.current.emit("userBalanceByPair", pairData);
      localSocket.current.emit("userPendingOrders", pairData);
      localSocket.current.emit("getSpreadPercentage", pairData);
      localSocket.current.emit("getOpenPosition");
      localSocket.current.emit("unsubscribe", disconnectPair);
      localSocket.current.emit("subscribe", connectPair);
      handleSocketMessages(localSocket, paramFirstCoin, paramSecondCoin);
    }
    setBuyAmount("");
    setSellAmount("");
    setSell_order_total("");
    setBuy_order_total("");
    setFeeAmyt("");
    setSellFeeAmyt("");
    localStorage.setItem("tab", setActiveTab);
  };

  useEffect(() => {
    const savedFavList = JSON.parse(localStorage.getItem("favList"));
    if (savedFavList) {
      setFavList(savedFavList);
    }
  }, []);

  useEffect(() => {
    if (favList.length > 0) {
      localStorage.setItem("favList", JSON.stringify(favList));
    }
  }, [favList]);

  const handleFav = (pair) => {
    if (favList.some((item) => item.id === pair.id)) {
      setFavList((prevFavList) =>
        prevFavList.filter((item) => item.id !== pair.id)
      );
    } else {
      setFavList((prevFavList) => [...prevFavList, pair]);
    }
  };

  const isInWatchList = (pairId) => {
    return favList.some((item) => item.id === pairId);
  };

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedItem = coinPairData[dragIndex];
      const newData = update(coinPairData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggedItem],
        ],
      });
      setCoinPairData(newData);
      const onlyIds = newData.map((item) => item.id);
      localStorage.setItem("savedOrder", JSON.stringify(onlyIds));
    },
    [coinPairData]
  );

  const mergeDataWithOrder = (newData, savedOrder) => {
    // Create a map for quick lookup
    const orderMap = new Map();
    savedOrder.forEach((id, index) => {
      orderMap.set(id, index);
    });

    // Sort new data based on saved order
    newData.sort((a, b) => {
      const orderA = orderMap.get(a.id) ?? newData.length;
      const orderB = orderMap.get(b.id) ?? newData.length;
      return orderA - orderB;
    });

    return newData;
  };

  const coinPairList = () => {
    const filteredItems = coinPairData.filter(
      (item) =>
        item.first_coin_name &&
        item.first_coin_name.includes(searchedValue.toUpperCase())
    );
    return filteredItems.map((item, index) => {
      const showActive =
        firstCoin === item.first_coin_name &&
        secondCoin === item.second_coin_name;
      var percentShow =
        ((parseFloat(item.current_price) -
          parseFloat(item.oneday_before_price)) *
          100) /
        item.oneday_before_price;
      percentShow = percentShow.toFixed(2);

      if (
        item.second_coin_name == setActiveTab ||
        (setActiveTab === "fav" && isInWatchList(item.id))
      ) {
        const hell = (
          <>
            <td className="r-market-pair ng-binding fw600">
              {isInWatchList(item.id) ? (
                <i
                  style={{ color: "#00bc8b" }}
                  onClick={() => handleFav(item)}
                  className="bi bi-star-fill"
                ></i>
              ) : (
                <i className="bi bi-star" onClick={() => handleFav(item)}></i>
              )}
            </td>
            <td className="r-market-pair ng-binding fw600">
              <img
                className="currency_icon"
                src={imageUrl + "/static/currencyImage/" + item.icon}
                alt=""
              />
              {item.first_coin_name}
              <small>/{item.second_coin_name}</small>
            </td>
            <td className="r-market-price ng-binding fw600">
              {formatDecimal(item.current_price)}
            </td>
            <td className="r-market-price ng-binding fw600">
              {formatDecimal(item.best_bid)}
            </td>
            <td className="r-market-price ng-binding fw600">
              {formatDecimal(item.best_ask)}
            </td>
            <td className="r-market-rate ng-scope tr">
              <span
                className="ng-binding ng-scope green"
                style={
                  percentShow < 0 ? { color: "#ff4545" } : { color: "#00bc8b" }
                }
              >
                {isNaN(percentShow) ? "0.00" : percentShow}%
              </span>
            </td>
          </>
        );

        return (
          <>
            <DraggableItem
              key={item.id} // Ensure each item has a unique key
              index={index}
              id={item.id}
              text={hell}
              moveItem={moveItem}
              active={showActive}
              onClick={() =>
                dataOnClick(
                  item.first_coin_name,
                  item.second_coin_name,
                  item.isBinance
                )
              }
            />
          </>
        );
      }
    });
  };

  const modifyOrder = async () => {
    const orderObj = {
      orderId: selectedRowData.id,
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      stopPrice: stopPrice ? stopPrice : null,
      takeProfit: takeProfit ? takeProfit : null,
    };
    // return;
    const resp = await modifyOrderClick(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
      handleClose();
      // setStopPrice("");
      setErrors(defaultError);
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const handlePriceChangeChangeBinance = (value) => {
    setBuyPrice(parseFloat(value.replace(/,/g, "")));
    setSellPrice(parseFloat(value.replace(/,/g, "")));
  };

  const handlePriceChangeChange = (value) => {
    if (buy_commission !== null) {
      var commission = (buyAmount * buy_commission) / 100;
      var buy_value = parseFloat(buyAmount) + parseFloat(commission);
    } else {
      buy_value = buyAmount;
    }
    if (buyAmount > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(value);
      var price = value * buyAmount;
      setBuy_order_total(price);
      setFeeAmyt(buyOrderTotalWithFee);
    }
    if (sellAmount > 0) {
      let commission = sellAmount * (100 / (100 + sell_commission));
      let sell_value = commission;
      let sellOrderTotalWithFee = parseFloat(sell_value) * parseFloat(value);
      var sellPriceNew = value * sellAmount;
      setSell_order_total(sellPriceNew);
      setSellFeeAmyt(sellOrderTotalWithFee);
    }
    setBuyPrice(value);
    setSellPrice(value);
  };

  const activeBuyOrderHtml = () => {
    if (binanceDepth && binanceDepth.bids && binanceDepth.bids.length > 0) {
      const calculateProgressBarWidth = (quantity, maxQuantity) => {
        const percentage = (quantity / maxQuantity) * 100;
        return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
      };
      const maxQuantity = binanceDepth.bids[0][1];
      const tableRows = binanceDepth.bids
        .slice(0, visibleRows)
        .map((activeBuyOrderSingle, index) => {
          const tradePrice = parseFloat(activeBuyOrderSingle[0]);
          const decimalPlaces = tradePrice < 1 ? 8 : 3;
          const formattedPrice = tradePrice.toLocaleString("en-US", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          });
          const progressBarWidth = calculateProgressBarWidth(
            activeBuyOrderSingle[1],
            maxQuantity
          );

          const number1 = new BigNumber(activeBuyOrderSingle[0]);
          const number2 = new BigNumber(activeBuyOrderSingle[1]);
          const product = number1.multipliedBy(number2);

          return (
            <tr
              className="ng-scope tr_prograss_depth"
              key={index}
              onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding green crypt-up">
                <span
                  className="tr_prograss2"
                  style={{ width: `${progressBarWidth}` }}
                ></span>
                {formattedPrice}
              </td>
              <td className="f-left ng-binding ">
                {formatNumber(activeBuyOrderSingle[1])}
              </td>
              <td className="f-left ng-binding text-right">
                {activeBuyOrderSingle && formatNumberIso(product)}
              </td>
            </tr>
          );
        });

      return <tbody>{tableRows}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const sideLastHtml = () => {
    if (topBarStatus !== false) {
      if (exchange === "binance" || exchange === "kraken") {
        const tradePrice = parseFloat(livPrice);

        const decimalPlaces = tradePrice < 1 ? 8 : 2; // Determine the number of decimal places based on the value of the number
        const formattedPrice = tradePrice.toLocaleString("en-US", {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
        return (
          <ul>
            <li>
              <strong className="ng-binding mm">{formattedPrice}</strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      } else {
        var topBarData = topBarApiData;
        var collectHtml = (
          <ul>
            <li>
              <strong className="ng-binding mm">
                {secondCoin === "INR" ? (
                  <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
                ) : (
                  <span></span>
                )}
                {topBarData.currentPrice}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      }

      return <ul>{collectHtml}</ul>;
    } else {
      collectHtml = (
        <ul>
          <li>
            <strong className="ng-binding">
              {secondCoin === "INR" ? (
                <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {0.0}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
        </ul>
      );
      return <ul>{collectHtml}</ul>;
    }
  };

  const formatNumberIso = (number) => {
    return number.toNumber();
  };

  const activeSellOrderHtml = () => {
    if (exchange === "binance") {
      if (binanceDepth && binanceDepth.asks && binanceDepth.asks.length > 0) {
        const calculateProgressBarWidth = (quantity, maxQuantity) => {
          const percentage = (quantity / maxQuantity) * 100;
          return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
        };

        const spreadAdjustmentFlat = spreadPercentage ? spreadPercentage : 0; // No flat adjustment if it's percentage based

        const sortedAsks = binanceDepth.asks.sort((a, b) => b[0] - a[0]);
        let asksToShow = sortedAsks.slice(0, visibleRows);
        const maxQuantity = binanceDepth.asks[0][1];
        const tableRows = asksToShow.map((activeSellOrderSingle, index) => {
          const tradePrice =
            parseFloat(activeSellOrderSingle[0]) +
            parseFloat(spreadAdjustmentFlat);
          const decimalPlaces = tradePrice < 1 ? 8 : 3;
          const formattedPrice = tradePrice.toLocaleString("en-US", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          });
          const progressBarStyle = {
            width: calculateProgressBarWidth(
              activeSellOrderSingle[1],
              maxQuantity
            ),
          };
          return (
            <tr
              className="ng-scope tr_prograss_depth"
              key={index}
              onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding magenta crypt-down">
                <span className="tr_prograss" style={progressBarStyle}></span>
                {formattedPrice}
              </td>
              <td className="f-left ng-binding ">
                {formatNumber(activeSellOrderSingle[1])}
              </td>
              <td className="f-left ng-binding text-right">
                {activeSellOrderSingle &&
                  formatNumber(
                    activeSellOrderSingle[0] * activeSellOrderSingle[1]
                  )}
              </td>
            </tr>
          );
        });

        return <tbody>{tableRows}</tbody>;
      }
    } else if (exchange === "kraken") {
      if (binanceDepth && binanceDepth.asks && binanceDepth.asks.length > 0) {
        const calculateProgressBarWidth = (quantity, maxQuantity) => {
          const percentage = (quantity / maxQuantity) * 100;
          return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
        };
        const spreadAdjustmentFactor =
          spreadRateType === "percentage"
            ? spreadType === "up"
              ? 1 + spreadPercentage / 100
              : 1 - spreadPercentage / 100
            : 1; // No factor needed for flat spread

        const spreadAdjustmentFlat =
          spreadRateType === "flat"
            ? spreadType === "up"
              ? spreadPercentage
              : -spreadPercentage
            : 0; // No flat adjustment if it's percentage based

        const sortedAsks = binanceDepth.asks.sort(
          (a, b) => parseFloat(b[0]) - parseFloat(a[0])
        );
        let startIndex = Math.max(0, sortedAsks.length - 7);
        let asksToShow = sortedAsks.slice(startIndex);

        const maxQuantity = binanceDepth.asks[0][1];
        const tableRows = asksToShow.map((activeSellOrderSingle, index) => {
          const tradePrice =
            spreadRateType === "percentage"
              ? parseFloat(activeSellOrderSingle[0] * spreadAdjustmentFactor)
              : parseFloat(activeSellOrderSingle[0]) +
                parseFloat(spreadAdjustmentFlat);
          const decimalPlaces = tradePrice < 1 ? 8 : 3;
          const formattedPrice = tradePrice.toLocaleString("en-US", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          });
          const progressBarStyle = {
            width: calculateProgressBarWidth(
              activeSellOrderSingle[1],
              maxQuantity
            ),
          };
          const number1 = new BigNumber(activeSellOrderSingle[0]);
          const number2 = new BigNumber(activeSellOrderSingle[1]);
          const product = number1.multipliedBy(number2);
          return (
            <tr
              className="ng-scope tr_prograss_depth"
              key={index}
              onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding magenta crypt-down">
                <span className="tr_prograss" style={progressBarStyle}></span>
                {formattedPrice}
              </td>
              <td className="f-left ng-binding ">
                {formatNumber(activeSellOrderSingle[1])}
              </td>
              <td className="f-left ng-binding text-right">
                {activeSellOrderSingle && formatNumberIso(product)}
              </td>
            </tr>
          );
        });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const AuthAction = () => {
    if (token) {
      return (
        <button
          className="btn w100  my_color_buy"
          type="submit"
          disabled={
            isLoading ||
            !buyPrice ||
            !buyAmount ||
            stopErrors.buyError ||
            stopErrors.amountError ||
            stopErrors.limitError ||
            disabledBuuButtn
          }
        >
          {t("Buy")}
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div
            className="btn w100  my_color_buy"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Link to="/register" style={{ fontWeight: 700 }}>
              {t("Sign Up")}
            </Link>{" "}
            or{" "}
            <Link to="/login" style={{ fontWeight: 700 }}>
              {t("Login")}
            </Link>
          </div>
        </div>
      );
    }
  };

  const AuthActionSell = () => {
    if (token) {
      return (
        <button
          className="btn w100  my_color_sell"
          disabled={
            isLoading ||
            !sellPrice ||
            !sellAmount ||
            stopErrors.sellError ||
            stopErrors.amountSellError ||
            stopErrors.limitSellError ||
            disabledBuuButtn
          }
        >
          {t("Sell")}
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div
            className="btn w100  my_color_buy"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Link to="/register" style={{ fontWeight: 700 }}>
              Sign Up
            </Link>{" "}
            or{" "}
            <Link to="/login" style={{ fontWeight: 700 }}>
              {t("Login")}
            </Link>
          </div>
        </div>
      );
    }
  };

  const marketTradeHtml = () => {
    if (exchange === "binance" || exchange === "kraken") {
      if (binanceTrades) {
        return (
          <tbody>
            <MarketTrade
              trades={binanceTrades}
              record={[]}
              currentPair={firstCoin + "/" + secondCoin}
              spreadPercentage={spreadPercentage}
              spreadType={spreadType}
            />
          </tbody>
        );
      }
    } else {
      if (marketTrade) {
        var collectHtml = [];
        for (var i = 0; i < marketTradeData.length; i++) {
          var singleData = marketTradeData[i];
          var setColor = singleData.extype === "buy" ? "green" : "magenta";
          var getTime = Moment(singleData.created_at).format("lll");
          collectHtml.push(
            <tr className="ng-scope">
              <td className={"f-left ng-binding " + setColor}>
                {singleData.get_per_price}
              </td>
              <td className="f-left ng-binding">{singleData.get_amount}</td>
              <td className="f-left ng-binding">
                {singleData.firstCoin + "/" + singleData.secondCoin}
              </td>
              <td className="f-left ng-binding">{getTime}</td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                {t("No data avaliable")}
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const topBarHtml = () => {
    var collectHtml;
    if (topBarStatus !== false) {
      const balance = new BigNumber(userBalance);
      const profit = new BigNumber(floatingProfits);

      // Equity is the sum of the account balance and the floating profits
      const equityPrice = balance.plus(profit);
      return (
        <UserTicker
          translate={t}
          balance={userBalance}
          equityPrice={equityPrice}
          leverage={userLeverage}
          usedMargin={margin}
          freeMargin={freeMargin}
          profit={accountPnL}
        />
      );
    } else {
      collectHtml = (
        <ul>
          <li>
            <h4 className="ng-binding">{t("Balance")}</h4>
            <strong className="ng-binding">${0.0}</strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
          <li>
            <h4 className="ng-binding">{t("Equity")}</h4>
            <strong className="changeRate ng-binding ng-scope green">
              $0.00
            </strong>
          </li>
          <li>
            <h4 className="ng-binding">{t("Leverage")}</h4>
            <strong className="ng-binding">1:1</strong>
          </li>
          <li>
            <h4 className="ng-binding">{t("Used Margin")}</h4>
            <strong className="ng-binding">$0.00</strong>
          </li>
          <li>
            <h4 className="ng-binding">{t("Available Margin")}</h4>
            <strong className="ng-binding">$0.00</strong>
          </li>
          <li>
            <h4 className="ng-binding">{t("Profit")}</h4>
            <strong className="ng-binding">$0.00</strong>
          </li>
        </ul>
      );
      return collectHtml;
    }
  };

  const activeCurrency = (value) => {
    setSetUrl(value);
    setBuyAmount("");
    setSellAmount("");
    setSell_order_total("");
    setBuy_order_total("");
    setSetActiveTab(value);
    setSearchedValue("");
    localStorage.setItem("tab", value);
  };

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setActiveSegment(tab);
  };

  const handleTabClickTab = (e, tab) => {
    e.preventDefault();
    setBuyTabs(tab);
  };

  // const handleTabClickSell = (e, tab) => {
  //   e.preventDefault();
  //   setSellTabs(tab);
  // };

  const deleteOrder = async (orderId, orderType) => {
    const submitData = {
      order_type: orderType,
      order_id: orderId,
    };

    setUpdateAndDeleteButton(true);
    setTimeout(() => {
      setUpdateAndDeleteButton(false);
    }, 500);

    const res = await deleteOrderApi(submitData);
    if (res) {
      const resp = res.data;
      if (resp.success) {
        toast.success(t(resp.message));
      } else {
        toast.error(t(resp.message));
      }
    }
  };

  function trimTrailingZeros(value) {
    return parseFloat(value.toFixed(8)).toString();
  }

  const handleRightClick = (event, data) => {
    event.preventDefault();
    setCurrentRightClickData(data);
    setMenuPosition({ x: event.clientX - 350, y: event.clientY - 100 });
    setShowMenu(true);
  };

  const handleAction = (action) => {
    if (action === "cancel") {
      deleteOrder(currentRightClickData.id, currentRightClickData.type);
    }

    setShowMenu(false);
  };

  const myActiveBuyOrderHtml = () => {
    if (myActiveBuyOrder) {
      var collectHtml = [];

      myActiveBuyOrderData.forEach((e, i) => {
        const parsedDate = Moment(e.created_at);
        var getTime = parsedDate.format("MMM D, YYYY h:mm:ss A");
        collectHtml.push(
          <tr
            className="ng-scope"
            key={`buy-${e.buy_id}`}
            onContextMenu={(ev) =>
              handleRightClick(ev, {
                id: e.orderSide === "buy" ? e.buy_id : e.sell_id,
                type: e.orderSide,
              })
            }
          >
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">{t(e.orderSide)}</td>
            <td className="f-left ng-binding green">
              {trimTrailingZeros(e.per_price)}
            </td>
            <td className="f-left ng-binding green">
              {e.stop_price ? trimTrailingZeros(e.stop_price) : "-"}
            </td>
            <td className="f-left ng-binding">{trimTrailingZeros(e.amount)}</td>
            <td className="f-left ng-binding">
              {trimTrailingZeros(e.per_price * e.amount)}
            </td>
            <td className="f-left ng-binding green">{t(e.orderMethod)}</td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              <button
                className="btn"
                style={{ backgroundColor: "#ee3e4f", borderColor: "#ee3e4f" }}
                disabled={updateAndDeleteButton}
                onClick={() =>
                  deleteOrder(
                    e.orderSide === "buy" ? e.buy_id : e.sell_id,
                    e.orderSide
                  )
                }
              >
                <i className="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        );
      });

      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              {t("You have no pending orders")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myActiveSellOrderHtml = () => {
    if (myActiveSellOrder) {
      var collectHtml = [];

      myActiveSellOrderData.forEach((e, i) => {
        const parsedDate = Moment(e.created_at);
        var getTime = parsedDate.format("MMM D, YYYY h:mm:ss A");
        collectHtml.push(
          <tr
            className="ng-scope"
            key={`sell-${e.sell_id}`}
            onContextMenu={(ev) =>
              handleRightClick(ev, {
                id: e.orderSide === "buy" ? e.buy_id : e.sell_id,
                type: e.orderSide,
              })
            }
          >
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">
              {trimTrailingZeros(e.per_price)}
            </td>
            <td className="f-left ng-binding green">
              {e.stop_price ? trimTrailingZeros(e.stop_price) : "-"}
            </td>
            <td className="f-left ng-binding">
              {trimTrailingZeros(e.sell_spend_amount)}
            </td>
            <td className="f-left ng-binding">
              {trimTrailingZeros(e.per_price * e.sell_spend_amount)}
            </td>
            <td className="f-left ng-binding green">{e.orderMethod}</td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              <button
                className="btn"
                style={{ backgroundColor: "#ee3e4f", borderColor: "#ee3e4f" }}
                disabled={updateAndDeleteButton}
                onClick={() =>
                  deleteOrder(
                    e.orderSide === "buy" ? e.buy_id : e.sell_id,
                    e.orderSide
                  )
                }
              >
                <i className="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        );
      });

      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedBuyOrderHtml = () => {
    if (myCompletedBuyOrder) {
      var collectHtml = [];
      for (var i = 0; i < 50; i++) {
        const myCompletedBuyOrderSingle = myCompletedBuyOrderData[i];
        if (myCompletedBuyOrderSingle) {
          var getTime = Moment(myCompletedBuyOrderSingle.created_at).format(
            "MMM D, YYYY h:mm:ss A"
          );
          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {t(myCompletedBuyOrderSingle.orderSide)}
              </td>
              <td className="f-left ng-binding green">
                {trimTrailingZeros(myCompletedBuyOrderSingle.per_price)}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.stop_price
                  ? trimTrailingZeros(myCompletedBuyOrderSingle.stop_price)
                  : "-"}
              </td>
              <td className="f-left ng-binding">
                {trimTrailingZeros(myCompletedBuyOrderSingle.amount)}
              </td>
              <td className="f-left ng-binding">
                {trimTrailingZeros(
                  myCompletedBuyOrderSingle.per_price *
                    myCompletedBuyOrderSingle.amount
                )}
              </td>
              <td>{t(myCompletedBuyOrderSingle.orderMethod)}</td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={7} style={{ textAlign: "center" }}>
              {t("You have no completed orders")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCancledOrderHtml = () => {
    if (myCancleOrder) {
      var collectHtml = [];
      for (var i = 0; i < 50; i++) {
        const myCompletedBuyOrderSingle = myCancleOrderData[i];
        if (myCompletedBuyOrderSingle) {
          var getTime = Moment(myCompletedBuyOrderSingle.created_at).format(
            "MMM D, YYYY h:mm:ss A"
          );
          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {t(myCompletedBuyOrderSingle.orderSide)}
              </td>
              <td className="f-left ng-binding green">
                {trimTrailingZeros(myCompletedBuyOrderSingle.per_price)}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.stop_price
                  ? trimTrailingZeros(myCompletedBuyOrderSingle.stop_price)
                  : "-"}
              </td>
              <td className="f-left ng-binding">
                {trimTrailingZeros(myCompletedBuyOrderSingle.amount)}
              </td>
              <td className="f-left ng-binding">
                {trimTrailingZeros(
                  myCompletedBuyOrderSingle.per_price *
                    myCompletedBuyOrderSingle.amount
                )}
              </td>
              <td>{t(myCompletedBuyOrderSingle.orderMethod)}</td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={7} style={{ textAlign: "center" }}>
              {t("You have no cancelled orders")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedSellOrderHtml = () => {
    if (myCompletedSellOrder) {
      var collectHtml = [];
      for (var i = 0; i < 50; i++) {
        const myCompletedSellOrderSingle = myCompletedSellOrderData[i];
        if (myCompletedSellOrderSingle) {
          var getTime = Moment(myCompletedSellOrderSingle.created_at).format(
            "MMM D, YYYY h:mm:ss A"
          );

          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {trimTrailingZeros(myCompletedSellOrderSingle.per_price)}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.stop_price
                  ? trimTrailingZeros(myCompletedSellOrderSingle.stop_price)
                  : "-"}
              </td>
              <td className="f-left ng-binding">
                {trimTrailingZeros(
                  myCompletedSellOrderSingle.total_sell_spend_amount
                )}
              </td>
              <td className="f-left ng-binding">
                {trimTrailingZeros(
                  myCompletedSellOrderSingle.per_price *
                    myCompletedSellOrderSingle.total_sell_spend_amount
                )}
              </td>
              <td>{myCompletedSellOrderSingle.orderMethod}</td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={7} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  useEffect(() => {
    const activeTab = localStorage.getItem("tab");
    if (activeTab) {
      setSetActiveTab(activeTab);
      activeCurrency(activeTab);
    }
  }, []);

  const allOrdersTable = () => {
    if (allOrdersData.length > 0) {
      return allOrdersData.map((item, index) => {
        let showingPrice;
        if (item.status === "pending") {
          showingPrice = item.currentPrice;
        } else if (item.status === "close") {
          showingPrice = item.closed_price;
        } else {
          showingPrice = "-";
        }
        let allStatus;
        let colour;
        if (item.status === "close") {
          allStatus = "Filled";
          colour = "#01d59b";
        } else if (item.status === "pending") {
          allStatus = "Working";
          colour = "#2962ff";
        } else if (item.status === "cancled") {
          allStatus = "Cancelled";
          colour = "#ff9800";
        }
        return (
          <tr key={index}>
            <td>{item.firstCoin + "/" + item.secondCoin}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.order_type}</td>
            <td>{item.quantity}</td>
            <td> {item.price}</td>
            <td> {showingPrice} </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td style={{ color: colour }}>
              {item.status ? t(allStatus) : "-"}
            </td>
            <td>{item.id ? item.id : "-"}</td>
            {item.status === "pending" && (
              <td>
                <span
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                  onClick={() => handleCancleOrder(item)}
                >
                  <i className="bi bi-x-circle"></i>
                </span>
              </td>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={10}>
            {t("There is no trading data yet")}
          </td>
        </tr>
      );
    }
  };

  const notificationTable = () => {
    if (notificationData.length > 0) {
      return notificationData.map((item, index) => {
        return (
          <tr key={index}>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>{item.title}</td>
            <td>{item.message}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={3}>
            {t("You don't have any notifications!")}
          </td>
        </tr>
      );
    }
  };

  const handleChangePositions = (activeTab) => {
    setActiveSide(activeTab);
  };

  const handleClose = () => {
    setShow(false);
    setStopPrice("");
    setTakeProfit("");
    setSelectedRowData(null);
    handleSocketMessages(localSocket);
  };

  const titleData = !currentPrice
    ? "0" +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      firstCoin +
      secondCoin +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`
    : currentPrice > 1
    ? currentPrice.toLocaleString() +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      firstCoin +
      secondCoin +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`
    : currentPrice +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      firstCoin +
      secondCoin +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`;

  const meta = {
    title: titleData.toString(),
    description: `${ProductName}  Exchange Live Is A Crypto Trading Platform That Exchanges Cryptocurrencies Like Bitcoin, Dogecoin, TRX, XRP, MRC etc. Sign Up For A New Account.`,
    canonical: `https://${ProductName} Exchange.com/high-limit-crypto-exchange`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Bitcoin Exchange, Crypto Buying Selling, Crypto Platform, Cryptocurrencies App ",
      },
    },
  };

  const handleLayoutChange = (layout, allLayouts) => {
    setLayout(allLayouts);
    saveToLocalStorage("cryptoLayouts", allLayouts);
  };

  const resetLayout = (type) => {
    if (type === "crypto") {
      setLayout(layouts);
      setRemovedItems(notAllowedWidgets);
      saveToLocalStorage("cryptoLayouts", layouts);
      const removeItemsLocal = getFromLocalStorage("rl");
      if (removeItemsLocal) {
        setRemovedItems(removeItemsLocal);
      }

      saveToLocalStorage("rl", removeItemsLocal);
    }
  };

  const changeChartTheme = (theme) => {
    if (theme === "defaultTheme" || theme === "darkTheme") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const removeItem = (itemId) => {
    let i = 0;
    for (const key in layout) {
      if (layout.hasOwnProperty(key)) {
        layout[key] = layout[key].filter((item) => item.i !== itemId);
        i = i + 50;
      }
    }

    setLayout(layout);
    saveToLocalStorage("cryptoLayouts", layout);

    setRemovedItems((prevRemovedItems) => [...prevRemovedItems, itemId]);
    setTimeout(() => {
      handleLayoutChange(undefined, layout);
    }, 100 + i);
  };

  const addItem = (newItem) => {
    const updatedLayout = { ...layout };

    let chartData = null;

    for (const key in layouts) {
      if (layouts.hasOwnProperty(key)) {
        const chartItem = layouts[key].find((item) => item.i === newItem);

        if (chartItem) {
          chartData = chartItem;
          break;
        }
      }
    }
    if (!chartData) return;

    const chartIndex = updatedLayout.lg.findIndex((item) => item.i === newItem);

    // chartData.y = Infinity;

    if (chartIndex !== -1) {
      updatedLayout.lg[chartIndex] = chartData;
      updatedLayout.md[chartIndex] = chartData;
      updatedLayout.sm[chartIndex] = chartData;
      updatedLayout.xs[chartIndex] = chartData;
    } else {
      updatedLayout.lg.push(chartData);
      updatedLayout.md.push(chartData);
      updatedLayout.sm.push(chartData);
      updatedLayout.xs.push(chartData);
    }

    setLayout(updatedLayout);

    saveToLocalStorage("cryptoLayouts", updatedLayout);

    setRemovedItems((prevRemovedItems) =>
      prevRemovedItems.filter((itemId) => itemId !== newItem)
    );

    saveToLocalStorage(
      "rl",
      removedItems.filter((itemId) => itemId !== newItem)
    );

    setTimeout(() => {
      handleLayoutChange(undefined, updatedLayout);
    }, 300);
  };

  function checkValueExists(value) {
    return removedItems.includes(value);
  }

  const getLayoutSettings = (section, status, page) => {
    if (!status) {
      removeItem(section);
    } else {
      addItem(section);
    }
  };

  const handleRemove = (value) => {
    value === "stopPrice" ? setStopPrice("") : setTakeProfit("");
  };

  useEffect(() => {
    for (const item of notAllowedWidgets) {
      removeItem(item);
    }
  }, [notAllowedWidgets]);

  return (
    <div>
      <DocumentMeta {...meta}>
        <ExchangeNavBar
          resetLayout={resetLayout}
          changeChartTheme={changeChartTheme}
          getLayoutSettings={getLayoutSettings}
          layoutChanged={removedItems}
          refresh={refresh}
          notAllowedWidgets={notAllowedWidgets}
        />
        <div className="dashbord_manbox exchange-main-outer">
          <div className="container-fluid p-0">
            <div className="dashbord_in dashbord_in_top fs13">
              <ResponsiveGridLayout
                className="layout"
                layouts={layout}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4 }}
                rowHeight={30}
                margin={[1, 1]}
                onLayoutChange={handleLayoutChange}
                resizeHandles={["se", "sw", "ne", "nw"]}
                // autoSize={true}
                draggableHandle=".drag-handle"
                draggableCancel=".drag-cancel"
              >
                <div key="topbar">
                  <div className="bg2 kline-para kline-para-basic d-flex align-items-center">
                    <div className="box-kline-para-basic-left ">
                      <span
                        className="productSymbol has-info ng-scope"
                        style={{ fontSize: "11px" }}
                      >
                        <strong className="ng-binding">{firstCoin}</strong>
                        <font className="ng-binding"> / {secondCoin}</font>
                      </span>
                    </div>
                    <div className="box-kline-para-basic-right">
                      {topBarHtml()}
                    </div>
                  </div>
                </div>
                {!checkValueExists("pairslist") && (
                  <div
                    key="pairslist"
                    className="dashbord_in dashbord_in_top fs13 text-left"
                  >
                    <div className="ne_box p-3 br10  bg2 h_100 ">
                      <div className="panel-heading tab-box">
                        <h6 className="text-left drag-handle">
                          {t("Crypto Pairs")}
                        </h6>
                        <div
                          className="close-resize"
                          onClick={() => removeItem("pairslist")}
                        >
                          <i class="bi bi-x-lg"></i>
                        </div>
                        <ul className="exchange_tab ">
                          <li
                            className={
                              setUrl === "fav"
                                ? "active tabQuoteAsset"
                                : "tabQuoteAsset"
                            }
                            onClick={() => activeCurrency("fav")}
                          >
                            <i class="bi bi-star-fill"></i>
                          </li>
                          {exchange_tab.map((list) => {
                            return (
                              <li
                                className={
                                  setUrl === list.symbol
                                    ? "active tabQuoteAsset"
                                    : "tabQuoteAsset"
                                }
                                onClick={() => activeCurrency(list.symbol)}
                              >
                                {list.symbol}
                              </li>
                            );
                          })}
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                      <div className="panel-body p-0">
                        <div className="searchFilter f-cb">
                          <div className="search-box search-box-new">
                            <input
                              type="text"
                              id="search-int"
                              placeholder={t("")}
                              className="ng-pristine ng-untouched ng-valid ng-empty"
                              onChange={onSearch}
                              value={searchedValue}
                              autoComplete="off"
                            />
                            {searchedValue !== "" ? (
                              <i
                                onClick={EmptySearch}
                                className="las la-times-circle"
                              ></i>
                            ) : (
                              <i className="las la-search"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <table className="table mb-0 currency_table1">
                        <thead>
                          <tr>
                            <th
                              className="f-left r-market-pair ng-binding"
                              ng-click="sortByKey('baseAsset')"
                            ></th>
                            <th
                              className="f-left r-market-pair ng-binding"
                              ng-click="sortByKey('baseAsset')"
                            >
                              {t("Pair")}
                            </th>
                            <th className="f-left r-market-price ng-binding">
                              {t("Price")}
                              <span className="ng-scope"></span>
                            </th>
                            <th
                              className="r-market-rate ng-binding ng-scope tr"
                              style={{ cursor: "pointer" }}
                            >
                              {t("Change")}
                              <span className="ng-scope">
                                <i className="fa fa-caret-down"></i>
                              </span>
                            </th>
                          </tr>
                        </thead>
                      </table> */}

                      <div className="table-overflow">
                        {/* <Dnd /> */}
                        <DndProvider backend={HTML5Backend}>
                          <table className="table table-hover currency_table">
                            <thead>
                              <tr>
                                <th
                                  className="f-left r-market-pair ng-binding"
                                  ng-click="sortByKey('baseAsset')"
                                ></th>
                                <th
                                  className="f-left r-market-pair ng-binding"
                                  ng-click="sortByKey('baseAsset')"
                                >
                                  {t("Pair")}
                                </th>
                                <th className="f-left r-market-price ng-binding">
                                  {t("Price")}
                                  <span className="ng-scope"></span>
                                </th>
                                <th className="f-left r-market-price ng-binding">
                                  {t("Best BID")}
                                  <span className="ng-scope"></span>
                                </th>
                                <th className="f-left r-market-price ng-binding">
                                  {t("Best ASK")}
                                  <span className="ng-scope"></span>
                                </th>
                                <th
                                  className="r-market-rate ng-binding ng-scope tr"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("Change")}
                                  <span className="ng-scope">
                                    <i className="fa fa-caret-down"></i>
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            {coinPairList()}
                          </table>
                        </DndProvider>
                        {/* <table className="table table-hover currency_table">
                          {coinPairList()}
                        </table> */}
                      </div>
                    </div>
                  </div>
                )}
                {!checkValueExists("trades") && (
                  <div key="trades">
                    <div className="ne_box  p-3 br10  bg2 h-100 text-left">
                      <h6 className="h6_head drag-handle">
                        {t("Market Trades")}
                      </h6>
                      <div
                        className="close-resize"
                        onClick={() => removeItem("trades")}
                      >
                        <i class="bi bi-x-lg"></i>
                      </div>
                      <div className="buy_sell_order">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th>{t("Price")}</th>
                              <th>{t("Quantity")}</th>
                              <th>{t("Pair")}</th>
                              <th>{t("Date & Time")}</th>
                            </tr>
                          </thead>
                          {marketTradeHtml()}
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {!checkValueExists("chart") && (
                  <div key="chart">
                    <div className="ne_box  br10  bg2 h387 drag-handle">
                      <div className="binance-chart-container">
                        <div
                          className="close-resize drag-cancel"
                          onClick={() => removeItem("chart")}
                        >
                          <i class="bi bi-x-lg"></i>
                        </div>

                        <div className="h-100 p-2 ">
                          <TradingViewCrypto theme={theme} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!checkValueExists("buy") && (
                  <div key={"buy"}>
                    <div
                      className="close-resize"
                      onClick={() => removeItem("buy")}
                    >
                      <i className="bi bi-x-lg"></i>
                    </div>
                    {marketSegment === "spot"
                      ? orderPlace === "buy"
                        ? buyBoxHtml()
                        : sellBoxHtml()
                      : marginBoxHtml()}
                  </div>
                )}

                {!checkValueExists("marketdepth") && (
                  <div key="marketdepth">
                    {" "}
                    <div
                      className="ne_box  p-3 br10 text-left bg2 depth-m h100"
                      ref={containerRef}
                    >
                      <h6 className="text-left drag-handle">
                        {t("Market Depth")}
                      </h6>
                      <div
                        className="close-resize"
                        onClick={() => removeItem("marketdepth")}
                      >
                        <i class="bi bi-x-lg"></i>
                      </div>
                      <div className="market_dapth_scrool">
                        <div className=" bs_border ">
                          <table className="table mb-0 ">
                            <thead>
                              <tr>
                                <th>{t("Price")}</th>
                                <th>{t("Qty.")}</th>
                                <th className="text-right">
                                  {t("Total")}({secondCoin})
                                </th>
                              </tr>
                            </thead>
                            {activeSellOrderHtml()}
                          </table>
                        </div>
                        <h6 className="text-center t2t m-0 ">
                          {sideLastHtml()}
                        </h6>
                        <div className="bs_border ">
                          <table className="table mb-0 ">
                            <thead>
                              <tr>
                                <th>{t("Price")}</th>
                                <th>{t("Qty.")}</th>
                                <th className="text-right">
                                  {t("Total")}({secondCoin})
                                </th>
                              </tr>
                            </thead>
                            {activeBuyOrderHtml()}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {!checkValueExists("positions") && (
                  <div key="positions">
                    <div className="ne_box  p-3 br10  bg2 h-100 text-left">
                      <h6 className="h6_head drag-handle">{t("Wallet")}</h6>
                      <div
                        className="close-resize"
                        onClick={() => removeItem("positions")}
                      >
                        <i class="bi bi-x-lg"></i>
                      </div>
                      <div className="buy_sell_order">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th>{t("Assets")}</th>
                              <th>{t("Amount")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {openPositions.map((item) => {
                              return (
                                <tr className="ng-scope">
                                  <td className="f-left ng-binding">
                                    {item.symbol}
                                  </td>
                                  <td className="f-left ng-binding">
                                    {item.amount}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )} */}
                {!checkValueExists("orderdetails") && (
                  <div key={"orderdetails"}>
                    <div className="fs13 h_100">
                      <div className="ne_box h_100 p-3 br10  bg2">
                        <div className="table_hadding flex-wrap mb-2">
                          <h6 className="h6_head drag-handle">{t("Orders")}</h6>
                          <div
                            className="close-resize"
                            onClick={() => removeItem("orderdetails")}
                          >
                            <i className="bi bi-x-lg"></i>
                          </div>
                          <div className="status-outer">
                            <ul className="nav nav-tabs2 d-flex" role="tablist">
                              {/* <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    activeSegment === "margin" ? "active" : ""
                                  }`}
                                  data-toggle="tab"
                                  href="#pending"
                                  onClick={(e) => handleTabClick(e, "margin")}
                                  role="tab"
                                >
                                  {t("Margin")} {t("Orders")}
                                </a>
                              </li>
                              <li className="nav-item ml-3">
                                <a
                                  className={`nav-link ${
                                    activeSegment === "spot" ? "active" : ""
                                  }`}
                                  onClick={(e) => handleTabClick(e, "spot")}
                                  data-toggle="tab"
                                  href="#complete"
                                  role="tab"
                                >
                                  {t("Spot")} {t("Orders")}
                                </a>
                              </li> */}
                              {/* <li className="nav-item ml-3">
                                <a
                                  className={`nav-link ${
                                    buyTabs === "deleted" ? "active" : ""
                                  }`}
                                  onClick={(e) => handleTabClick(e, "deleted")}
                                  data-toggle="tab"
                                  href="#deleted"
                                  role="tab"
                                >
                                  Canceled
                                </a>
                              </li>
                              <li className="nav-item ml-3">
                                <a
                                  className={`nav-link ${
                                    buyTabs === "positions" ? "active" : ""
                                  }`}
                                  data-toggle="tab"
                                  href="#positions"
                                  onClick={(e) =>
                                    handleTabClick(e, "positions")
                                  }
                                  role="tab"
                                >
                                  Positions
                                </a>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="buy-order-outer tab-content h_100 ">
                          <div
                            className={`tab-pane h_100 ${
                              activeSegment === "spot" ? "active" : ""
                            }`}
                            id="pending"
                            role="tabpanel"
                          >
                            <div className="position_tab d-flex mb-2">
                              <a
                                href="#!"
                                onClick={(e) => handleTabClickTab(e, "pending")}
                                className={
                                  buyTabs === "pending" ? "active" : ""
                                }
                              >
                                {t("Pending")}
                              </a>
                              <a
                                href="#!"
                                onClick={(e) =>
                                  handleTabClickTab(e, "complete")
                                }
                                className={
                                  buyTabs === "complete" ? "active" : ""
                                }
                              >
                                {t("Completed")}
                              </a>
                              <a
                                href="#!"
                                onClick={(e) => handleTabClickTab(e, "deleted")}
                                className={
                                  buyTabs === "deleted" ? "active" : ""
                                }
                              >
                                {t("Cancelled")}
                              </a>
                            </div>
                            <div className="userRecords-main obo_exchange buy_sell_order ">
                              {buyTabs === "pending" ? (
                                <table className="table mb-0 ">
                                  <thead>
                                    <tr>
                                      <th className="f-left ng-binding">
                                        {t("Pair")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Side")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Price")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Stop Price")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Used Margin")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Total")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Order Type")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Date & Time")}
                                      </th>
                                      <th className="f-center cancels">
                                        <span className="ng-binding p-0">
                                          {t("Action")}
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  {myActiveBuyOrderHtml()}
                                  {showMenu && (
                                    <ContextMenu
                                      x={menuPosition.x}
                                      y={menuPosition.y}
                                      onAction={handleAction}
                                    />
                                  )}
                                </table>
                              ) : buyTabs === "complete" ? (
                                <table className="table mb-0">
                                  <thead>
                                    <tr>
                                      <th>{t("Pair")}</th>
                                      <th>{t("Side")}</th>
                                      <th>{t("Price")}</th>
                                      <th>{t("Stop Price")}</th>
                                      <th>{t("Used Margin")}</th>
                                      <th>{t("Total")}</th>
                                      <th>{t("Order Type")}</th>
                                      <th>{t("Date")}</th>
                                    </tr>
                                  </thead>
                                  {myCompletedBuyOrderHtml()}
                                </table>
                              ) : (
                                <table className="table mb-0">
                                  <thead>
                                    <tr>
                                      <th>{t("Pair")}</th>
                                      <th>{t("Side")}</th>
                                      <th>{t("Price")}</th>
                                      <th>{t("Stop Price")}</th>
                                      <th>{t("Used Margin")}</th>
                                      <th>{t("Total")}</th>
                                      <th>{t("Order Type")}</th>
                                      <th>{t("Date")}</th>
                                    </tr>
                                  </thead>
                                  {myCancledOrderHtml()}
                                </table>
                              )}
                            </div>
                          </div>
                          <div
                            className={`tab-pane h_100 ${
                              activeSegment === "margin" ? "active" : ""
                            }`}
                            id="positions"
                            role="tabpanel"
                          >
                            <div className="position_tab d-md-flex mb-1">
                              <div className=" d-flex mb-2 mb-md-0">
                                <a
                                  href="#!"
                                  onClick={() =>
                                    handleChangePositions("positions")
                                  }
                                  className={
                                    activeSide === "positions" ? "active" : ""
                                  }
                                >
                                  {t("Positions")}{" "}
                                  {openOrders.length > 0
                                    ? openOrders.length
                                    : ""}
                                </a>
                                <a
                                  href="#!"
                                  onClick={() => handleChangePositions("all")}
                                  className={
                                    activeSide === "all" ||
                                    activeSide === "working" ||
                                    activeSide === "filled" ||
                                    activeSide === "cancelled"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {t("Orders")}
                                </a>
                                <a
                                  href="#!"
                                  onClick={() =>
                                    handleChangePositions("Account Summary")
                                  }
                                  className={
                                    activeSide === "Account Summary"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {t("Account Summary")}
                                </a>
                                <a
                                  href="#!"
                                  onClick={() =>
                                    handleChangePositions("Notification Logs")
                                  }
                                  className={
                                    activeSide === "Notification Logs"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {t("Notification Summary")}
                                </a>
                              </div>

                              <div className="total_profit ml-auto pt-1">
                                {activeSide === "positions" ? (
                                  <h6 className="mb-0 mr-md-2">
                                    {t("Open")} {t("P & L")}:{" "}
                                    <span
                                      style={
                                        floatingProfits < 0
                                          ? { color: "#f23345" }
                                          : { color: "#00bc8b" }
                                      }
                                    >
                                      ${formatDecimal(floatingProfits, 5)}
                                    </span>
                                  </h6>
                                ) : (
                                  <h6 className="mb-0 mr-md-2">
                                    {t("Close")} {t("P & L")}:{" "}
                                    <span
                                      style={
                                        floatingProfitsClose < 0
                                          ? { color: "#f23345" }
                                          : { color: "#00bc8b" }
                                      }
                                    >
                                      ${formatDecimal(floatingProfitsClose, 5)}
                                    </span>
                                  </h6>
                                )}
                              </div>
                            </div>

                            {(activeSide === "all" ||
                              activeSide === "working" ||
                              activeSide === "filled" ||
                              activeSide === "cancelled") && (
                              <div className="position_tab_in d-flex mb-2 mb-md-0">
                                <a
                                  href="#!"
                                  onClick={() => handleChangePositions("all")}
                                  className={
                                    activeSide === "all" ? "active" : ""
                                  }
                                >
                                  {t("All")}{" "}
                                  {allOrdersData.length > 0
                                    ? allOrdersData.length
                                    : ""}
                                </a>
                                <a
                                  href="#!"
                                  onClick={() =>
                                    handleChangePositions("working")
                                  }
                                  className={
                                    activeSide === "working" ? "active" : ""
                                  }
                                >
                                  {t("Working")}{" "}
                                  {pendingOrders.length > 0
                                    ? pendingOrders.length
                                    : ""}
                                </a>
                                <a
                                  href="#!"
                                  onClick={() =>
                                    handleChangePositions("filled")
                                  }
                                  className={
                                    activeSide === "filled" ? "active" : ""
                                  }
                                >
                                  {t("Filled")}{" "}
                                  {closedOrders.length > 0
                                    ? closedOrders.length
                                    : ""}
                                </a>
                                <a
                                  href="#!"
                                  onClick={() =>
                                    handleChangePositions("cancelled")
                                  }
                                  className={
                                    activeSide === "cancelled" ? "active" : ""
                                  }
                                >
                                  {t("Cancelled")}{" "}
                                  {cancleOrders.length > 0
                                    ? cancleOrders.length
                                    : ""}
                                </a>
                              </div>
                            )}

                            <div className="userRecords-main obo_exchange mt-2 buy_sell_order">
                              {activeSide === "positions" ? (
                                <table className="table mb-0 ">
                                  <thead>
                                    <tr>
                                      <th className="f-left ng-binding">
                                        {t("Pair")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Direction")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Quantity, lots")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Entry point")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Price change")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Take Profit")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Stop Loss")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Commission")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Open Time")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("Gross Profit")}
                                      </th>
                                      <th className="f-left ng-binding">
                                        {t("P & L")}
                                      </th>
                                      <th className="f-center cancels">
                                        <span className="ng-binding p-0">
                                          {t("Action")}
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  {openOrderTable()}
                                  {showMenu && (
                                    <ContextMenu
                                      x={menuPosition.x}
                                      y={menuPosition.y}
                                      onAction={handleAction}
                                    />
                                  )}
                                </table>
                              ) : activeSide === "all" ? (
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>{t("Pair")}</th>
                                      <th>{t("Direction")}</th>
                                      <th>{t("Type")}</th>
                                      <th>{t("Quantity, lots")}</th>
                                      <th>{t("Entry point")}</th>
                                      <th>{t("Price change")}</th>
                                      <th>{t("Take Profit")}</th>
                                      <th>{t("Stop Loss")}</th>
                                      <th>{t("Status")}</th>
                                      <th>{t("OrderID")}</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>{allOrdersTable()}</tbody>
                                </table>
                              ) : activeSide === "working" ? (
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>{t("Pair")}</th>
                                      <th>{t("Direction")}</th>
                                      <th>{t("Quantity, lots")}</th>
                                      <th>{t("Entry Price")}</th>
                                      <th>{t("Take Profit")}</th>
                                      <th>{t("Stop Loss")}</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>{pendingOrderTable()}</tbody>
                                </table>
                              ) : activeSide === "filled" ? (
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>{t("Pair")}</th>
                                      <th>{t("Direction")}</th>
                                      <th>{t("Quantity, lots")}</th>
                                      <th>{t("Open time")}</th>
                                      <th>{t("Close time")}</th>
                                      <th>{t("Price change")}</th>
                                      <th>{t("Commission")}</th>
                                      <th>{t("Gross Profit")}</th>
                                      <th>{t("P & L")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>{closeOrderTable()}</tbody>
                                </table>
                              ) : activeSide === "cancelled" ? (
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>{t("Pair")}</th>
                                      <th>{t("Direction")}</th>
                                      <th>{t("Quantity, lots")}</th>
                                      <th>{t("Entry Price")}</th>
                                      <th>{t("Take Profit")}</th>
                                      <th>{t("Stop Loss")}</th>
                                      <th>{t("Date & Time")}</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>{cancleOrderTable()}</tbody>
                                </table>
                              ) : activeSide === "Account Summary" ? (
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>{t("Title")}</th>
                                      <th>{t("Balance")}</th>
                                      <th>{t("Open PL")}</th>
                                      <th>{t("Equity")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {" "}
                                    <tr>
                                      <td>{t("User Balance")}</td>
                                      <td>
                                        {userBalance
                                          ? Number.isInteger(userBalance)
                                            ? userBalance
                                            : parseFloat(userBalance)
                                          : 0}
                                      </td>
                                      <td>
                                        {formatDecimal(floatingProfits, 5)}
                                      </td>
                                      <td>
                                        {" "}
                                        {new BigNumber(
                                          userBalance ? userBalance : 0
                                        )
                                          .plus(floatingProfits)
                                          .toFixed(5)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : (
                                activeSide === "Notification Logs" && (
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>{t("Time")}</th>
                                        <th>{t("Title")}</th>
                                        <th>{t("Message")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>{notificationTable()}</tbody>
                                  </table>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ResponsiveGridLayout>
            </div>
          </div>
        </div>

        {show && (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title className="h6">{t("Modify Order")}</Modal.Title>
              <span style={{ cursor: "pointer" }}>
                <i onClick={handleClose} className="bi bi-x-circle"></i>
              </span>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <div className="d-flex  pending_in_tab mb-3 mt-3 bg1 br10 p-1 drag-cancel">
                  <button
                    className={sideAcvive === "sell" ? "active " : ""}
                    disabled
                  >
                    {" "}
                    {t("Sell")}
                  </button>
                  <button
                    className={sideAcvive === "buy" ? "active" : ""}
                    disabled
                  >
                    {" "}
                    {t("Buy")}
                  </button>
                </div>
                <div className="mb-3 br10 bg3 p-2">
                  <label className="d-flex align-items-center">
                    {t("Stop Loss")}
                  </label>
                  <div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="number"
                        className="form-control"
                        value={stopPrice}
                        name="stopPriceBuy"
                        onChange={handleBuyChange}
                      />
                      {stopPrice && (
                        <span
                          onClick={() => handleRemove("stopPrice")}
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "12px",
                            cursor: "pointer",
                          }}
                        >
                          X
                        </span>
                      )}
                      <div className="text-danger">{t(errors.stopPrice)}</div>
                    </div>
                  </div>
                </div>

                <div className="mb-3 br10 bg3 p-2">
                  <label className="d-flex align-items-center">
                    {t("Take Profit")}
                  </label>
                  <div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="number"
                        className="form-control"
                        value={takeProfit}
                        name="takeProfit"
                        onChange={handleBuyChange}
                      />
                      {takeProfit && (
                        <span
                          onClick={() => handleRemove("takeProfit")}
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "12px",
                            cursor: "pointer",
                          }}
                        >
                          X
                        </span>
                      )}
                      <div className="text-danger">{t(errors.takeProfit)}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="place_order_fix">
                <button className="btn w100  mt-2 p-2" onClick={modifyOrder}>
                  {t("Modify order")}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <div className="copy-right bg2 p-3 text-center br10">
          © 2024 Dynamic Tech. All Rights Reserved <br />{" "}
          <small>version 1.1.0</small>
        </div>
      </DocumentMeta>
    </div>
  );
};

export default CryptoExchange;
