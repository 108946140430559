import React, { useEffect, useRef, useState } from "react";
import ExchangeNavBar from "../widgets/ExchangeNavBar";
import socketIOClient from "socket.io-client";
import TradingViewEquity from "../partials/TradingViewEquityWidgit";
import { socketUrl } from "../constant/BaseUrl";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const layouts = {
  lg: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1.5, static: true },
    {
      i: "pairslist",
      x: 0,
      y: 1,
      w: 3,
      h: 12,
      minW: 3,
      minH: 4,
    },
    { i: "chart", x: 3, y: 1, w: 6, h: 10, minW: 4, minH: 6 },
    { i: "marketdepth", x: 9, y: 0, w: 3, h: 12, minW: 2, minH: 4 },
    { i: "trades", x: 0, y: 1, w: 3, h: 7, minW: 1, minH: 4 },
    { i: "buy", x: 3, y: 1, w: 3, h: 9, minW: 3, minH: 8 },
    { i: "sell", x: 6, y: 1, w: 3, h: 9, minW: 3, minH: 8 },
    { i: "positions", x: 9, y: 1, w: 3, h: 7, minW: 1, minH: 4 },
    { i: "buyOrders", x: 0, y: 2, w: 6, h: 8.8, minW: 3, minH: 4 },
    { i: "sellOrders", x: 6, y: 2, w: 6, h: 8.8, minW: 3, minH: 4 },
  ],
  md: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 4, static: true },
    {
      i: "pairslist",
      x: 0,
      y: 1,
      w: 12,
      h: 12,
    },
    { i: "chart", x: 0, y: 2, w: 12, h: 10 },
    { i: "marketdepth", x: 9, y: 0, w: 3, h: 12 },
    { i: "trades", x: 0, y: 3, w: 3, h: 7 },
    { i: "buy", x: 0, y: 1, w: 3, h: 9 },
    { i: "sell", x: 0, y: 1, w: 3, h: 9 },
    { i: "positions", x: 0, y: 1, w: 3, h: 7 },
    { i: "buyOrders", x: 0, y: 2, w: 6, h: 8.8 },
    { i: "sellOrders", x: 0, y: 2, w: 6, h: 8.8 },
  ],
  sm: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 4, static: true },
    {
      i: "pairslist",
      x: 0,
      y: 1,
      w: 3,
      h: 12,
    },
    { i: "chart", x: 0, y: 2, w: 6, h: 10 },
    { i: "marketdepth", x: 9, y: 0, w: 3, h: 12 },
    { i: "trades", x: 0, y: 3, w: 3, h: 7 },
    { i: "buy", x: 0, y: 1, w: 3, h: 9 },
    { i: "sell", x: 0, y: 1, w: 3, h: 9 },
    { i: "positions", x: 0, y: 1, w: 3, h: 7 },
    { i: "buyOrders", x: 0, y: 2, w: 6, h: 8.8 },
    { i: "sellOrders", x: 0, y: 2, w: 6, h: 8.8 },
  ],
  xs: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 5, static: true },
    {
      i: "pairslist",
      x: 0,
      y: 1,
      w: 3,
      h: 12,
    },
    { i: "chart", x: 0, y: 2, w: 12, h: 10 },
    { i: "marketdepth", x: 9, y: 0, w: 12, h: 12 },
    { i: "trades", x: 0, y: 3, w: 12, h: 7 },
    { i: "buy", x: 0, y: 1, w: 12, h: 9 },
    { i: "sell", x: 0, y: 1, w: 12, h: 9 },
    { i: "positions", x: 0, y: 1, w: 12, h: 7 },
    { i: "buyOrders", x: 0, y: 2, w: 12, h: 8.8 },
    { i: "sellOrders", x: 0, y: 2, w: 12, h: 8.8 },
  ],
};

const saveToLocalStorage = (key, value) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
const getFromLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    return saved ? JSON.parse(saved) : null;
  }
  return null;
};

const EquityExchange = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("jwtToken");
  const localSocket = useRef(null);
  const { symbol } = useParams();
  const [theme, setTheme] = useState("dark");
  const [equityList, setEquityList] = useState([]);
  const [layout, setLayout] = useState(
    getFromLocalStorage("cryptoLayouts") || layouts
  );

  useEffect(() => {
    if (!localSocket.current) {
      localSocket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });
      localSocket.current.on("connect", () => {
        console.log("Socket is connected");
        handleSocketMessages(localSocket, symbol);
      });
      localSocket.current.emit("ping");
      localSocket.current.emit("getEquitySymbol");
    }
    return () => {
      if (
        localSocket.current &&
        !window.location.pathname.includes("/stocks")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        localSocket.current.disconnect();
      }
    };
  }, []);

  const handleSocketMessages = (localSocket, firstCoin, secondCoin) => {
    localSocket.current.on("pong", () => {
      console.log("Received PONG from server");
    });
    localSocket.current.off("resultEquitySymbol");
    localSocket.current.on("resultEquitySymbol", (data) => {
      setEquityList(data);
    });

    localSocket.current.off("refresh");
    localSocket.current.on("refresh", () => {
      localSocket.current.emit("ping");
      localSocket.current.emit("getEquitySymbol");
      handleSocketMessages(localSocket, firstCoin, secondCoin);
    });
  };

  const resetLayout = (type) => {
    if (type === "crypto") {
      setLayout(layouts);
      saveToLocalStorage("cryptoLayouts", layouts);
    }
  };

  const hanldeChangeSymbol = (item) => {
    navigate(`/stocks/${item.symbol}`);
  };

  const equityListHtml = () => {
    return equityList.map((item) => {
      return (
        <tr>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => hanldeChangeSymbol(item)}
          >
            {item.symbol}
          </td>
          <td>-</td>
          <td className="text-danger">-</td>
          <td className="text-danger">-</td>
          <td>-</td>
          <td>-</td>
          <td>----</td>
          <td>{item.best_bid}</td>
          <td>{item.best_ask}</td>
          <td>0</td>
          <td>0</td>
        </tr>
      );
    });
  };

  const changeChartTheme = (theme) => {
    if (theme === "defaultTheme" || theme === "darkTheme") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };
  return (
    <div>
      <ExchangeNavBar
        resetLayout={resetLayout}
        changeChartTheme={changeChartTheme}
      />
      <div className="dashbord_manbox fs13 dashbord_stocks">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="ne_box  p-2 br10  bg2">
                <select className="control_new w50 mb-2">
                  <option>DEX</option>
                </select>
                <div className="stocks_table2 bg1">
                  <table className="table th_bg">
                    <thead>
                      <tr>
                        <th>Time</th>
                        <th> {t("Price")} </th>
                        <th>Last Sz</th>
                        <th>Mkt</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                      <tr>
                        <td>06:13:27</td>
                        <td>188.110</td>
                        <td>50</td>
                        <td>Q</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-5 mb-3">
              <div className="ne_box  p-2 br10  bg2 h100">
                <TradingViewEquity theme={theme} />
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="ne_box  p-2 br10  bg2">
                <div className="flex_select d-flex">
                  <select className="control_new mb-2">
                    <option>DEX</option>
                  </select>
                  <span className="control_new mb-2 w50px bg-danger">
                    <i className="bi bi-caret-down"></i>
                  </span>
                  <span className="control_new mb-2 bg-danger">-0.590</span>
                  <span className="control_new mb-2">188.030</span>
                  <span className="control_new mb-2">188.030</span>
                  <span className="control_new mb-2">25.503</span>
                </div>
                <div className="flex_select d-flex">
                  <select className="control_new mb-2">
                    <option>DEMOJ</option>
                  </select>
                  <button className="control_new mb-2 w50px">
                    <i className="bi bi-lock"></i>
                  </button>
                  <button className="control_new mb-2 w50px">Clr</button>
                  <select className="control_new mb-2">
                    <option>188</option>
                  </select>
                  <select className="control_new mb-2">
                    <option>DAY</option>
                  </select>
                  <button className="control_new mb-2 w50px">
                    <i className="bi bi-x-lg"></i>
                  </button>
                  <input type="text" className="control_new mb-2 w50px" />
                </div>

                <div className="flex_select d-flex">
                  <select className="control_new mb-2">
                    <option>100</option>
                  </select>

                  <button className="control_new mb-2 w50px">P</button>
                  <button className="control_new mb-2 ">SHRT</button>
                  <button className="control_new mb-2 ">SELL</button>
                  <button className="control_new mb-2 active">BUY</button>
                  <button className="control_new mb-2 w50px">...</button>

                  <select className="control_new mb-2">
                    <option>ARCA</option>
                  </select>
                </div>

                <div className="flex_select d-flex">
                  <span className="control_new control_new_sp mb-2">300</span>
                  <span className="control_new control_new_sp bg-success mb-2">
                    188.110
                  </span>
                  <span className="control_new control_new_sp bg-info mb-2">
                    188.110
                  </span>
                  <span className="control_new control_new_sp mb-2">
                    188.220
                  </span>
                  <span className="control_new control_new_sp mb-2">100</span>
                </div>

                <div className="stocks_table2 ">
                  <div className="row m-0">
                    <div className="col-6 p-0 pr-05">
                      <table className="table th_bg  bg1 ">
                        <thead>
                          <tr>
                            <th>Maker</th>
                            <th> {t("Price")} </th>
                            <th>Size</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6 p-0 pl-05">
                      <table className="table th_bg col-6 p-0 bg1">
                        <thead>
                          <tr>
                            <th>Maker</th>
                            <th> {t("Price")} </th>
                            <th>Size</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                          <tr>
                            <td>NASD</td>
                            <td>188.110</td>
                            <td>200</td>
                            <td>06:13</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="ne_box  p-2 br10  bg2 h100">
                <h6>Patient Summary</h6>
                <ul className="exchange_tab ml-auto">
                  <li className="active tabQuoteAsset">All Order</li>
                  <li className="tabQuoteAsset">Open Order</li>
                  <li className="tabQuoteAsset">Executions</li>
                  <li className="tabQuoteAsset">30/05/24 Executions</li>
                  <li className="tabQuoteAsset">30/05/24 Order</li>
                </ul>

                <div className="stocks_table4 ">
                  <table className="table th_bg bg1 table-striped-white mb-3">
                    <thead>
                      <tr>
                        <th>Sym</th>
                        <th> Pas </th>
                        <th>Last</th>
                        <th>PasAngPrc</th>
                        <th>$Unreal</th>
                        <th>$Real</th>
                        <th>$Total P/L</th>
                        <th>Chg</th>
                        <th>%Chg</th>
                        <th>Opg pas</th>
                        <th>OPP</th>
                        <th>Total City</th>
                        <th>OPP</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-success">AAPL</td>
                        <td className="text-success">582</td>
                        <td>-126.00</td>
                        <td>7.000</td>
                        <td className="text-success">515.88</td>
                        <td>0.00</td>
                        <td className="text-success">515.0</td>
                        <td className="text-success">1.11</td>
                        <td className="text-success">7.25</td>
                        <td>532</td>
                        <td>7.8</td>
                        <td>50</td>
                        <td className="text-success">0</td>
                      </tr>
                      <tr>
                        <td className="text-success">AAPL</td>
                        <td className="text-success">582</td>
                        <td>-126.00</td>
                        <td>7.000</td>
                        <td className="text-danger">515.88</td>
                        <td>0.00</td>
                        <td className="text-danger">515.0</td>
                        <td className="text-danger">-1.11</td>
                        <td className="text-danger">7.25</td>
                        <td>532</td>
                        <td>7.8</td>
                        <td>50</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td className="text-success">AAPL</td>
                        <td className="text-success">582</td>
                        <td>-126.00</td>
                        <td>7.000</td>
                        <td className="text-success">515.88</td>
                        <td>0.00</td>
                        <td className="text-success">515.0</td>
                        <td className="text-success">1.11</td>
                        <td className="text-success">7.25</td>
                        <td>532</td>
                        <td>7.8</td>
                        <td>50</td>
                        <td className="text-success">0</td>
                      </tr>
                      <tr>
                        <td className="text-success">AAPL</td>
                        <td className="text-success">582</td>
                        <td>-126.00</td>
                        <td>7.000</td>
                        <td className="text-danger">515.88</td>
                        <td>0.00</td>
                        <td className="text-danger">515.0</td>
                        <td className="text-danger">-1.11</td>
                        <td className="text-danger">7.25</td>
                        <td>532</td>
                        <td>7.8</td>
                        <td>50</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td className="text-success">AAPL</td>
                        <td className="text-success">582</td>
                        <td>-126.00</td>
                        <td>7.000</td>
                        <td className="text-success">515.88</td>
                        <td>0.00</td>
                        <td className="text-success">515.0</td>
                        <td className="text-success">1.11</td>
                        <td className="text-success">7.25</td>
                        <td>532</td>
                        <td>7.8</td>
                        <td>50</td>
                        <td className="text-success">0</td>
                      </tr>
                      <tr>
                        <td className="text-success">AAPL</td>
                        <td className="text-success">582</td>
                        <td>-126.00</td>
                        <td>7.000</td>
                        <td className="text-danger">515.88</td>
                        <td>0.00</td>
                        <td className="text-danger">515.0</td>
                        <td className="text-danger">-1.11</td>
                        <td className="text-danger">7.25</td>
                        <td>532</td>
                        <td>7.8</td>
                        <td>50</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td className="text-success">AAPL</td>
                        <td className="text-success">582</td>
                        <td>-126.00</td>
                        <td>7.000</td>
                        <td className="text-success">515.88</td>
                        <td>0.00</td>
                        <td className="text-success">515.0</td>
                        <td className="text-success">1.11</td>
                        <td className="text-success">7.25</td>
                        <td>532</td>
                        <td>7.8</td>
                        <td>50</td>
                        <td className="text-success">0</td>
                      </tr>
                      <tr>
                        <td className="text-success">AAPL</td>
                        <td className="text-success">582</td>
                        <td>-126.00</td>
                        <td>7.000</td>
                        <td className="text-danger">515.88</td>
                        <td>0.00</td>
                        <td className="text-danger">515.0</td>
                        <td className="text-danger">-1.11</td>
                        <td className="text-danger">7.25</td>
                        <td>532</td>
                        <td>7.8</td>
                        <td>50</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="ne_box h100  p-2 br10  bg2">
                <h6>Stock Watch</h6>
                <div className="stocks_table4">
                  <table className="table th_bg bg1 table-striped-white mb-3">
                    <thead>
                      <tr>
                        <th>Sym</th>
                        <th>Last</th>
                        <th>Chg</th>
                        <th>% Ch</th>
                        <th>High</th>
                        <th>Low</th>
                        <th>Last Trand</th>
                        <th>Bid</th>
                        <th>Ask</th>
                        <th>Vol</th>
                        <th>POS</th>
                      </tr>
                    </thead>
                    <tbody>{equityListHtml()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquityExchange;
