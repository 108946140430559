import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../components/constant/BaseUrl";

export const myApi = createApi({
  // reducerPath: "myApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("jwtToken");
      const selectedLanguage = localStorage.getItem("lang") || "en"; // Default to English
      if (!!token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept-Language", selectedLanguage); // or use a custom header like 'X-Language'
      return headers;
    },
  }),
  tagTypes: [
    "point",
    "contestTemplate",
    "withdraw",
    "promotionBanner",
    "support",
  ],
  endpoints: (builder) => ({}),
});
